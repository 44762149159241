<template>
  <section>
    <div :class="{ 'filter blur-sm': false }">
      <div class="max-w-6xl mx-auto px-4 sm:px-6">
        <HeaderSection v-if="formBanner" :banner="formBanner=='-' ? false : formBanner " :eventId="mainEvent && formBanner=='-'  ? mainEvent.id : false">
        </HeaderSection>
      </div>
      <div class="px-4 mx-auto max-w-6xl sm:px-6">
        <div class="shadow-xl bg-white text-left p-4 md:p-12 md:pb-5">

          <div v-html="infoMessage"></div>
          <table class="applytb">
            <tbody>
              <tr>
                <th class="text-center" colspan="3" valign="top">{{formTitle}}</th>
              </tr>
              <template v-for="(event,i) in this.events">

                <tr :class=" i % 2==1 ? 'odd' : 'even' " :key="i">
                  <td>

                    <b>{{event.ticket_title}}</b>
                    <p> {{event.eventTimePeriod}}</p>

                  </td>
                  <td>
                    <!-- <p>{{$t('性質')}}：{{ i==2 ? $t('團體專場') : $t('公眾諮詢會')}}</p> -->
                    <p>{{$t('地點')}}：{{event.sessionAddress}}</p>
                    <!-- <p>{{$t('語言：粵語（設有葡語，普通話即時傳譯）')}}</p> -->
                  </td>
                  <td>  
                    {{$t('報名時間')}}： {{event.applyTimePeriod}}

<br/>
                    <a v-if="event.canClick" target="_blank" type="button" :class="event.btnClass"
                      :href="event.href">{{event.stateStr}}</a>
                    <a v-if="!event.canClick" type="button" :class="event.btnClass">{{event.stateStr}}</a>

                  </td>
                </tr>
              </template>

            </tbody>
          </table>
          <div v-html="infoMessageFooter"></div>

        </div>
      </div>
    </div>
  </section>
</template>
<style>
tr.odd {
  background: #CDE3F1;
}

tr.even {
  background: #ECF5FA;
}


table {
  border: 1px solid #ccc;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
}

table.applytb tr th {
  padding: 10px 5px;
  color: rgb(255, 255, 255);
  border-radius: 5px 5px 0px 0px;
  background: #768AA2;
  font-size: 1.5em;
}

table.applytb tr td {
  padding: 10px 5px;
  text-align: center;
}

table caption {
  font-size: 1.5em;
  margin: .5em 0 .75em;
}

table tr {
  background-color: #f8f8f8;
  border: 1px solid #ddd;
}



@media screen and (max-width: 780px) {
  table {
    border: 0;
  }

  table caption {
    font-size: 1.3em;
  }

  table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  table tr {
    border-bottom: 3px solid #ddd;
    display: block;
  }

  table.applytb tr th {
    background: #768AA2;
    display: block;
    width: 100%;
    padding: 10px 0px;
    border-radius: 0px;
  }

  table td {
    border-bottom: 1px solid #ddd;
    display: block;
    text-align: right;
  }

  table td::before {
    /*
    * aria-label has no advantage, it won't be read inside a table
    content: attr(aria-label);
    */
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }

  table td:last-child {
    border-bottom: 0;
  }
}
</style>
<script>
import CONFIG from "../config";
import HeaderSection from "../components/HeaderSection.vue";
import {
  getApplyFormList,
  getMessageTemplate,
  getEventDetail
} from "../api/fetchEvent";
import {
  messageTemplateToI18n,
  applyFormi18n,
  tranlationsToi18nDict
} from "../utils/helper";
// import { updateMessages } from "@/libs/i18n";


const onClass = 'select-none text-2xl text-white bg-gradient-to-r from-teal-400 via-teal-500 to-teal-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-teal-300 dark:focus:ring-teal-800  rounded-lg text-base px-5 py-2.5 text-center mr-2 mb-2'
const beforeClass = 'select-none text-2xl cursor-not-allowed text-blue-700 hover:text-white border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2 dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:hover:bg-blue-600 dark:focus:ring-blue-800'
const disabledClass = 'select-none text-2xl text-white bg-gray-400  cursor-not-allowed font-medium rounded-lg text-sm px-5 py-2.5 text-center'
export default {
  components: {
    HeaderSection,
  },
  data() {
    return {
      'infoMessage': '',
      'infoMessageFooter': '',
      'beforeClass': beforeClass,
      'onClass': onClass,
      'disabledClass': disabledClass,
      'events': [],
      'formTitle': '',
      'mainEvent': false,
      'formBanner': '-'
    }
  },
  methods: {
    async getEvent() {
      let mainEventId = this.$route.params.eventId ? this.$route.params.eventId : CONFIG.defaultMainEventId;

      return getEventDetail(mainEventId);
    }
  }, 
  async mounted() {
    this.mainEvent = await this.getEvent();
    console.log(`locale=${this.$i18n.locale}`);
    if(!this.mainEvent && !this.mainEvent.isEnable ){
        return this.$router.push("/end");
    }           
    const IDs = this.mainEvent.frontendConfig?.index?.watch_forms;
    if(!IDs){
        return this.$router.push("/end");
    } 
    this.formTitle = this.mainEvent.frontendConfig.index.i18n.formTitle[this.$i18n.locale];
    this.formBanner = this.mainEvent.frontendConfig.index.banner;
    console.log('IDs',IDs);

    let resultingArr = [];

    let tpl_slug = 'index_info'
    if(this.mainEvent && this.mainEvent.frontendConfig?.index?.list_slug){
      tpl_slug = this.mainEvent.frontendConfig.index.list_slug;
    }
    let msgTemplateRaw = await getMessageTemplate(tpl_slug);

    if (msgTemplateRaw) {
      const header_info = messageTemplateToI18n(msgTemplateRaw);
      console.log({ header_info })
      this.infoMessage = header_info[this.$i18n.locale]
    }

    // msgTemplateRaw = await getMessageTemplate("東區2- 資料");

    // if (msgTemplateRaw) {
    //   const footer_info = messageTemplateToI18n(msgTemplateRaw);
    //   console.log({ footer_info })
    //   this.infoMessageFooter = footer_info[this.$i18n.locale]
    // }


    const forms = await getApplyFormList(IDs.join(','));

    for (let fi in forms) {
      const f = forms[fi]
      const formID = IDs[fi]
      const i18n = applyFormi18n(f);
      console.log({ i18n });
      const stateI = f.form.availableState;
      let state = CONFIG.availableState(stateI);

      const ticket = f.form.tickets[0]
      const t = ticket ? tranlationsToi18nDict(f.form.tickets[0].ticketTranslations) : ''
      const ticket_title = t ? t[this.$i18n.locale].title : ''
      const session = f.form.sessions[0]
      const s = session ? tranlationsToi18nDict(f.form.sessions[0].sessionTranslations) : ''
      const eventTimePeriod = s ? s[this.$i18n.locale].eventTimePeriod : ''
      const applyTimePeriod = s ? s[this.$i18n.locale].applyTimePeriod : ''
      const sessionAddress = s ? s[this.$i18n.locale].address : ''
      if (state == 'on' && ticket && ticket.availableState == 4) {
        state = 'full'
      }
      console.log({ ticket, quota: ticket.quota })
      const stateStr = this.$t(`event_state_${state}`)
      const btnClass = state == 'on' ? onClass : (state == 'before' ? beforeClass : disabledClass);
      const href = state != 'on' ? '' : `?formKey=${formID}&lang=${this.$i18n.locale}`
      const canClick = state == 'on'
      resultingArr.push({ formID, href, canClick, btnClass, ticket_title, state, stateStr, sessionAddress, eventTimePeriod, applyTimePeriod })
    }

    this.events = resultingArr;
    console.log('form selection mounted')
  },
  created() { },
};
</script>
