<template>
    <div>
        <KProgress 
            :percent="getPercentage(surveyStep, surveyValues, currentSurveyValue, surveySchema)"
            :flow-second=3
            :line-height=10
            :format="() => {
                return getStringFormat(surveyStep, surveyValues, currentSurveyValue, surveySchema);
            }"
            />
    </div>
</template>

<script>
import KProgress from 'k-progress';

export default {
    name: 'ProgressBar',
    props: ['surveyStep', 'surveySchema', 'surveyValues', 'currentSurveyValue'],
    components: { KProgress },
    methods: {
        calculateFraction(surveyStep, surveyValues, currentSurveyValue, surveySchema) {
            if ((!surveySchema || !(typeof surveySchema) || !surveySchema.length)) {
                return { 'answered': 1, 'total': 1 };
            }

            let totalQuestions = 0;
            surveySchema.forEach(page => {totalQuestions += page.filter(x => !['simple-group','editor'].includes(x.type)).length});

            let answeredQuestions = [];
            if (currentSurveyValue && (surveyStep <= (surveySchema.length - 1))) {
                Object.keys(currentSurveyValue).forEach(questionId => {
                    if (currentSurveyValue[questionId]) answeredQuestions.push(questionId);
                });
            }

            surveyValues.forEach((page, idx) => {
                if (idx <= (surveySchema.length - 1)) {
                    if (((idx < surveyStep) || (idx != (surveyValues.length - 1)))) {
                        surveySchema[idx].forEach(question => {
                            if (question.type && (question.id != null)) {
                                let questionIndicator = `${question.type}_${question.id}`;
                                if (!answeredQuestions.includes(questionIndicator)) answeredQuestions.push(questionIndicator);
                            }
                        });
                    } else if (idx == (surveyValues.length - 1)) {
                        Object.keys(surveyValues[idx]).forEach(questionId => {
                            if (!answeredQuestions.includes(questionId)) answeredQuestions.push(questionId);
                        });
                    }
                }
            });
            return { 'answered': answeredQuestions.length, 'total': totalQuestions };
        },
        getPercentage(surveyStep, surveyValues, currentSurveyValue, surveySchema) {
            let data = this.calculateFraction(surveyStep, surveyValues, currentSurveyValue, surveySchema);
            let result = ((data.answered / data.total) * 100);
            return result ? Math.min(result, 100) : 0;
        },
        getStringFormat(surveyStep, surveyValues, currentSurveyValue, surveySchema) {
            let data = this.calculateFraction(surveyStep, surveyValues, currentSurveyValue, surveySchema);
            if (!data || (data.totalQuestions <= 0) || (data.total < 0) ) return '--';
            return `${Math.min(data.answered, data.total)}/${data.total}`;
        }
    }
}
</script>

<style scoped>

</style>