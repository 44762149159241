<template>
  <div id="app" :class="`lang-${$i18n.locale}`">
    <div v-if="previewMode" class="bg-green-200	">PREVIEW MODE</div>
  
    <div class="max-w-6xl mx-auto text-right px-6 mb-2 mt-2">
      <div class="locale-changer">
        <span class="hidden text-gray-300 m-3">
          <span class="sm:hidden">default</span>
          <span class="hidden sm:inline md:hidden">sm</span>
          <span class="hidden md:inline lg:hidden">md</span>
          <span class="hidden lg:inline xl:hidden">lg</span>
          <span class="hidden xl:inline 2xl:hidden">xl</span>
          <span class="hidden 2xl:inline">2xl</span>
        </span>
        <span  v-if="useDebug"> debug:
        <select v-if="formLangs && formLangs.length" v-model="$i18n.locale">
          <option v-for="(lang, i) in langs.filter( l => { return formLangs ? (formLangs.indexOf(l)>=0 ) : true} )" :key="`Lang${i}`" :value="lang">
            {{ langLables[lang] }}
          </option>
        </select>  
      </span>
     
      </div>
    </div>
    <router-view></router-view>
    <div class="p-5 m-5 dim">{{version()}} - <span class="">
      <span class="sm:hidden">default</span>
      <span class="hidden sm:inline md:hidden">sm</span>
      <span class="hidden md:inline lg:hidden">md</span>
      <span class="hidden lg:inline xl:hidden">lg</span>
      <span class="hidden xl:inline 2xl:hidden">xl</span>
      <span class="hidden 2xl:inline">2xl</span>
    </span></div>
    
  </div> 
</template>

<script>
import { SUPPORT_LANGS,SUPPORT_LANG_LABELS } from "./libs/i18n";
import { version } from './utils/helper'
import { mapModalComputed } from './store';
const useDebug =
  window.location.href.indexOf("127.0.0.1") >= 0 ||
  window.location.href.indexOf("localhost") >= 0;

export default {
  name: "App",
  computed: {
      ...mapModalComputed()
  },
  methods:{
    version,  
    triigerError(){
      alert("Here is an uncaught error!" + new Date() )
      throw new Error("Here is an uncaught error!" + new Date())
    }},
    data() {
      return { 
        langs: SUPPORT_LANGS ,
        langLables : SUPPORT_LANG_LABELS,
        previewMode: false,
        useDebug
      };
  },
};
</script>

<style>
html {
  min-height: 100%;
}
body {
  background: linear-gradient(-45deg, #d0edfa, #ffffff);
  min-height: 100%;
  text-align: center;
}

.bg-main-blue{
  background: #3e8fce;
}

.dim{
  color: #E5F5FC;
  opacity: 0.2;
}
</style>
