<template>
    <div v-if="!this.context.slotProps.label.isQuestionTop">
        <!-- <div>non isQuestionTop</div> -->
        <label
        :class="context.classes.label"
        :for="context.id"
        v-html="context.label + (context.slotProps.label.addStar ? `<span class='ml-1 text-red-600 font-bold'>*</span>`: '')" />
    </div>
    <div v-else>
        <!-- <div>isQuestionTop</div> -->
        <div
            v-if="context.type == 'editor'"
            class="w-full flex flex-col mb-2">
            
            <div v-if="context.attributes.content" v-html="context.attributes.content"  class="w-full text-gray-700 text-wrap font-normal mb-1">
              
            </div>
        </div>
        <div
            v-else-if="context.type == 'simple-group'"
            class="w-full flex flex-col -mb-2">
            <div class="w-full flex flex-row flex-wrap justify-between items-center">
                <div class='flex flex-row justify-start w-full'>
                    <label
                        :class="context.classes.label + ' text-2xl'"
                        :for="context.id"
                        v-html="context.label" />
                        
                </div>
            </div>
            <div class="w-full mt-0 mb-1 h-1 rounded-full bg-gray-600" />
            <div v-if="context.slotProps.label.remark" class="w-full text-gray-700 text-wrap font-normal mb-1">
                <label v-html="context.slotProps.label.remark" />
            </div>
        </div>
        <div
            v-else
            class="w-full flex flex-col">
            <div class="w-full flex flex-row flex-wrap justify-between items-center">
                <div class='flex flex-row justify-start w-10/12 md:w-11/12'>
                    <label
                        :class="context.classes.label"
                        :for="context.id"
                        v-html="context.label" />
                    <div v-if="context.slotProps.label.addStar" class="ml-1 text-red-600 font-bold">*</div>
                </div>
                <i
                    v-if="context.slotProps.label.tooltip"
                    class="border border-orange-500 bg-orange-500 rounded-full fas fa-exclamation-circle ml-2 mr-1 inverted_icon cursor-pointer md:mr-2" 
                    v-tooltip.top-center="context.slotProps.label.tooltip" />
            </div>
            <div v-if="context.slotProps.label.remark" class="w-full text-sm mb-1 text-gray-600 text-wrap font-normal">
                <label 
                    v-html="context.slotProps.label.remark" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'customlabel',
    props: ['context'],
    mounted() {
    }
}
</script>

<style>
.inverted_icon{
    filter: invert(1);
}

.tooltip {
    display: block !important;
    z-index: 10000;
}
 .tooltip .tooltip-inner {
    background: white;
    border-color: #808080;
    border-width: 2px;
    color: black;
    border-radius: 16px;
    padding: 5px 10px 4px;
}
 .tooltip .tooltip-arrow {
    width: 0;
    height: 0;
    border-style: solid;
    position: absolute;
    margin: 5px;
    border-color: #808080;
    z-index: 1;
}
 .tooltip[x-placement^="top"] {
    margin-bottom: 5px;
}
 .tooltip[x-placement^="top"] .tooltip-arrow {
    border-width: 5px 5px 0 5px;
    border-left-color: transparent !important;
    border-right-color: transparent !important;
    border-bottom-color: transparent !important;
    bottom: -5px;
    left: calc(50% - 5px);
    margin-top: 0;
    margin-bottom: 0;
}
 .tooltip[x-placement^="bottom"] {
    margin-top: 5px;
}
 .tooltip[x-placement^="bottom"] .tooltip-arrow {
    border-width: 0 5px 5px 5px;
    border-left-color: transparent !important;
    border-right-color: transparent !important;
    border-top-color: transparent !important;
    top: -5px;
    left: calc(50% - 5px);
    margin-top: 0;
    margin-bottom: 0;
}
 .tooltip[x-placement^="right"] {
    margin-left: 5px;
}
 .tooltip[x-placement^="right"] .tooltip-arrow {
    border-width: 5px 5px 5px 0;
    border-left-color: transparent !important;
    border-top-color: transparent !important;
    border-bottom-color: transparent !important;
    left: -5px;
    top: calc(50% - 5px);
    margin-left: 0;
    margin-right: 0;
}
 .tooltip[x-placement^="left"] {
    margin-right: 5px;
}
 .tooltip[x-placement^="left"] .tooltip-arrow {
    border-width: 5px 0 5px 5px;
    border-top-color: transparent !important;
    border-right-color: transparent !important;
    border-bottom-color: transparent !important;
    right: -5px;
    top: calc(50% - 5px);
    margin-left: 0;
    margin-right: 0;
}
 .tooltip[aria-hidden='true'] {
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.15s, visibility 0.15s;
}
 .tooltip[aria-hidden='false'] {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.15s;
}
</style>