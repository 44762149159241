export default {
  methods: {
    trans: function($key, $default) {
      const v = this.$te($key) ? this.$t($key) : false;
      if (v) return v;
      else return $default;
    },
    ensureOk(label, field) {
      let fieldLabel = document.querySelector(`.name_${field} label`);
      if (fieldLabel) {
        let fieldHasValue = fieldLabel.classList.contains("has-value");
        let fieldHasErrors = fieldLabel.classList.contains("has-errors");
        if (!fieldHasValue) { 
          alert(`${this.$t(label)} ${this.$t("必需輸入")}`);
          return false;
        }
        if (fieldHasErrors) {
          alert(`${this.$t(label)} ${this.$t("不正確")}`);
          return false;
        }
        return true;
      } else {
        return false;
      }
    },
  },
};
