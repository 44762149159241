<template>
    <section>
        <div>
            <div class="max-w-6xl mx-auto px-4 sm:px-6">
                <HeaderSection v-if="formBanner" :banner="formBanner=='-' ? false : formBanner " :eventId="mainEvent && formBanner=='-'  ? mainEvent.id : false">
                </HeaderSection>
            </div>
            <div class="px-4 mx-auto max-w-6xl sm:px-6">
                <div class="shadow-xl bg-white text-left p-4 md:p-12 md:pb-5">
                    <div v-html="body"></div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>

import {
  getMessageTemplate,
  getEventDetail
} from "../api/fetchEvent";
import {
  messageTemplateToI18n
} from "../utils/helper";
import HeaderSection from "../components/HeaderSection.vue";
import CONFIG from "../config";

export default {
    components: {
        HeaderSection,
    },
    data(){
        return {
            'body':'',
            'mainEvent': false,
            'formBanner':'-', 
        }
    },
    async mounted() {
        let mainEventId = this.$route.params.eventId ? this.$route.params.eventId : CONFIG.defaultMainEventId;
        this.mainEvent = mainEventId? await getEventDetail(mainEventId) : false;
                    console.log('mainEvent',this.mainEvent)
        if(!this.mainEvent && !this.mainEvent.isEnable ){
            return this.$router.push("/end");
        }           
        if(this.mainEvent && this.mainEvent.frontendConfig?.index?.formBanner){
            this.formBanner= this.mainEvent.frontendConfig.index.formBanner
        }
        let tpl_slug = 'index_lang'
        if(this.mainEvent && this.mainEvent.frontendConfig?.index?.lang_slug){
            tpl_slug = this.mainEvent.frontendConfig.index.lang_slug;
        }
        let msgTemplateRaw = await getMessageTemplate(tpl_slug);
        if (msgTemplateRaw) {
            const header_info = messageTemplateToI18n(msgTemplateRaw);
            this.body = header_info['zh-hant']
        }
    }

};
</script>
