<template>
    <section>
    
         <RegisterForm/>
 
    </section>
</template>

<script>

import RegisterForm from "../components/RegisterForm.vue";

export default {
  components: {
    RegisterForm
  }
};
</script>
