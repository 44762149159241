<template>
  <div
      :class="`modal fixed w-full h-full top-0 left-0 flex items-center justify-center
               ${showQrModal ? '' : 'opacity-0 pointer-events-none'}`" >
      <div class="modal-overlay absolute w-full h-full bg-gray-900 opacity-50" />
        <!-- Top Right Esc Button -->
        <div class=" modal-container bg-white w-11/12 md:max-w-md mx-auto rounded shadow-lg z-50 overflow-y-auto" >
            <div class=" modal-close absolute top-0 right-0 cursor-pointer flex flex-col items-center mt-4 mr-4 text-white text-sm z-50">
                <svg
                    @click="toggleQr()"
                    class="cursor-pointer fill-current text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18" >
                <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z" />
                </svg>
          <span class="text-sm">(Esc)</span>
        </div>

        <!-- Acutal Modal Content -->
        <!-- Add margin if you want to see some of the overlay behind the modal-->
        <div class="modal-content py-4 text-left px-6">
          <div class="flex justify-between items-center">
            <div class="modal-close cursor-pointer z-50">
              <svg
                @click="toggleQr()"
                class="cursor-pointer fill-current text-black"
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18" >
                <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z" />
              </svg>
            </div>

            <!-- Sharing Title -->
            <div class="flex justify-center items-center font-semibold text-2xl h-full">{{ $t("分享") }}</div>
            <div :style="{'width': '18px', 'height':'100%'}"></div>
          </div>
          
          <vue-qrcode
            v-if="publicUrl"
            class="mx-auto"
            :value="publicUrl"
            :options="{ width: 200 }"
            :title="publicUrl" />

          <!-- Modal Text -->
          <div class="flex flex-col">
            <div
                v-html="shareText1"
                class="overflow-y-auto h-32 p-2" />
            <!-- MODERN SOCIAL MEDIA SHARING -->
            <div v-if="showSocialSharing" class="flex flex-row flex-wrap justify-start w-full py-2">
                <div v-for="shareOption in shareOptionsList" v-bind:key="shareOption.name" class="w-12 h-12 m-3">
                <ShareNetwork
                    :network="shareOption.name"
                    :url="(!useDebug && !publicUrl ? publicUrl : 'https://dsepdr-dev.staging.wizmacau.com/')"
                    :title="$t('event.intro.title').replace(/<[^>]+>/g, '').replace(/^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g, '')"
                    :description="$t('event.intro.description').replace(/<[^>]+>/g, '').replace(/^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g, '')"
                    >
                    <span v-bind:style="{ 'background-color': `${shareOption.color}` }" class="rounded-xl h-full w-full flex justify-center items-center">
                        <span :class="`fab fah fa-lg fa-${shareOption.icon} text-white`"/>
                    </span>
                </ShareNetwork>
                </div>
                <div
                    v-if="isMobileSharable"
                    @click="()=>{navigator.share({
                        title: $t('event.intro.title').replace(/<[^>]+>/g, '').replace(/^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g, ''),
                        text: $t('event.intro.description').replace(/<[^>]+>/g, '').replace(/^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g, ''),
                        url: (!useDebug && !publicUrl ? publicUrl : 'https://dsepdr-dev.staging.wizmacau.com/')
                    })}"
                    class="w-12 h-12 m-3 flex justify-center items-center rounded-xl border-4 b bg-white cursor-pointer">
                    <span class="rounded-xl fas fa-lg fa-share-square" />
                </div>
            </div>

            <textarea readonly
                class="border rounded-md p-2 h-32"
                v-model="shareText2" />
            <button @click="copyLink" class="btn btn-blue mt-2">{{ this.$t("複製") }}</button>
          </div>

          <!--Footer-->
          <div v-if="false" class="flex justify-end pt-2">
            <button class="px-4 bg-transparent p-3 rounded-lg text-indigo-500
                         hover:bg-gray-100 hover:text-indigo-400 mr-2">
              Action
            </button>
            <button class="modal-close px-4 bg-indigo-500 p-3 rounded-lg
                         text-white hover:bg-indigo-400">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import BaseMixin from '../BaseMixin';
import VueQrcode from "@chenfengyuan/vue-qrcode";

export default {
    name: 'ShareModal',
    props: ["showQrModal", "toggleQr", "publicUrl", "shareText1",
            "useDebug", "shareText2"],
    components: { VueQrcode },
    mixins: [BaseMixin],
    computed: {
        isMobileSharable() {
            if (!navigator) return false;
            if (!navigator.userAgent) return false;
            if (!(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))) {
                // Gets in here if not mobile
                return false;
            }
            if (!navigator.share) return false;
            return true;
        },
    },
    methods: {
        copyLink() {
            let dummy = document.createElement('textarea');
            dummy.value = this.publicUrl;              
            dummy.style.position = 'absolute';                     
            dummy.style.left = '-9999px';     
            document.body.appendChild(dummy)
            dummy.select();

            try {
                document.execCommand("copy");
                window.getSelection().removeAllRanges();
                alert(this.$t("分享內容已複制"));
                document.body.removeChild(dummy);
            } catch (err) {
                alert(this.$t("請複制上述內容進行分享"));
                document.body.removeChild(dummy);
            }
        }
    },
    data() {
        return {
            showSocialSharing: false,
            shareOptionsList: [
                { name: 'facebook', color: '#145fc2', icon: 'facebook-f' },
                { name: 'messenger', color: '#006acc', icon: 'facebook-messenger' },
                { name: 'whatsapp', color: '#1ea952', icon: 'whatsapp' },
                { name: 'weibo', color: '#bb1223', icon: 'weibo' },
                { name: 'line', color: '#009c00', icon: 'line' },
                { name: 'twitter', color: '#1781c1', icon: 'twitter' },
                { name: 'SMS', color: '#292929', icon: 'comment-dots far' }
            ],
        }
    }
}
</script>

<style>

</style>