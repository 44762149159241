<template>
  <section :data-update="forceUpate">
    <div class="max-w-6xl mx-auto px-4 sm:px-6">
      <HeaderSection :banner="banner">
        <h3
          class="text-lg md:text-3xl text-white mb-2"
          v-html="$t('event.intro.title')"
        ></h3>
        <p class="text-white lg:text-lg">
          <span v-if="!isSurvey" v-html="$t('event.welcomeWord')"></span>
          <span v-else v-html="$t('event.survey.welcomeWord')"></span>
        </p>
      </HeaderSection>
    </div>
    <div class="px-4 mx-auto max-w-6xl sm:px-6">
      <div class="shadow-xl bg-white text-left p-6 pb-12 md:pb-20">
        <div
          class="mx-auto text-center text-2xl text-gray-800"
          v-html="thankyouMsg"
        />

        <vue-qrcode
          v-if="showQr && info.attendeeNumber"
          class="mx-auto"
          :value="info.attendeeNumber"
          :options="{ width: 200, color: qrcodeColor }"
          :title="qr"
        ></vue-qrcode>
        <dir
          class="font-bold text-green p-0 text-center text-2xl text-green-800"
          v-if="showSequenceNumber && info.attendeeNumber"
        >
          <div>{{ $t("報名編號") }}</div>
          <div>{{  info.attendeeNumber.indexOf('_') >=0 ? 1 * info.attendeeNumber.split("_").slice(-1)[0] : info.attendeeNumber }}</div>
        </dir>
        <div class="text-center">
          <!-- <p class="mb-4 text-xl font-bold" v-if=" showQr && !showSequenceNumber && info.attendeeNumber">{{ $t('label.please-screenshot-qr-code') }}</p> -->
          <span v-if="!hideSessionDetail">
            <p
              class="mb-4 font-bold text-green-600"
              v-if="(showQr || showSequenceNumber) && info.attendeeNumber"
            >
              {{ $t("label.please-screenshot") }}
            </p>
            <p
              class="mb-4 font-bold text-green-600"
              v-if="showQr && info.attendeeNumber"
            >
              {{ attendeeNumberLabel }}:
              {{ attendeeNumberFormater(info.attendeeNumber) }}
            </p>

            <p class="eventTimePeriod font-bold" v-if="$te('session.title')">
              {{ $t("session.title") }}
            </p>
            <p class="eventTimePeriod" v-if="$te('session.eventTimePeriod')">
              {{ $t("日期及時間") }}:{{ $t("session.eventTimePeriod") }}
            </p>
            <p class="eventAddress" v-if="$te('session.address')">
              {{ $t("地點") }}:{{ $t("session.address") }}
            </p>
          </span>
          <button
            v-if="showBack"
            type="button"
            @click="handleGoBack"
            class="mx-auto mt-4 px-4 py-2 rounded bg-green-500 text-white hover:bg-green-600 disabled:opacity-50"
          >
            <span class="formulate-input-element--button--label">{{
              $t("返回")
            }}</span>
          </button>
        </div>
      </div>
    </div>
  </section>
</template>
 
<script>
import HeaderSection from "../components/HeaderSection.vue";
import VueQrcode from "@chenfengyuan/vue-qrcode";
import { submitI18n, attendeeNumberFormater } from "../utils/helper";

import { applyFormi18n, json } from "../utils/helper";
import { updateMessages } from "@/libs/i18n";
import { getBannerUrl } from "../api/fetchEvent";

const useDebug =
  window.location.href.indexOf("127.0.0.1") >= 0 ||
  window.location.href.indexOf("localhost") >= 0;

export default {
  components: {
    HeaderSection,
    VueQrcode,
  },
  methods: {
    attendeeNumberFormater,
    async getEvent() {
      const applyForm = window.applyForm;
      this.applyForm = applyForm;
      this.showQr = this.applyForm.form.isShowQRCode;
      this.eventI18n = applyFormi18n(this.applyForm);
      updateMessages(this.eventI18n);
      this.forceUpate = 1;
      this.isSurvey = window.applyForm.form.configJson.showCustomForm; // window.applyForm?.form?.frontendConfig?.customForm?.length >= 1;
      this.formConfigJson = window.applyForm.form.configJson;

      //event banner
      let b = this.eventI18n[this.$i18n.locale]["event.intro.banner"];
      if (b && b.length) {
        this.banner = getBannerUrl(b[0]);
      } else {
        this.banner = false;
      }

      //handle session's attrs
      let sessionAttrs = json(window.thankyou?.data?.session?.attrs);
      if (sessionAttrs && sessionAttrs.qrcodeColor) {
        this.qrcodeColor =
          typeof sessionAttrs.qrcodeColor == "object"
            ? sessionAttrs.qrcodeColor
            : { dark: sessionAttrs.qrcodeColor };
      }

      if (applyForm.form.event.frontendConfig?.form?.showSequenceNumber) {
        this.showSequenceNumber =
          applyForm.form.event.frontendConfig.form.showSequenceNumber;
      }
      //handle thankyou msg
      this.thankyouMsg = this.isSurvey
        ? this.$t("event.survey.thanksWork")
        : this.$t("event.thanksWork");
      const hasSpeaker =
        window.thankyou.data.attendeeNumber.indexOf("_SPK") > 0;
      this.hasSpeaker = hasSpeaker;
      this.attendeeNumberLabel = this.$t("報名編號");
      if (!this.isSurvey) {
        //only for signup
        if (hasSpeaker) {
          console.log(
            "xxx",
            applyForm.form.event.frontendConfig?.msg?.signup_thankyou_speaker[
              this.$i18n.locale
            ]
          );
          if (
            applyForm.form.event.frontendConfig?.msg?.signup_thankyou_speaker[
              this.$i18n.locale
            ]
          ) {
            this.thankyouMsg =
              applyForm.form.event.frontendConfig?.msg?.signup_thankyou_speaker[
                this.$i18n.locale
              ];
          }
          if (
            applyForm.form.event.frontendConfig?.msg?.signup_no_speaker[
              this.$i18n.locale
            ]
          ) {
            this.attendeeNumberLabel =
              applyForm.form.event.frontendConfig?.msg?.signup_no_speaker[
                this.$i18n.locale
              ];
          }
        } else {
          if (
            applyForm.form.event.frontendConfig?.msg?.signup_thankyou[
              this.$i18n.locale
            ]
          ) {
            this.thankyouMsg =
              applyForm.form.event.frontendConfig?.msg?.signup_thankyou[
                this.$i18n.locale
              ];
          }
          if (
            applyForm.form.event.frontendConfig?.msg?.signup_no[
              this.$i18n.locale
            ]
          ) {
            this.attendeeNumberLabel =
              applyForm.form.event.frontendConfig?.msg?.signup_no[
                this.$i18n.locale
              ];
          }
        }
      }
    },
    handleGoBack() {
      if (
        this.applyForm?.form?.useAfterRedirect &&
        this.eventI18n &&
        this.eventI18n[this.$i18n.locale] &&
        this.eventI18n[this.$i18n.locale]["form.afterPeriodRedirectUrl"]
      ) {
        let redirectUrl =
          this.eventI18n[this.$i18n.locale]["form.afterPeriodRedirectUrl"];
        if (
          !redirectUrl.startsWith("http://") &&
          !redirectUrl.startsWith("https://")
        )
          redirectUrl = "https://" + redirectUrl;
        window.location.href = redirectUrl;
      } else {
        this.$router.push("/");
      }
    },
    getThankyou() {
      if (useDebug) console.log("method getThankyou");
      let i = submitI18n(window.thankyou.data);
      window.thankyou.data.i18n = i;
      return window.thankyou.data;
    },

    isConfigTrue(key) {
      return (
        typeof this.formConfigJson[key] != "undefined" &&
        this.formConfigJson[key]
      );
    },
    async initView() {
      if (!window.thankyou || !window.applyForm) {
        this.$router.push("/");
      }
      await this.getEvent();
      this.info = this.getThankyou();
      this.hideSessionDetail = this.isConfigTrue("hideSessionDetail");
      updateMessages(this.info.i18n);
      if (useDebug) console.log("this.info", this.info);
    },
  },

  async mounted() {
    await this.initView();
    this.$forceUpdate();
  },

  async beforeRouteUpdate(to, from, next) {
    if (useDebug) console.log("beforeRouteUpdate", { to, from, next });
    await this.initView();
    next();
    this.$forceUpdate();
  },
  data() {
    return {
      showQr: false,
      qr: this.$route.params["uuid"],
      qrcodeColor: false,
      msg: false,
      forceUpate: 0,
      banner: false,
      isSurvey: false,
      info: {},
      hasSpeaker: false,
      thankyouMsg: "",
      attendeeNumberLabel: "",
      showBack: false,
      showSequenceNumber: false,
      hideSessionDetail: false,
    };
  },
};
</script>