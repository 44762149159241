import axios from "axios";

if (useDebug) console.log({env:process.env})
const useDebug =
  window.location.href.indexOf("127.0.0.1") >= 0 ||
  window.location.href.indexOf("localhost") >= 0;

const DOMAIN = process.env.VUE_APP_API_DOMAIN;
const API_BASE_PATH = process.env.VUE_APP_API_BASE_PATH;
const BASE_URI = `${DOMAIN}${API_BASE_PATH}`


const APPLY_FORM_CONFIG_PATH = "apply-form/config";
const APPLY_FORM_CONFIG_LIST_PATH = "apply-form/list";

const ATTENDEE_SUBMIT = "attendees/submit";
const EVENT_DETAIL = "event/detail";

const MOBILE_SEND_VALIDATION = "attendees/send_verification_code";
const EMAIL_SEND_VALIDATION = "attendees/email/send_verification_code";
const VERIFY_VALIDATION = "attendees/verify_verification_code";
const FILE_PATH = "file";

const CAPTCHA = "captcha";
const CAPTCHA_VALIDATION = "captcha/verify";

const MESSAGE_TEMPLATE = 'rsvp/message-template/get-by-slug'

const ATTENDEE_INFO = 'attendees/apply-info';
const ATTENDEE_CAN_COMMIT = 'rsvp/attendees/can-commit'

const UPLOAD_FILE_ATTACHMENT_PATH = "file/attachment/upload";

export async function uploadFileAttachment(formKey,formMetaId,file, progress) {
    let data = new FormData();
    data.append("file", file);

    const postURL =   `${BASE_URI}/${UPLOAD_FILE_ATTACHMENT_PATH}`;
    const response = await axios.post(postURL, data,
      {
        params: {formKey,formMetaId},
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        onUploadProgress:  progress ? ( progressEvent) =>{
          progress( Math.floor(progressEvent.loaded/progressEvent.total*100 ) ) 
        } : undefined
    });
    if (useDebug) console.log("uploadFileAttachment",{postURL,response});
    handleErrorInfo(response);
    const name = response.data.data.data;
    const url = `${BASE_URI}/${FILE_PATH}/${name}`;
    return { name, url };
}

export async function getAttendeeInfo(uuid) {
  try {
    const url =   `${BASE_URI}/${ATTENDEE_INFO}/${uuid}`;

    const response = await axios.get(url);
    if (useDebug) console.log("getAttendeeInfo",{url,response});
    handleErrorInfo(response);
    return response.data.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function getAttendeeInfoByMobile(mobile,sessionId,verifyKey,verifyCode) {
  try {
    const url =   `${BASE_URI}/${ATTENDEE_INFO}`;
    const response = await axios.post(url,{mobile,sessionId,verifyKey,verifyCode});
    if (useDebug) console.log("getAttendeeInfoByMobile",{url,response,verifyKey,verifyCode});
    handleErrorInfo(response);
    return response.data.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}



export function getBannerUrl(banner){
  return  `${BASE_URI}/${FILE_PATH}/${banner}`;

}

export function getFormPublicUrl(formKey,formLang){
    return `${window.location.origin}${process.env.BASE_URL}?formKey=${formKey}&lang=${formLang}`;
}

export async function getMessageTemplate(slug) {
  try {
    const url = `${BASE_URI}/${MESSAGE_TEMPLATE}`;
    
    const response = await axios.get(url,{params:{slug}});
    if (useDebug) console.log("getMessageTemplate");
    handleErrorInfo(response);
    return response.data.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}


function handleError(response) {
  if (response.data && response.data.code == -1) {
    throw new Error(response.data.message);
  }
}

function handleErrorInfo(response) {
    if (response.data && response.data.code == -1) {
        let e = new Error(
        response.data.message ? response.data.message : "error code -1"
        );
        e.source = response;
        throw e;
    }
    if (!response.data.isSuccess) {
        const errorPrefix = "apply form api error";
        let msg = response.data.message
        ? `${errorPrefix}: ${response.data.message}`
        : errorPrefix;
        let code = response.data.code ? response.data.code : "";
        if (code && !response.data.message) {
            msg = `${msg}: ${code}`;
        }
        let err = new Error(msg);
        err.name = "ApplyForm Error";
        if (code) {
            err.code = response.data.code * 1;
        }
        err.source = response.data;
        if (Error && Error.captureStackTrace) Error.captureStackTrace(err);
        throw err;
    }
}
/* publish */

export async function sendMobileVerification(mobile,locale) {
  try {
    const url = `${BASE_URI}/${MOBILE_SEND_VALIDATION}`;
    const response = await axios.post(url, { mobile,locale });
    handleError(response);
    if (useDebug) console.log("sendMobileVerification", { mobile,locale });
    return response.data.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function sendEmailVerification(Title, Email,locale) {
  try {
    const url = `${BASE_URI}/${EMAIL_SEND_VALIDATION}`;

    const response = await axios.post(url, { Title, Email,locale });
    if (useDebug) console.log("sendEmailVerification", { Title, Email,locale });

    handleErrorInfo(response);
    return response.data.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function getCaptcha() {
  try {
    const url = `${BASE_URI}/${CAPTCHA}`;

    const response = await axios.get(url);
    if (useDebug) console.log("getCaptcha");

    handleErrorInfo(response);
    return response.data.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function verifyCode(Id, VerifyCode) {
  try {
    const url = `${BASE_URI}/${VERIFY_VALIDATION}`;

    const response = await axios.post(url, { Id, VerifyCode });
    if (response.data.code == 302005) {
      response.data.data = false;
    } else if (response.data.code == 302004) {
      response.data.data = false;
      response.data.expired = true;
    } else {
      handleErrorInfo(response);
    }
    if (useDebug) console.log("verifyCode", { Id, VerifyCode, response });
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function verifyCaptchaCode( givenCaptchaKey, CaptchaCode) {
  try {
    const CaptchaKey = givenCaptchaKey=='*' || givenCaptchaKey==null ? window.CaptchaKey : givenCaptchaKey;
    
    const url = `${BASE_URI}/${CAPTCHA_VALIDATION}`;

    let response = await axios.post(url, null, {
      params: { CaptchaKey, CaptchaCode },
    });
    if (response.data.code == 302005) {
      response.data.data = false;
    } else if (response.data.code == 302004) {
      response.data.data = false;
      response.data.expired = true;
    } else {
      handleErrorInfo(response);
    }
    if (useDebug) console.log("verifyCaptchaCode", { CaptchaKey, CaptchaCode });
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function getEventBanner(eventId) {
  try {
    const url = `http://gep.tiger.wizmacau.com/api/event/detail`;

    const response = await axios.get(url, { params: { eventId } });
    handleError(response);
    if (useDebug) console.log("getEventBanner", { eventId });
    return response.data.data.banner;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
export async function getApplyForm(formKey, previewKey, inviteCode) {
  try {
    const url = `${BASE_URI}/${APPLY_FORM_CONFIG_PATH}`;
    const response = await axios.get(url, {
      params: { formKey, previewKey, inviteCode },
    });
    
    if ((response?.data?.code == 301003) || (response?.data?.code == 301004)) return 'INVITE_CODE';
    handleErrorInfo(response);
    if (useDebug) console.log("getApplyForm", { formKey, previewKey, inviteCode, response });
    let data = response.data.data;
    if(data && data.form && data.form.configJson){
      try{
        const configJson = JSON.parse(data.form.configJson);
        data.form.configJson = {};
        configJson.map( (v)=>{
          if(v.slug && v.value)
            data.form.configJson[v.slug]=v.value;
        })
      }catch(e){
        if (useDebug) console.log('data.form.configJson', {e})  
      }
    }
    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function getApplyFormList(formKeys) {
  try {
    const url = `${BASE_URI}/${APPLY_FORM_CONFIG_LIST_PATH}`;
    const response = await axios.get(url, {
      params: { formKeys },
    });
    
    handleErrorInfo(response);
    if (useDebug) console.log("getApplyFormList", { formKeys, response });
    let data = response.data.data;
    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function getEventDetail(id) {
  try {
    const url = `${BASE_URI}/${EVENT_DETAIL}`;
    const response = await axios.get(url, { params: { id } });
    handleErrorInfo(response);
    if (useDebug) console.log("getEventDetail", { id, response });
    return response.data.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function submitForm(params) {
  try {
    const url = `${BASE_URI}/${ATTENDEE_SUBMIT}`;
    const response = await axios.post(url, params);
    handleErrorInfo(response);
    if (useDebug) console.log("submitForm", { params, response });

    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function checkCanCommit(query) {
  try {
    let endString = '?';
    if(window.vueI18n.locale) query.locale = window.vueI18n.locale;
    Object.keys(query).forEach(key => {
      if (query[key] != 'null') endString += `${key}=${query[key]}&`
    })
    const url = `${BASE_URI}/${ATTENDEE_CAN_COMMIT}${endString}`;
    const response = await axios.get(url);
    if (useDebug) console.log("checkCanCommit", { query, response });

    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
