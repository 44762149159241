<template>
    <div
        :class="`formulate-input-element formulate-input-element w-full flex flex-row justify-start align-middle`"
        :data-type="context.type" >
        <input
            type="text"
            class="w-2/3 sm:w-1/2 mr-2"
            v-model="context.model"
            v-bind="context.attributes"
            autocomplete="off"
            maxlength="4"
            @keydown.enter.prevent="context.model = selection.label"
            @blur="customBlurHandler">
        <div class='flex flex-row justify-start rounded-sm border-2 border-gray-700 h-full'>
            <div class="captch-image-size">
                <div 
                    class="w-full h-full"
                    :style="`background-image: url(data:image/png;base64,${imageBase64String})`" />
            </div>
            <div
                v-on:click="refreshCaptcha" 
                class="bg-gray-700 captcha-box-height flex  flex-col justify-center cursor-pointer" >
                <span class="fas fa-redo-alt captcha-reload-icon px-2"></span>
            </div>
        </div>
    </div>
</template>

<script>
import { getCaptcha, verifyCaptchaCode } from '../../api/fetchEvent';

export default {
    props: {
        context: {
            type: Object,
            required: true
        },
    },
    async mounted() {
        this.refreshCaptcha();

        window.$vue.$on("refreshCaptcha", () => {
            console.log("Refersh captcha triggered");
            console.log(this)
            this.refreshCaptcha();
        });
    },
    methods: {
        async refreshCaptcha() {
            let captcha = await getCaptcha();
            this.context.model = "";
            this.context.slotProps.addMore.setCaptchaKey(captcha.captchaKey);
            this.context.captchaKey = captcha.captchaKey;
            this.captchaKey =  captcha.captchaKey;
            this.imageBase64String = captcha.imageBase64String;
        },
        async customBlurHandler() {
            this.context.blurHandler();
            if (this.context.model.length != 4) return;
            let verifyCodeResp = await verifyCaptchaCode(this.captchaKey, this.context.model);
            if (verifyCodeResp.expired) this.refreshCaptcha();

        }
    },
    data () {
        return {
            imageBase64String: null,
            captchaKey: null,
        }
    }
}

</script>

<style scoped>

.captch-image-size{
    height: 32px;
    width: 80px;
}

.captcha-box-height{
    height: 32px;
}

.captcha-reload-icon{
    height: 16px;
    filter: invert(1);
}

</style>
