<template>
  <section :data-update="forceUpate">
    <div class="max-w-6xl mx-auto px-4 sm:px-6">
      <HeaderSection :banner="banner">
        <h3
          class="text-lg md:text-3xl text-white mb-2"
          v-html="$t('event.intro.title')"
        ></h3>
        <p class="text-white lg:text-lg">
          <span v-html="$t('event.welcomeWord')"></span>
        </p>
      </HeaderSection>
    </div>
    <div class="px-4 mx-auto max-w-6xl sm:px-6">
      <div class="shadow-xl bg-white text-left p-6 pb-12 md:pb-20">
        <!-- <div
          class="mx-auto text-center text-2xl text-gray-800"
          v-html="$t('event.thanksWork')"
        ></div> -->
        <vue-qrcode
          v-if="showQr && info.attendeeNumber"
          class="mx-auto"
          :value="info.attendeeNumber" 
          :options="{ width: 200, color: qrcodeColor  }"
          :title="qr"
        ></vue-qrcode>
        <div class="text-center">
          <p class="mb-4 text-xl font-bold" v-if=" showQr && info.attendeeNumber">{{ $t('label.please-screenshot-qr-code') }}</p>
          <p class="attendeeNumber" v-if="showQr && info.attendeeNumber">{{ attendeeNumberLabel }}:{{ attendeeNumberFormater(info.attendeeNumber) }}</p>
          <p class="eventTimePeriod" v-if="$te('session.eventTimePeriod')">{{ $t("日期及時間") }}:{{ $t("session.eventTimePeriod") }} 
          <p class="eventAddress" v-if="$te('session.address')">{{ $t("地點") }}:{{ $t("session.address") }}</p>
     
        </div>
      </div>
    </div>
  </section>
</template>
 
<script>
import HeaderSection from "../components/HeaderSection.vue";
import VueQrcode from "@chenfengyuan/vue-qrcode";
import {submitI18n,formI18n,handleStopError, attendeeNumberFormater,json, getFormQueryString } from "../utils/helper";

import { updateMessages } from "@/libs/i18n";
import { getAttendeeInfo,getBannerUrl } from '../api/fetchEvent';


let { formKey, previewKey, inviteCode, formLang } = getFormQueryString();

const useDebug =
  window.location.href.indexOf("127.0.0.1") >= 0 ||
  window.location.href.indexOf("localhost") >= 0;

export default {
  components: {
    HeaderSection,
    VueQrcode,
  },
  methods: {
    attendeeNumberFormater,
     setFormLang(locale){
      if (window.VueFormulate) {
        let formLocale = locale
        if( locale == "zh-hans"){ 
          formLocale = 'hans'
        } else if(locale == "zh-hant"){
          formLocale = 'hant'
        }
        if (useDebug) console.log({formLocale});
        window.VueFormulate.setLocale(formLocale);
      }
    },

    async initView(){
      this.showQr = true;
      try{
        this.attendee = await getAttendeeInfo(this.$route.params.uuid);
      }catch(e){
        let errCode = handleStopError("applyFormConfig", e,false);
          this.$router.push(`/display/${errCode}`);
          return false;
      }
      console.log('this.attendee',this.attendee)
      const thankyou = this.attendee
      this.eventI18n = formI18n(null,this.attendee.event,[this.attendee.ticket],[this.attendee.session]);

      //handle session's attrs
      let sessionAttrs = json( thankyou?.session?.attrs)
      if(sessionAttrs && sessionAttrs.qrcodeColor ){
        this.qrcodeColor = typeof sessionAttrs.qrcodeColor == "object" ? sessionAttrs.qrcodeColor : {"dark":sessionAttrs.qrcodeColor} ;
      }

      //handle labels 
      const event = this.attendee.event;
      const hasSpeaker = thankyou.attendeeNumber.indexOf("_SPK") >0;
      this.hasSpeaker = hasSpeaker;
      this.attendeeNumberLabel =  this.$t("報名編號")
      if(hasSpeaker){
        if(event.frontendConfig?.msg?.signup_no_speaker[this.$i18n.locale]){
          this.attendeeNumberLabel = event.frontendConfig?.msg?.signup_no_speaker[this.$i18n.locale]
        }
      }else{
        if(event.frontendConfig?.msg?.signup_no[this.$i18n.locale]){
          this.attendeeNumberLabel = event.frontendConfig?.msg?.signup_no[this.$i18n.locale]
        }
      }

      updateMessages(this.eventI18n);
      this.forceUpate = 1;
      let b = this.eventI18n[this.$i18n.locale]["event.intro.banner"];
      if (b && b.length) {
        this.banner = getBannerUrl(b[0])
      } else {
        this.banner = false;
      }
      this.info = this.attendee
      const i = submitI18n(this.attendee)
      updateMessages(i);    
      if (useDebug) console.log('this.info',JSON.parse(JSON.stringify(this.info) ) )
      
    },
  },

  async mounted() {
    const i = this.$i18n.locale
    console.log({formKey, previewKey, inviteCode, formLang ,i})
    await this.initView()
    this.$forceUpdate();
  },
  
  async beforeRouteUpdate(to, from, next) {
    if (useDebug) console.log('beforeRouteUpdate',{to, from, next})
    await this.initView()
    next();
    this.$forceUpdate();
  },
  data() {
    return {
      showQr: false,
      qr: this.$route.params["uuid"],
      qrcodeColor: false,
      msg: false,
      forceUpate: 0,
      banner: false,
      info: {},
      attendeeNumberLabel: ''
    };
  },
};
</script>