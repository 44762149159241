// $t('available_state_other')
// $t('available_state_before')
// $t('available_state_on')
// $t('available_state_after')
// $t('available_state_full')

module.exports = {
  availableStates: {
    other: 0,
    before: 1,
    on: 2,
    after: 3,
    full: 4,
  },
  availableStateStrings: ["other", "before", "on", "after", "full"],
  availableState(i) {
    return this.availableStateStrings[i];
  },
  errors: {
    applyFormConfig: {
      "404": "沒有相關活動或報名",
      "500": "沒有相關活動或報名",
      "301001": "當前沒有該活動報名",
      "301002": "未生成報名表",
      "301003": "請填寫邀請碼",
      "301004": "邀請碼不正確",
      "301005": "無效的表單配置",
    },
    attendeesSubmit: {
      
      "302001": "圖片驗證碼驗證不通過",
      "302002": "手機驗證碼驗證不通過",
      "301003": "請填寫邀請碼",
      "301004": "邀請碼不正確",
      "301005": "該活動報名已截止",
      "301006": "請選擇正確的場次",
      "303001": "請填寫",
      "303002": "手機格式不正確",
      "303003": "電郵格式不正確",
      "303004": "該流動電話或電郵已使用",
      "303005": "該流動電話已使用",
      "303006": "該電郵已使用",
      "301007": "沒有該票種",
      "304001": "票種已超出上限",
      "302003": "請輸入驗證碼",
      "302004": "驗證碼已過期",
      "302005": "驗證碼不正確",
      "304003" : "發言參與人數已滿"
    },
  },
};
