import { render, staticRenderFns } from "./CustomSessions.vue?vue&type=template&id=f23b9f7e&scoped=true&"
import script from "./CustomSessions.vue?vue&type=script&lang=js&"
export * from "./CustomSessions.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f23b9f7e",
  null
  
)

export default component.exports