<template>
  <div
    :class="{ 'filter blur-sm': false }"
    :data-update="forceUpdate"
    v-show="ready"
  >
    <!-- Loading for waiting submit -->
    <loading
      :active="isSubmitLoading"
      :can-cancel="false"
      :is-full-page="true"
      transition="fade"
      color="#3e8fce"
      background-color="#696969"
    />

    <!-- Header -->
    <div class="max-w-6xl mx-auto px-4 sm:px-6">
      <HeaderSection :banner="banner">
        <h3
          class="text-lg md:text-3xl text-white mb-2"
          v-html="$t('event.intro.title')"
        ></h3>
        <p class="text-white lg:text-lg">
          <span v-if="!isSurvey" v-html="$t('event..welcomeWord')"></span>
          <span v-else v-html="$t('event.survey.welcomeWord')"></span>
        </p>
      </HeaderSection>
    </div>

    <div class="px-4 mx-auto max-w-6xl sm:px-6">
      <div class="shadow-xl bg-white text-left p-4 md:p-12 md:pb-5">
        <!-- Sharing Button -->
        <div
          v-if="!isConfigTrue('noSharing')"
          class="relative m-0 mb-6 sm:mb-0"
        >
          <div
            v-if="publicUrl"
            class="cursor-pointer text-right md:absolute md:top-0 md:right-0"
          >
            <span @click="toggleQr()">
              <i class="fas fa-share-square" />
              {{ $t("分享") }}
            </span>
          </div>
        </div>

        <!-- Title -->
        <h1
          class="block text-center mb-4 text-lg leading-tight font-medium text-black mt-6"
          v-html="title()"
        />

        <!-- Show short desc if in forms -->
        <!-- <transition :name="withTransition">
          <span
            v-if="showShortDesc"
            class="
              block
              mb-4
              mt-1
              text-sm
              leading-tight
              font-medium
              text-gray-500
            "
            v-html="description()"
          />
        </transition>-->

        <!-- First Page of form -->
        <transition :name="withTransition" v-if="hasIntroPage()">
          <div v-if="isAtStartForm">
            <p class="text-black lg:text-lg">
              <span v-if="!isSurvey" v-html="$t('event.welcomeWord')"></span>
              <span v-else v-html="$t('event.survey.welcomeWord')"></span>
            </p>
            <br />
            <p class="text-black lg:text-lg">
              <span v-html="description()"></span>
            </p>
            <br />
            <!-- <p class="text-black lg:text-lg">
              <span v-html="$t('form.description')"></span>
            </p> -->
            <br />
            <div class="flex w-full justify-center items-center">
              <button class="formBtn-green" v-on:click="moveAwayFromStartPage">
                {{ this.tempTextI18N[this.$i18n.locale].start }}
              </button>
            </div>
          </div>
        </transition>

        <ProgresBar
          v-if="showProgressBar"
          v-bind="{
            surveyStep,
            surveySchema,
            surveyValues,
            currentSurveyValue,
          }"
        />
        <!-- Base Form -->

        <div v-if="hasProfilePage && !useRecord">
          <transition :name="withTransition">
            <FormulateForm
              name="baseFormName"
              v-show="showBaseForm"
              @submit="formSubmit"
              v-model="values"
              @click-mobile-code-btn="
                startCounting(useDebug ? 5 : 60, 'phoneNumber')
              "
              @click-email-code-btn="startCounting(useDebug ? 5 : 60, 'email')"
              @click-refresh-captcha="getCaptcha()"
              @on-verifiy="onVerifiyCodeEvent"
              :schema="formSchema"
              :form-errors="formErrors"
              :errors="inputErrors"
              ref="baseForm"
            >
              <!-- validation="required|max:4|verifyCodeRule:captchaKey,captchaCode,true" -->
              <FormulateInput
                v-if="showCaptcha"
                type="captchainput"
                name="captchaCode"
                :setCaptchaKey="setCaptchaKey"
                :label="$t('驗證碼')"
                :placeholder="$t('驗證碼')"
                :validation="`required|minNoReq:4,false|verifyCaptchaCode:${captchaKey}`"
                :validation-messages="{ verifyCaptchaCode: verifyCodeMesssage }"
                error-behavior="submit"
                v-model="captchaCode"
              />
              <div v-if="!isConfigTrue('noTnC')">
                <div
                  v-if="term"
                  class="md:p-4 p-4 mb-2 border text-gray-600 use-list-disc overflow-auto term"
                  v-html="term"
                />
                <div
                  v-if="eventTerm"
                  class="md:p-4 p-4 mb-2 border text-gray-600 use-list-disc overflow-auto eventTerm"
                  v-html="eventTerm"
                />

                <FormulateInput
                  type="checkbox"
                  v-model="acceptTnc"
                  :validation-messages="{ accepted: $t('請同意') }"
                  name="acceptTnc"
                  :label="$t('同意遵守以上規則') + '*'"
                  validation="accepted"
                  v-if="!hideAcceptTnc"
                />
              </div>
              <button
                v-if="!isConfigTrue('skipFirstIntroPage')"
                type="button"
                class="formBtn"
                @click="moveToStartPage"
              >
                {{ $t("返回") }}
              </button>
              <button type="submit" class="formBtn" ref="baseSubmitBtn">
                {{
                  !hasSurveyForm()
                    ? $t("label.submit")
                    : this.tempTextI18N[this.$i18n.locale].startSurvey
                }}
              </button>
            </FormulateForm>
          </transition>
        </div>

        <!-- Record Form -->
        <div v-if="hasProfilePage && useRecord">
          <transition :name="withTransition">
            <div v-if="recordSchema">
              <transition :name="withTransition">
                <FormulateForm
                  name="recordForm"
                  v-show="showBaseForm"
                  @input="recordInput"
                  @submit="recordFormSubmit"
                  @submit-raw="surveySubmitRaw"
                  v-model="recordValues"
                  :schema="recordSchema"
                  ref="recordForm"
                  class="lg:grid grid-cols-2 gap-2"
                >
                  <div
                    v-if="term"
                    class="md:p-4 p-4 mb-2 border text-gray-600 use-list-disc overflow-auto term"
                    v-html="term"
                  />
                  <div
                    v-if="eventTerm"
                    class="md:p-4 p-4 mb-2 border text-gray-600 use-list-disc overflow-auto event-term col-span-2"
                    v-html="eventTerm"
                  />

                  <FormulateInput
                    type="checkbox"
                    v-model="acceptTnc"
                    :validation-messages="{ accepted: $t('請同意') }"
                    name="acceptTnc"
                    :label="$t('同意遵守以上規則') + '*'"
                    validation="accepted"
                  />

                  <button
                    type="button"
                    class="formBtn"
                    @click="moveToStartPage"
                    v-if="!isConfigTrue('skipFirstIntroPage')"
                  >
                    {{ $t("返回") }}
                  </button>
                  <button type="submit" class="formBtn">
                    {{
                      !configJson.showCustomForm
                        ? $t("label.submit")
                        : this.tempTextI18N[this.$i18n.locale].startSurvey
                    }}
                  </button>
                </FormulateForm>
              </transition>
              <br />
            </div>
          </transition>
        </div>
        <!-- Survey Form -->
        <transition :name="withTransition">
          <div
            v-show="showSurveyForm"
            v-if="surveyStep >= 0 && currentSurveySchema"
          >
            <transition :name="withTransition">
              <div
                v-if="
                  lastStep == surveyStep &&
                  !isConfigTrue('skipPreviewSurveyAnswsers')
                "
              >
                <DisplayAllValues
                  v-bind="{
                    surveyStep,
                    surveySchema,
                    surveyValues,
                    currentSurveyValue,
                    useOptionsV2,
                  }"
                />
                <br />
                <!-- <p class="text-black lg:text-lg">
                      <span v-html="$t('form.afterPeriod')"></span>
                  </p>
                  <br/>
                  <p class="text-black lg:text-lg">
                      <span v-html="$t('event.intro.precautions')"></span>
                  </p>
                  <br/> -->
              </div>
            </transition>

            <FormulateForm
              name="fff"
              @submit="surveySubmit"
              @failed-validation="surveyFailedValidation"
              @input="surveyInput"
              @submit-raw="surveySubmitRaw"
              @created="surveyCreated"
              v-model="currentSurveyValue"
              :schema="currentSurveySchema"
              :form-errors="surveyFormErrors"
              :errors="surveyInputErrors"
              ref="surveyForm"
            >
              <FormulateInput
                type="hidden"
                validation="verifyUploading"
              ></FormulateInput>

              <button
                type="button"
                class="formBtn"
                v-if="surveyHasBackToBase()"
                @click="backtoBase"
              >
                {{ $t("返回") }}
              </button>

              <!-- <button type="button" class="formBtn" v-if="surveyHasPrev()" @click="moveSurveyStep(surveyStep-1)">{{$t('上一頁')}}</button>
                <button type="button" class="formBtn" v-if="surveyHasNext()"  @click="moveSurveyStep(surveyStep+1)">{{$t('下一頁')}}</button> -->
              <button
                type="button"
                class="formBtn"
                v-if="surveyHasPrev()"
                @click="
                  surveyStep == 0
                    ? goSurveyStep(-2)
                    : moveSurveyStep(surveyStep - 1)
                "
              >
                {{ this.tempTextI18N[this.$i18n.locale].prevPage }}
              </button>
              <button
                type="button"
                class="formBtn"
                v-if="surveyHasNext()"
                @click="moveSurveyStep(surveyStep + 1)"
              >
                {{ this.tempTextI18N[this.$i18n.locale].nextPage }}
              </button>

              <button
                type="button"
                class="formBtn"
                v-if="surveyHasFinish()"
                @click="moveSurveyStep(surveyStep + 2, true)"
              >
                {{ $t("label.submit") }}
              </button>
            </FormulateForm>
            <br />
          </div>
        </transition>

        <div class="mt-20" v-html="trustPolicy[this.$i18n.locale]" />

        <!-- [Development] Debug Information -->
        <button
          v-if="useDebug"
          class="formBtn"
          v-on:click="
            () => {
              showDebug = !showDebug;
            }
          "
        >
          Click me to toggle debug
        </button>
        <DebugViewer
          :showDebug="showDebug"
          :useDebug="useDebug"
          :useTestData="useTestData"
          :forceFormSubmit="forceFormSubmit"
          :surveyStep="surveyStep"
          :currentSurveySchema="currentSurveySchema"
          :currentSurveyValue="currentSurveyValue"
          :surveyFormErrors="surveyFormErrors"
          :surveyInputErrors="surveyInputErrors"
          :surveySchema="surveySchema"
          :surveyValues="surveyValues"
          :shareText="shareText"
          :deviceDetails="getDeviceDetails()"
          :allRelationRules="allRelationRules"
          :formValues="values"
          :recordSchema="recordSchema ? recordSchema : {}"
          :recordValues="recordValues"
          :values="values"
        />
      </div>
    </div>

    <!--Modal-->
    <ShareModal
      :showQrModal="showQrModal"
      :toggleQr="toggleQr"
      :publicUrl="publicUrl"
      :shareText1="shareText1"
      :shareText2="shareText2"
      :useDebug="useDebug"
    />
  </div>
</template>

<script>
// more detail:
// https://dev.to/justinschroeder/tailwind-vue-formulate-24k1

import {
  htmlToText,
  getFormQueryString,
  handleStopError,
  messageTemplateToI18n,
} from "../utils/helper";
import CONFIG from "../config";
let { formKey, previewKey, inviteCode, formLang } = getFormQueryString();
import HeaderSection from "../components/HeaderSection.vue";

import ProgresBar from "./formcomponents/ProgressBar.vue";
import DisplayAllValues from "./formcomponents/DisplayAllValues.vue";
import DebugViewer from "./formcomponents/DebugViewer.vue";
import ShareModal from "./formcomponents/ShareModal.vue";

import formItemStyles from "../libs/formItemStyles";

const PAGE_LOAD_TIME = 20;
const useDebug =
  window.location.href.indexOf("127.0.0.1") >= 0 ||
  window.location.href.indexOf("localhost") >= 0;

if (useDebug) console.log({ formKey, previewKey, inviteCode, formLang });

import {
  getApplyForm,
  submitForm,
  sendMobileVerification,
  sendEmailVerification,
  getCaptcha,
  getMessageTemplate,
  getBannerUrl,
  getFormPublicUrl,
  getAttendeeInfoByMobile,
  uploadFileAttachment,
  //   getMyIP
} from "../api/fetchEvent";
import { applyFormi18n } from "../utils/helper";
import { updateMessages } from "@/libs/i18n";
import BaseMixin from "./BaseMixin";
import { mapModalComputed } from "../store";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import UAParser from "ua-parser-js";

export default {
  mixins: [BaseMixin],
  components: {
    HeaderSection,
    ProgresBar,
    DisplayAllValues,
    Loading,
    DebugViewer,
    ShareModal,
  },
  watch: {
    "$i18n.locale": function (newVal, oldVal) {
      if (useDebug) console.log(`locale change ${oldVal}=>${newVal}`);

      const baseForm = window.location.href.split("?")[0];
      let urlParts = [];
      urlParts.push(`formKey=${formKey}`);
      urlParts.push(`lang=${newVal}`);
      if (previewKey) {
        urlParts.push(`previewKey=${previewKey}`);
      }
      if (inviteCode) {
        urlParts.push(`inviteCode=${inviteCode}`);
      }
      const formUrl = `${baseForm}?${urlParts.join("&")}`;

      location.href = formUrl;
    },
    "values.ticketId": function (newVal, oldVal) {
      if (useDebug) console.log(`ticketId change ${oldVal}=>${newVal}`);
      this.refreshTickets();
      this.refreshI18n();
    },
    "values.attendeeSpeak": function (newVal, oldVal) {
      if (useDebug) console.log(`ticketId change ${oldVal}=>${newVal}`);
      this.refreshTickets();
      this.refreshI18n();
    },
    "values.sessionId": function (newVal, oldVal) {
      if (useDebug) console.log(`sessionId change ${oldVal}=>${newVal}`);
    },
  },
  methods: {
    verifyUploading(context, type) {
      console.log("verifyUploading method", { context, type });
      return false;
    },
    async uploadFile(file, progress, error, option) {
      console.log("uploadFile", { file, progress, error, option });
    },
    handleFormLang(langStr) {
      this.formLangs = JSON.parse(langStr);

      // if( this.formLangs.indexOf(formLang) ==-1 ){
      // }
      if (useDebug) console.log("handleFormLang", [this.formLangs, langStr]);
    },
    setFormLang(locale) {
      if (window.VueFormulate) {
        let formLocale = locale;
        if (locale == "zh-hans") {
          formLocale = "hans";
        } else if (locale == "zh-hant") {
          formLocale = "hant";
        }
        if (useDebug) console.log({ formLocale });
        window.VueFormulate.setLocale(formLocale);
      }
    },
    genShareText() {
      //表單分享刪走Facebook；分享頁面須包括的內容 : 活動名稱, 表單名稱, 場次日期時間, 地址, 二維碼, 該頁面的URL (包括複製功能鍵)
      const title = this.title();
      const description = false && this.description();
      const session = this.getCurrentSession();
      let lines = [];
      let lines2 = [];
      lines.push(title);

      if (session) {
        let sessionId = session.id;
        // const stitle = this.$t(`session.${sessionId}.title`);
        // if (stitle && title != stitle) lines.push(stitle);
        const stime = this.$t(`session.${sessionId}.eventTimePeriod`);
        if (stime && stime != `session.${sessionId}.eventTimePeriod`)
          lines.push(`${this.$t("日期及時間")}: ${stime}`);
        const add = this.$t(`session.${sessionId}.address`);
        if (add) lines.push(`${this.$t("地點")}: ${add}`);
        if (description) lines.push("" + description);
      }

      lines2.push("");
      lines2.push(`${this.$t("連結")}: ${this.publicUrl}`);

      return [lines.join("\n"), lines2.join("\n")];
    },
    toggleQr() {
      this.showQrModal = !this.showQrModal;
      let l = this.genShareText();
      this.shareText1 = l[0].replaceAll("\n", "<br/>");
      this.shareText2 = htmlToText(l[1]);
      this.shareText = htmlToText(l[0]) + htmlToText(l[1]);
      if (useDebug)
        console.log(this.shareText, this.shareText1, this.shareText2);
    },
    title() {
      const eventTitle = this.trans("event.intro.title");
      const formTitle = this.trans("form.title");
      return formTitle ? formTitle : eventTitle;
    },
    description() {
      const eventTitle = this.trans("event.intro.description");
      const formTitle = this.trans("form.description");
      // const cleanTitle = htmlToText(formTitle);
      return formTitle ? formTitle : eventTitle;
      // return cleanTitle ? formTitle : eventTitle;
    },
    useTestData() {
      for (let i in this.abc) {
        this.$set(this.values, i, this.abc[i]);
      }
    },
    onVerifiyCodeEvent($event) {
      if (useDebug) console.log("$event", $event);
    },
    async startCounting(val, field, field2) {
      console.log("startCounting");
      if (useDebug) console.log("startCounting", { field, field2 });
      if (useDebug) console.log("this.values", this.values);
      if (field == "phoneNumber") {
        const ok = this.ensureOk("電話", field);
        if (ok) {
          this.isCounting = true;
          this.counting = val;
          let resp = await sendMobileVerification(
            this.values[field],
            this.$i18n.locale
          );
          if (useDebug) console.log({ resp });
          this.values.verifyKey = resp.id;
        }
      } else if (field == "email") {
        const ok = this.ensureOk("電郵", field);
        if (ok) {
          this.isCounting = true;
          this.counting = val;
          let resp = await sendEmailVerification(
            "Email verification",
            this.values[field],
            this.$i18n.locale
          );
          if (useDebug) console.log({ resp });
          this.values.verifyKey = resp.id;
        }
      } else {
        throw new Error("unknown type for verfiy code");
      }
    },
    getCurrentTicket() {
      if (this.applyForm.form.tickets?.length == 1)
        return this.applyForm.form.tickets[0];
      let theId = false;
      if (this.useRecordTickets) {
        let ticketIdInput = this.recordSchema.find((x) => x.slug == "tickets");
        if (ticketIdInput && ticketIdInput.ticketList.length) {
          theId = ticketIdInput.ticketList[0].id;
        }
      } else {
        theId = this.values.ticketId;
      }
      if (!this.values.ticketId || !this.applyForm.form.tickets) return false;
      let obj = this.applyForm.form.tickets.filter((t) => t.id == theId)[0];
      return obj;
    },
    getCurrentSession() {
      let theSessionId = false;
      if (this.useRecordSession) {
        let sessionIdInput = this.recordSchema.find(
          (x) => x.slug == "sessions"
        );
        if (sessionIdInput && sessionIdInput.sessionList.length) {
          theSessionId = sessionIdInput.sessionList[0].id;
        } else {
          return false;
        }
      } else {
        if (!this.values.sessionId || !this.applyForm.form.sessions)
          return false;
        theSessionId = this.values.sessionId;
      }
      let obj = this.applyForm.form.sessions.filter(
        (t) => t.id == theSessionId
      )[0];
      return obj;
    },

    refreshTickets() {
      // const groupticketId = document.querySelector('.group_ticketId')
      // if(this.hasSurveyForm){
      //   groupticketId.classList.add('hidden');
      // }
      const nameAttendeeSpeak = document.querySelector(".id_attendeeSpeak");
      if (useDebug) console.log("nameAttendeeSpeak", nameAttendeeSpeak);
      if (nameAttendeeSpeak) {
        let ticket = this.getCurrentTicket();
        console.log({ ticket, "ticket.allowToSpeak": ticket.allowToSpeak });
        if (ticket && ticket.allowToSpeak) {
          nameAttendeeSpeak.classList.remove("hidden");
          const theCheckbox = document.querySelector(".id_attendeeSpeak input");
          const theLabel = document.querySelectorAll(
            ".id_attendeeSpeak label"
          )[2];
          if (ticket.speakQuota > 0) {
            theCheckbox.disabled = false;
            theLabel.innerText = this.$t("label.Yes");
            theLabel.classList.remove("text-gray-300");
            this.speakStatus = true;
          } else {
            this.values.attendeeSpeak = false;
            theCheckbox.disabled = true;
            theLabel.innerText = `[${this.$t("available_state_full")}]`;
            setTimeout(() => {
              theLabel.classList.add("text-gray-300");
            }, 100);

            this.speakStatus = false;
          }
        } else {
          if (useDebug) console.log(">");
          nameAttendeeSpeak.classList.add("hidden");
        }
      }
    },
    refreshI18n() {
      if (useDebug) console.log("refreshI18n", window.eventI18n);
      document.title = this.$t(`event.intro.title`);
      if (useDebug) console.log("document.title", document.title);
      if (this.values.ticketId) {
        let term = `${this.$t(`ticket.${this.values.ticketId}.description`)}`;
        if (this.values.attendeeSpeak) {
          const t = this.$t(`ticket.${this.values.ticketId}.speakPrecautions`);
          if (t) term += `<div class="mt-2">${t}</div>`;
        }
        this.term = term;
      } else {
        this.term = "";
      }
      this.eventTerm = this.showSurveyForm
        ? this.$t("event.intro.surveyPrecautions")
        : this.$t(`event.intro.precautions`);
      let b = this.eventI18n[this.$i18n.locale]["event.intro.banner"];
      if (b && b.length) {
        this.banner = getBannerUrl(b[0]);
      } else {
        this.banner = false;
      }
      this.forceUpdate++;
      this.$forceUpdate();
    },
    async getCaptcha() {
      let captcha = await getCaptcha();
      if (useDebug) console.log("getCaptcha", captcha);
      this.values.captchaKey = captcha.captchaKey;
      const captchaButton = document.querySelector("[name=captchaButton]");
      if (captchaButton)
        captchaButton.style =
          "margin-top:26px;width:80px;height:30px;background: url(data:image/png;base64," +
          captcha.imageBase64String +
          ")";
    },
    async getEvent() {
      let err = false;
      try {
        this.applyForm = window.applyForm = await getApplyForm(
          formKey,
          previewKey,
          inviteCode 
        );
        // create a style tag in the head which content
        const s = document.createElement('style');
        s.innerHTML = this.applyForm.form.theme.customCss.replace("<p>","").replace("</p>","").replace("&nbsp;","").replace(/\n/,"")
         if (useDebug) console.log({s})
        document.body.appendChild(s);
        //set style tag content to "body{}"
        if (this.applyForm == "INVITE_CODE") {
          this.$router.push("enter-invite-code");
          return;
        }
        if (this.applyForm.form && this.applyForm.form.lang) {
          this.handleFormLang(this.applyForm.form.lang);
        }

        this.inviteCode = inviteCode;
      } catch (e) {
        if (useDebug) console.log("getEvent error", e);
        err = e;
        window.route_error = this.tempTextI18N[this.$i18n.locale].systemError;
        let failUrl = "/error";
        this.$router.push(failUrl);
      }
      //retry for non or invalid
      // if (err && (err.code == 301003 || err.code == 301004)) {
      //   let retry = 0;
      //   const max_retry = 2;
      //   while (err && retry < max_retry) {
      //     let inputInviteCode = prompt(
      //       retry == 0
      //         ? this.$t("請輸入邀請碼")
      //         : this.$t("輸入錯誤，請輸入邀請碼")
      //     );
      //     retry++;
      //     err = false;
      //     try {
      //       this.applyForm = await getApplyForm(
      //         formKey,
      //         previewKey,
      //         inputInviteCode
      //       );
      //       this.inviteCode = inputInviteCode;
      //     } catch (e) {
      //       err = e;
      //     }
      //   }
      // }

      if (this.applyForm.form.configJson) {
        this.configJson = this.applyForm.form.configJson;
      }
      // handle error that stops form showing
      if (err) {
        window.eee = err;
        let errCode = handleStopError("applyFormConfig", err);
        this.$router.push(`/display/${errCode}`);
        return false;
      }

      window.eventI18n = this.eventI18n = applyFormi18n(this.applyForm);
      window.t = (a) => {
        return this.$t(a);
      };

      if (formKey) {
        this.publicUrl = getFormPublicUrl(formKey, formLang);
      }

      window.applyForm = this.applyForm;

      if (useDebug) console.log("eventI18n", this.eventI18n);
      updateMessages(this.eventI18n);
      
      if (useDebug)
        console.log(
          "this.applyForm.form.availableState ",
          this.applyForm.form.availableState
        );
      if (this.applyForm.form.availableState != CONFIG.availableStates.on) {
        this.handleBeforeAfter(this.applyForm.form.availableState);
      }

      this.forceUpdate++;
      return this.applyForm;
    },
    handleBeforeAfter(stateIdx) {
      if (this.applyForm.previewMode) {
        return;
      }
      const state = CONFIG.availableState(stateIdx);
      let redirectUrl = false;
      let useStopDialog = false;
      const errorMsgKey = "event-not-available-" + state;
      let dialog = {
        icon: "info",
        back: true,
      };
      switch (state) {
        case "before":
          redirectUrl = this.trans("form.beforePeriodRedirectUrl");
          dialog.icon = "info";
          if (this.applyForm.form.useBeforeRedirect && redirectUrl) {
            window.location = redirectUrl;
            return false;
          } else {
            dialog.msgKeys = [
              "form.beforePeriod",
              "event-not-available-before",
            ];
            useStopDialog = true;
          }
          break;
        case "after":
          redirectUrl = this.trans("form.afterPeriodRedirectUrl");
          dialog.icon = "info";
          if (this.applyForm.form.useAfterRedirect && redirectUrl) {
            window.location = redirectUrl;
            return false;
          } else {
            dialog.msgKeys = ["form.afterPeriod", "event-not-available-after"];
            useStopDialog = true;
          }
          break;
        case "other":
          dialog.msg = "other";
          useStopDialog = true;
          break;
        case "full":
          this.handleNoQuota("handleBeforeAfter is full");
          break;
      }
      if (useStopDialog) {
        dialog.useEventI18n = true;
        localStorage.setItem(errorMsgKey, JSON.stringify(dialog));
        this.$router.push(`/display/${errorMsgKey}`);
      }
    },
    handleNoQuota(reason) {
      if (reason && useDebug) console.log("handleNoQuota reason", reason);
      let dialog = {
        icon: "info",
        back: true,
      };
      const errorMsgKey = "event-no-quota";
      let useStopDialog = false;
      let redirectUrl = this.trans("form.afterPeriodRedirectUrl");
      if (this.applyForm.form.useAfterRedirect && redirectUrl) {
        dialog.msgKeys = ["報名發言配額已達上限"];
        dialog.backUrl = redirectUrl;
        useStopDialog = true;
      } else {
        dialog.msgKeys = ["報名發言配額已達上限"];
        useStopDialog = true;
      }
      if (useStopDialog) {
        dialog.useEventI18n = true;
        localStorage.setItem(errorMsgKey, JSON.stringify(dialog));
        this.$router.push(`/display/${errorMsgKey}`);
      }
    },
    validateFormSubmit() {
      // window.scrollTo({ top: 0, behavior: 'smooth' });
      window.VueFormulate.submit("baseFormName");
    },
    async formSubmit() {
      window.scrollTo({ top: 0, behavior: "smooth" });
      await this.doSubmit(true);
    },
    async forceFormSubmit() {
      await this.doSubmit();
    },
    async recordFormSubmit() {
      console.log("recordFormSubmit");
      window.scrollTo({ top: 0, behavior: "smooth" });
      await this.doSubmit(true);
    },
    async surveySubmit(a) {
      if (useDebug) console.log("surveySubmit", a);
      window.scrollTo({ top: 0, behavior: "smooth" });
      this.goSurveyStep(this.nextStep);
    },
    async surveySubmitRaw(a) {
      this.surveySubmitRawValidateFail = false;
      if (useDebug) console.log("surveySubmitRaw", a);

      // if(isFileUploading){
      //   this.surveySubmitRawValidateFail  = true;
      //   this.$toast.warning( this.$t('label.file-uploading'), {
      //       position: "top-center",
      //       timeout: 5000,
      //       closeOnClick: true,
      //       pauseOnFocusLoss: true,
      //       pauseOnHover: true,
      //       draggable: true,
      //       draggablePercent: 0.6,
      //       showCloseButtonOnHover: true,
      //       hideProgressBar: false,
      //       closeButton: "button",
      //       icon: true,
      //       rtl: false
      //     });

      // }

      // Smooth transition to first error
      a.form.hasValidationErrors().then((hasErrors) => {
        console.log({ hasErrors }, hasErrors);
        if (hasErrors) {
          let $formEl = a.form.$el;
          const errorWrapperEl = $formEl.querySelector(
            '[data-has-errors="true"]'
          );
          const errorWrapperInput =
            errorWrapperEl &&
            errorWrapperEl.querySelector("input,select,textarea");
          if (errorWrapperInput) {
            errorWrapperInput.focus();
            errorWrapperInput.scrollIntoView({
              block: "center",
              behavior: "smooth",
            });
          }
        }
      });
      return false;
    },
    async surveyInput(/*a*/) {
      //if (useDebug) console.log("surveyInput", a);
      this.evaluateShowQuestions();
    },
    async surveyFailedValidation(a) {
      if (useDebug) console.log("surveyFailedValidation", { a });
    },
    async surveyCreated(a) {
      if (useDebug) console.log("surveyCreated", { a });
    },

    async recordInput() {
      //if (useDebug) console.log("recordInput", { a });
      this.evaluateShowRecordQuestions();
    },

    async doSubmit(byUser) {
      console.log("doSubmit");
      // Determine whether to start survey or submit base form
      if (byUser && this.hasSurveyForm()) {
        this.startSurvey();
        return;
      }
      // Loading spinner & survey end time
      this.surveyEndTime = new Date();
      this.isLoading = true;
      this.isSubmitLoading = true;

      const values = this.values;
      if (useDebug) console.log("e", { previewKey, af: this.applyForm });
      let submitValues = {
        name: values.name,
        mobile: values.phoneNumber,
        email: values.email,
        idCard: values.idNumber,
        contact: values.mobile,
        address: values.address,
        organizer: values.organizer || values.orgainzer || values.group,
        position: values.position || values.agency,
        remark: "",
        sessionId: values.sessionId,
        inviteCode: this.inviteCode,
        formKey,
        previewKey,
        ticketIds: [values.ticketId],
        captchaKey: this.captchaKey,
        captchaCode: this.captchaCode,
        channel: values.channel,
        locale: this.$i18n.locale,
        spendTime: Math.floor(
          (this.surveyEndTime - this.surveyStartTime) / 1000
        ),
      };

      //collect anwser from Survey Form
      if (this.hasSurveyForm()) {
        console.log("surveyValues", this.surveyValues);
        submitValues.survey = this.surveyValues.reduce(
          (r, c) => Object.assign(r, c),
          {}
        );

        console.log("hasSurveyForm", submitValues);

        Object.keys(submitValues.survey).forEach((key) => {
          if (submitValues.survey[key] == "") delete submitValues.survey[key];

          if (submitValues.survey[key]?.context?.type == "file") {
            submitValues.survey[key] = submitValues.survey[key].files.map(
              (f) => f.path.name
            );
            for (let fii in submitValues.survey[key].files) {
              const fileupload = submitValues.survey[key].files[fii];
              console.log(`file input ${fii}`, { fileupload });
              if (fileupload.progress != 100) {
                break;
              }
            }
          }
        });

        if (submitValues.survey.captchainput_captcha) {
          submitValues.captchaCode = submitValues.survey.captchainput_captcha;
          delete submitValues.survey.captchainput_captcha;
        }

        if (this.useOptionsV2) {
          this.surveySchema.forEach((schemaPage) => {
            schemaPage.forEach((surveyQuestion) => {
              if (
                ["radio", "checkbox", "select"].includes(surveyQuestion.type)
              ) {
                let value =
                  submitValues.survey[
                    `${surveyQuestion.type}_${surveyQuestion.id}`
                  ];
                if (
                  value &&
                  (surveyQuestion.type == "radio" ||
                    surveyQuestion.type == "select")
                ) {
                  let label = surveyQuestion.options
                    .filter((x) => x.id == value)
                    .pop();
                  if (label)
                    submitValues.survey[
                      `${surveyQuestion.type}_${surveyQuestion.id}`
                    ] = label.label;
                } else if (value && surveyQuestion.type == "checkbox") {
                  let idToLabel = {};
                  surveyQuestion.options.forEach((option) => {
                    idToLabel[option.id] = option.label;
                  });
                  submitValues.survey[
                    `${surveyQuestion.type}_${surveyQuestion.id}`
                  ] = value.map((id) => idToLabel[id]);
                }
              }
            });
          });
        }
        console.log("survey values", submitValues.survey);
      }
      //collect anwser from Record Form
      if (this.useRecord) {
        submitValues.record = Object.assign({}, this.recordValues);
        window.r = this.recordValues;
        console.log("use record submitValues", { submitValues });
        delete submitValues.record.captchaCode;
        delete submitValues.record.驗證碼;

        if (submitValues.record.checkbox_attendeeSpeak) {
          //TODO:hardcode
          const ticked = submitValues.record.checkbox_attendeeSpeak.length == 1;
          delete submitValues.checkbox_attendeeSpeak;
          submitValues.attendeeSpeak = ticked;
        }

        if (this.useRecordTickets) {
          let ticketIdInput = this.recordSchema.find(
            (x) => x.slug == "tickets"
          );
          if (ticketIdInput) {
            submitValues.ticketIds = [
              this.recordValues[`customticket_${ticketIdInput.id}`],
            ];
            const oid = this.recordValues.radio_customticket_ticketcount;
            submitValues.applyTotal = {
              "7a05e39cf0cc9b8f": 1,
              ba4444797a70d738: 2,
            }[oid]; //TODO:hardcode
            delete submitValues.record[`customticket_${ticketIdInput.id}`];
            delete submitValues.record.radio_customticket_ticketcount;
          }
        }

        if (this.useRecordSession) {
          let sessionIdInput = this.recordSchema.find(
            (x) => x.slug == "sessions"
          );
          if (sessionIdInput) {
            submitValues.sessionId =
              this.recordValues[`customsession_${sessionIdInput.id}`];
            delete submitValues.record[`customsession_${sessionIdInput.id}`];
          }
        }

        if (this.contactVerifyType) {
          let toReplace;
          Object.keys(submitValues.record).forEach((key) => {
            if (key.search("codeverifyinput") >= 0) toReplace = key;
          });
          if (toReplace) {
            submitValues.record[
              toReplace.replace("codeverifyinput", this.contactVerifyType)
            ] = submitValues.record[toReplace];
            delete submitValues.record[toReplace];
          }
        }

        if (this.useOptionsV2) {
          this.recordSchema.forEach((recordQues) => {
            if (["radio", "checkbox", "select"].includes(recordQues.type)) {
              let value =
                submitValues.record[`${recordQues.type}_${recordQues.id}`];
              if (
                value &&
                (recordQues.type == "radio" || recordQues.type == "select")
              ) {
                let label = recordQues.options
                  .filter((x) => x.id == value)
                  .pop();
                if (label)
                  submitValues.record[`${recordQues.type}_${recordQues.id}`] =
                    label.label;
              } else if (value && recordQues.type == "checkbox") {
                let idToLabel = {};
                recordQues.options.forEach((option) => {
                  idToLabel[option.id] = option.label;
                });
                submitValues.record[`${recordQues.type}_${recordQues.id}`] =
                  value.map((id) => idToLabel[id]);
              }
            }
          });
        }

        console.log("end record submitValues", { submitValues });
      } //end record

      // if (this.useDimension) {
      //   submitValues.record = this.dimensionValues;

      //   if (this.useOptionsV2) {
      //       this.dimensionSchema.forEach(dimensionQues => {
      //         if (['radio', 'select'].includes(dimensionQues.type)) {
      //           let value = submitValues.record[`${dimensionQues.type}_${dimensionQues.id}`];
      //           let label = dimensionQues.options.filter(x=>x.id==value).pop();
      //           if (label) submitValues.record[`${dimensionQues.type}_${dimensionQues.id}`] = label.label;
      //         }
      //       });
      //     }
      // }

      let ticket = this.getCurrentTicket();
      if (ticket.allowToSpeak) {
        submitValues["attendeeSpeak"] =
          values.attendeeSpeak && values.attendeeSpeak.length
            ? values.attendeeSpeak.length > 0
            : values.attendeeSpeak;
      }
      if (values.verifyCode) {
        console.log("use base form values veifyCode", values.verifyCode);
        submitValues.verifyCode = values.verifyCode;
        if (window.cc) submitValues.verifyCode = window.cc;
      }
      if (values.verifyKey) {
        console.log("use base form values verifyKey", values.verifyKey);
        submitValues.verifyKey = values.verifyKey;
      }
      if (useDebug) console.log({ submitValues, baseFormValues: values });

      // Device Details
      submitValues.device = JSON.stringify(this.getDeviceDetails());
      // submitValues.submitterIP = await getMyIP();

      // Try submitting

      if (this.formConfig("formType") == "attendeeForm") {
        if (useDebug) console.log({ submitValues });
        try {
          const data = await getAttendeeInfoByMobile(
            submitValues.mobile,
            1 * submitValues.sessionId,
            window.lastVerifyKey,
            submitValues.verifyCode
          );
          if (data && data.uuid) {
            window.thankyou = data;
            this.$router.push({ path: `/attendee/${data.uuid}` });
          } else {
            alert(this.trans("label.not-found"));
          }
          console.log({ data });
        } catch (e) {
          console.log(e);
        }
        this.isLoading = false;
        this.isSubmitLoading = false;
        return;
      }

      try {
        if (useDebug) console.log({ submitValues });
        const data = await submitForm(submitValues);
        if (useDebug) console.log({ data });
        window.thankyou = data;
        this.isLoading = false;
        this.isSubmitLoading = false;
        if (data.isSuccess) {
          let successUrl = "/done/" + data.data.uuid;
          this.$router.push(successUrl);
        } else {
          alert(data.errorInfo);
        }
      } catch (e) {
        if (useDebug) console.log("submit form error now");
        this.isLoading = false;
        this.isSubmitLoading = false;
        window.eeee = e;
        if (e.code && e.code == "304001") {
          this.handleNoQuota("304001");
        } else if (e.code && CONFIG.errors.attendeesSubmit[e.code]) {
          alert(this.$t(CONFIG.errors.attendeesSubmit[e.code]));
        } else if (e.code && (e.code == "303009" || e.code == "303007")) {
          alert(e.message);
        } else {
          if (e.source && e.source.errorInfo) {
            let einfo = e.source.errorInfo;
            let detail = "";
            if (einfo.validationErrors && einfo.validationErrors.length) {
              if (useDebug)
                console.log({
                  "einfo.validationErrors": einfo.validationErrors,
                });
              detail =
                "\n" + einfo.validationErrors.map((v) => v.message).join("\n");
            }
            if (useDebug) console.log({ detail });
            let em = this.$te(e.source.errorInfo.message)
              ? this.$t(e.source.errorInfo.message)
              : e.source.errorInfo.message;
            let msg = `${em}${detail}`;
            alert(msg);
          } else {
            alert(e);
          }
        }
        if (useDebug) console.log("doSubmit", e);
      }
    },
    // getBasicSchema() {
    //     return [];
    // },
    getApplyFormSchema() {
      // const that = this;
      let schema = [];
      if (useDebug) console.log("Basic schema: ", schema, this.applyForm);

      let valid_session_count = 0;
      const detailSession = true; //TODO: configuration detail session label
      
      let sessionOptions = this.applyForm.form.sessions
        .map((t) => {
          let lines = [];
          const disabled = t.availableState != CONFIG.availableStates.on;
          //let no = t.code ? t.code : '';
          const title = this.$t(`session.${t.id}.title`);
          lines.push(title);
          if (detailSession) {
            const time = this.trans(`session.${t.id}.eventTimePeriod`);
            if (time) lines.push(`${this.$t("日期及時間")}: ${time}`);
            const add = this.trans(`session.${t.id}.address`);
            if (add) lines.push(`${this.$t("地點")}: ${add}`);
          }
          //let extra = `<span>this.$t('available_state_${CONFIG.availableState(t.availableState)}')</span>`;
          let label = lines
            .map((l, i) =>
              i == 0
                ? l
                : `<div class="${
                    i == 0 ? "text-current" : "text-gray-600"
                  }">${l}</div>`
            )
            .join("");
          if (!t.isEnable) return false;

          if (!disabled) {
            valid_session_count++;
          }
          return {
            value: t.id,
            id: `session_${t.id}`,
            disabled,
            label,
            useHtml: true,
            groupCount: this.applyForm.form.sessions.length,
          };
        })
        .sort((a, b) => a.value * 1 - b.value * 1)
        .filter((o) => o);

      if (valid_session_count == 0 && sessionOptions.length == 1) {
        this.handleBeforeAfter(this.applyForm.form.sessions[0].availableState);
      }
      // build tickets:
      let valid_ticket_count = 0;
      let attendeeAllowSpeak = false;
      let joinOptions = this.applyForm.form.tickets
        .map((t, ticketIdx) => {
          let disabled = t.availableState != CONFIG.availableStates.on;
          let label = this.$t(`ticket.${t.id}.title`);
          let extra =
            t.availableState != CONFIG.availableStates.on
              ? this.$t(
                  `available_state_${CONFIG.availableState(t.availableState)}`
                )
              : "";
          if (!t.isEnable) return false;

          if (t.quota != 0 && t.useQuota >= t.quota) {
            disabled = true;
            //label = `${label} (${this.$t("已滿額")}) `;
          }
          if (extra) label = `${label} [${extra}]`;
          if (!disabled) {
            valid_ticket_count++;
          }
          const canSpeak = t.allowToSpeak;
          if (canSpeak) {
            attendeeAllowSpeak = true;
          }
          //if (useDebug) console.log('ticket',{t,disabled,label})
          if (useDebug) console.log("ticket info: ", t);
          return {
            value: t.id,
            id: `ticket_${t.id}_${ticketIdx}`,
            disabled,
            label,
            groupCount: this.applyForm.form.tickets.length,
          };
        })
        //.sort((a, b) => a.value * 1 - b.value * 1)
        .filter((o) => o);

      let s = {
        type: "radio",
        name: "sessionId",
        label: this.$t(
          this.formConfig("label.signup-session", "label.signup-session")
        ),
        addStar: true,
        options: sessionOptions,
        validation: "required",
        "element-class": "one-column-container",
        "input-class": "one-column-items border-box",
      };
      const select_only_valid = true;
      if (select_only_valid && valid_session_count === 1) {
        
        if (this.hasSurveyForm()) s.wrapper_hidden = "hidden";
        for (let oo in sessionOptions) {
          let o = sessionOptions[oo];
          if (!o.disabled) {
            this.$set(this.values, "sessionId", `${o.value}`);
          }
        }
      }
      if (useDebug) console.log({ valid_session_count });
      if (sessionOptions.length) schema.push(s);

      this.formOverrides = this.applyForm.form.configJson.form_overrides ? JSON.parse(this.applyForm.form.configJson.form_overrides ) : {} ;
      this.baseFieldsOverides = this.formOverrides.base_fields ? this.formOverrides.base_fields : {};
      if(this.applyForm.form.formConfig) this.applyForm.form.formConfig
        .map((field_config) => {
          if (field_config.hidden) return false;
          let field = field_config.field;
          let f = {
            label: this.$t(field_config.module),
            name: field,
            validation: "",
            maxlength: 255,
            id: field,
          };
          if (field == "email") {
            f.validation = "email";
            if (field_config.uniqueEvent)
              f.validation += `|verifyReapeatForm:email,${this.applyForm.form.id}`;
            if (field_config.unique)
              f.validation += `|verifyReapeatSession:email,${this.values.sessionId}`;
            if (field_config.use_validation_code) {
              f.type = "codeverifyinput";
              f.slug = "email";
              this.contactVerifyType = "text";
            } else {
              f.type = "email";
            }
            f.rules = {
              required: field_config.required ? field_config.required : false,
              email: true,
            };
            f.placeholder = this.$t("電郵");
            f["help-position"] = "after";
            f.isQuestionTop = true;
            f.tooltip = this.generateHelpText(f.rules, f.type);
            f.addStar = field_config.required;
            f.validationName = f.label;
            f.defaultHidden = field.hidden ? field.hidden : false;
            f.help = true;
            f.hidden = false;
            f.setCodeKey = this.setCodeKey;
            f.setCodeValue = this.setCodeValue;
            f["@blur-context"] = this.recordFormBlur;
          } else if (field == "idNumber") {
            f.validation = "macauID";
            if (field_config.uniqueEvent)
              f.validation += `|verifyReapeatForm:idCard,${this.applyForm.form.id}`;
            if (field_config.unique)
              f.validation += `|verifyReapeatSession:idCard,${this.values.sessionId}`;
            f.placeholder = this.$t("身份證");
          } else if (field == "phoneNumber") {
            if (field_config.use_validation_code) {
              f.type = "codeverifyinput";
              f.slug = "phoneNumber";
              this.contactVerifyType = "number";
            } else {
              f.type = "tel";
            }
           
            f.rules = {
              required: field_config.required ? field_config.required : false,
              macauPhone: true,
            };
            f.validation = "macauPhone";
            if (field_config.uniqueEvent)
              f.validation += `|verifyReapeatForm:mobile,${this.applyForm.form.id}`;
            if (field_config.unique)
              f.validation += `|verifyReapeatSession:mobile,${this.values.sessionId},${this.applyForm.form.id}`;
            f["help-position"] = "after";
            f.isQuestionTop = true;
            f.placeholder = this.$t("請輸入8位澳門流動電話號碼");
            f.tooltip = this.generateHelpText(f.rules, f.type);
            f.addStar = field_config.required;
            f.validationName = f.label;
            f.defaultHidden = field.hidden ? field.hidden : false;
            f.help = true;
            f.hidden = false;
            f.setCodeKey = this.setCodeKey;
            f.setCodeValue = this.setCodeValue;
            f["@blur-context"] = this.recordFormBlur;
          }
          if(this.baseFieldsOverides[field]){
            f = Object.assign(f, this.baseFieldsOverides[field]);
            f.label = this.$t(f.label);
            if(f.options){
              for(var o in f.options){
                if(f.options[o].label){
                  f.options[o].label = this.$t(f.options[o].label);
                }
              }
            }
          }
          if (field_config.required) {
            if (f.validation) {
              f.validation =
                "^required|" + f.validation.replace("optional|", "");
            } else {
              f.validation = "^required";
            }
            f.addStar = true;
          } else {
            if (f.validation) {
              //if (useDebug) console.log("row.validation", row.validation);
              f.validation = "optional|" + f.validation;
            }
          }

          if (
            f &&
            (f.type != "codeverifyinput" ||
              !this.applyForm.form.configJson.showCustomForm)
          )
            schema.push(f);
          if (f && f.type == "codeverifyinput") this.contactSchema = [f];
        })
        .filter((f) => f);

      if (this.applyForm.form.isValidateCaptcha) this.showCaptcha = true;

      const mapping = {
        name: "name",
        phoneNumber: "mobile",
        email: "email",
        address: "address",
        idNumber: "bir",
      };
      // let hideFields = [];
      let fieldConfig = {};
      if(this.applyForm.form.formConfig) this.applyForm.form.formConfig?.map((field_config) => {
        const schemaKey = mapping[field_config.field];
        // if (field_config.hidden) {
        // hideFields.push(schemaKey);
        // }
        fieldConfig[schemaKey] = field_config;
      });

      // schema post process -- like setting vladiation
      schema = schema.map((row) => {
        if (!row["validation-name"] && row["label"]) {
          row["validation-name"] = row["label"];
        }
        return row;
      });

      // build tickets:
      let o = {
        type: "radio",
        name: "ticketId",
        label: this.$t("報名種類"),
        options: joinOptions,
        validation: "required",
        ref: "ticketId",
      };

      if (valid_ticket_count === 0) {
        if (joinOptions.length == 1) {
          this.handleBeforeAfter(this.applyForm.form.tickets[0].availableState);
        } else {
          if (!this.isSurveyForm())
            this.handleNoQuota("normal form valid_ticket_count === 0 ");
        }
      } else if (valid_ticket_count === 1) {
        if (this.hasSurveyForm()) {
          o.wrapper_hidden = "hidden";
        }
        //TODO: auto hidden if only one ticket , might be a configuration by user
        o.otherClasses = "hidden";
        joinOptions.map((o) => {
          if (!o.disabled) {
            if (useDebug) console.log("set only ticket$$$$");
            this.$set(this.values, "ticketId", `${o.value}`);
          }
        });
      }
      if (joinOptions.length) schema.push(o);

      // theLabel.innerText = `${this.$t( "label.參加發言")}[${this.$t('已滿額')}]`
      //       theLabel.classList.add('text-gray-300')
      if (useDebug) console.log({ attendeeAllowSpeak });
      if (attendeeAllowSpeak) {
        const speakCheckbox = {
          type: "checkbox",
          name: "attendeeSpeak",
          id: "attendeeSpeak",
          label: this.$t("label.參加發言"),
          class: "hidden",
          options: [{ id: "attendeeSpeak", label: this.$t("label.Yes") }],
        };
        schema.push(speakCheckbox);
      }

      return schema;
    },

    surveyHasFinish() {
      return !this.isConfigTrue("skipPreviewSurveyAnswsers")
        ? this.surveyStep == this.surveySchema.length
        : this.surveyStep == this.surveySchema.length - 1;
    },
    surveyHasNext() {
      return !this.isConfigTrue("skipPreviewSurveyAnswsers")
        ? this.surveyStep < this.surveySchema.length
        : this.surveyStep < this.surveySchema.length - 1;
    },
    surveyHasPrev() {
      return this.surveyStep > 0;
    },
    surveyHasBackToBase() {
      return (
        !this.isConfigTrue("skipSecondProfilePage") && this.surveyStep == 0
      );
    },
    initSurvey() {
      this.surveyStep = 0;
      this.lastStep = this.surveySchema.length;
    },
    ftest() {
      window.VueFormulate.resetValidation("fff");
    },
    startSurvey() {
      this.showBaseForm = false;
      if (!this.showAnimation) {
        setTimeout(() => {
          this.showSurveyForm = true;
          this.showProgressBar = !this.isConfigTrue("hideProgressBar");
          this.goSurveyStep(0, true);
          // if (!this.useDimension) this.goSurveyStep(0, true);
          // if (this.useDimension) this.goSurveyStep(-2, true);
        }, PAGE_LOAD_TIME);
      } else {
        setTimeout(() => {
          this.showSurveyForm = true;
          this.showProgressBar = !this.isConfigTrue("hideProgressBar");
          this.goSurveyStep(0, true);
          // if (!this.useDimension) this.goSurveyStep(0, true);
          // if (this.useDimension) this.goSurveyStep(-2, true);
        }, 500);
      }
    },
    backtoBase() {
      this.showSurveyForm = false;
      this.showProgressBar = false;
      this.surveyStep = 0;
      if (!this.showAnimation) {
        setTimeout(() => {
          this.showBaseForm = true;
        }, PAGE_LOAD_TIME);
      } else {
        setTimeout(() => {
          this.showBaseForm = true;
        }, 500);
      }
    },

    evaluateShowRecordQuestions() {
      if (!this.originalRecordSchema) return;

      let newRecordSchema = [];

      this.originalRecordSchema.forEach((question) => {
        let added = false;
        let hidden = false;

        if (this.allRecordRelationRules[question.uid]) {
          this.allRecordRelationRules[question.uid]["show"].forEach(
            (showRule) => {
              let currentValue = this.recordValues
                ? this.recordValues[showRule.valueID]
                : null;
              let expectedAnswer =
                showRule.on == "index"
                  ? showRule.options[showRule.index]
                  : showRule.on == "optionId"
                  ? showRule.index
                  : showRule.todo;
              let conditionFulfilled =
                currentValue && showRule.conditionType == "checkbox"
                  ? currentValue.includes(expectedAnswer)
                  : currentValue == expectedAnswer;

              if (
                (!question.defaultHidden && !added) ||
                ((showRule.on == "index" || showRule.on == "optionId") &&
                  currentValue &&
                  conditionFulfilled &&
                  !added)
              ) {
                newRecordSchema.push(question);
                added = true;
              }
            }
          );
          this.allRecordRelationRules[question.uid]["hide"].forEach(
            (hideRule) => {
              let currentValue = this.recordValues
                ? this.recordValues[hideRule.valueID]
                : null;
              let expectedAnswer =
                hideRule.on == "index"
                  ? hideRule.options[hideRule.index]
                  : hideRule.on == "optionId"
                  ? hideRule.index
                  : hideRule.todo;
              let conditionFulfilled =
                currentValue && hideRule.conditionType == "checkbox"
                  ? currentValue.includes(expectedAnswer)
                  : currentValue == expectedAnswer;

              if (
                (hideRule.on == "index" || hideRule.on == "optionId") &&
                currentValue &&
                conditionFulfilled
              ) {
                hidden = true;
                if (added && newRecordSchema.slice(-1)[0].uid == question.uid)
                  newRecordSchema.pop();
              } else if (!added && !hidden) {
                newRecordSchema.push(question);
                added = true;
              }
            }
          );
        } else if (!question.defaultHidden) {
          newRecordSchema.push(question);
        }
      });

      this.recordSchema = newRecordSchema;
    },

    evaluateShowQuestions() {
      if (
        this.surveyStep < 0 ||
        this.surveyStep == this.lastStep ||
        !this.surveySchema
      )
        return;

      let supposeCurrentSurveyQuestions = this.surveySchema[this.surveyStep];
      let newCurrentSurveySchema = [];

      supposeCurrentSurveyQuestions.forEach((question) => {
        let added = false;
        let hidden = false;

        if (this.allRelationRules[question.uid]) {
          this.allRelationRules[question.uid]["show"].forEach((showRule) => {
            let currentValue =
              this.currentSurveyValue &&
              this.currentSurveyValue[showRule.valueID]
                ? this.currentSurveyValue[showRule.valueID]
                : this.surveyValues[showRule.surveyPage]
                ? this.surveyValues[showRule.surveyPage][showRule.valueID]
                : null;
            let expectedAnswer =
              showRule.on == "index"
                ? showRule.options[showRule.index]
                : showRule.on == "optionId"
                ? showRule.index
                : showRule.todo;
            let conditionFulfilled =
              currentValue && showRule.conditionType == "checkbox"
                ? currentValue.includes(expectedAnswer)
                : currentValue == expectedAnswer;

            if (
              (!question.defaultHidden && !added) ||
              ((showRule.on == "index" || showRule.on == "optionId") &&
                currentValue &&
                conditionFulfilled &&
                !added)
            ) {
              newCurrentSurveySchema.push(question);
              added = true;
            }
          });
          this.allRelationRules[question.uid]["hide"].forEach((hideRule) => {
            let currentValue =
              this.currentSurveyValue &&
              this.currentSurveyValue[hideRule.valueID]
                ? this.currentSurveyValue[hideRule.valueID]
                : this.surveyValues[hideRule.surveyPage]
                ? this.surveyValues[hideRule.surveyPage][hideRule.valueID]
                : null;
            let expectedAnswer =
              hideRule.on == "index"
                ? hideRule.options[hideRule.index]
                : hideRule.on == "optionId"
                ? hideRule.index
                : hideRule.todo;
            let conditionFulfilled =
              currentValue && hideRule.conditionType == "checkbox"
                ? currentValue.includes(expectedAnswer)
                : currentValue == expectedAnswer;

            if (
              (hideRule.on == "index" || hideRule.on == "optionId") &&
              currentValue &&
              conditionFulfilled
            ) {
              hidden = true;
              if (
                added &&
                newCurrentSurveySchema.slice(-1)[0].uid == question.uid
              )
                newCurrentSurveySchema.pop();
            } else if (!added && !hidden) {
              newCurrentSurveySchema.push(question);
              added = true;
            }
          });
        } else if (!question.defaultHidden) {
          newCurrentSurveySchema.push(question);
        }
      });

      this.currentSurveySchema = newCurrentSurveySchema;
    },
    goSurveyStep(step, fromFormPage) {
      if (this.surveyStep >= 0) {
        // Remove observer before inserting to survey values. Mainly for vue json viewer.
        const tobeInserted = { ...this.currentSurveyValue };
        this.surveyValues[this.surveyStep] = tobeInserted;
      }

      if (step > this.lastStep) {
        if (useDebug) console.log("doSubmit");
        this.doSubmit();
        return;
      }

      if ((step < -1 || !this.surveySchema[step]) && !(step == this.lastStep))
        return;

      if (this.showAnimation && step == this.lastStep) {
        setTimeout(() => {
          this.surveyStep = step;
        }, 500);
      } else {
        this.surveyStep = step;
      }
      if (!this.showAnimation) {
        if (step < this.lastStep && step > -1) {
          this.currentSurveyValue = this.surveyValues[step];
          this.evaluateShowQuestions();
        } else if (step == this.lastStep) {
          this.currentSurveyValue = this.contactValues;
          this.currentSurveySchema = this.contactSchema;
        } else {
          this.currentSurveyValue = {};
          this.currentSurveySchema = [];
        }
      } else {
        if (!fromFormPage) window.startSurveyAnimation = true;
        setTimeout(() => {
          window.startSurveyAnimation = false;
          if (step < this.lastStep && step > -1) {
            this.currentSurveyValue = this.surveyValues[step];
            this.evaluateShowQuestions();
          } else {
            this.currentSurveyValue = {};
            this.currentSurveySchema = [];
          }
        }, 500);
      }

      this.surveyFormErrors = [];
      this.surveyInputErrors = {};
    },
    moveSurveyStep(step, gosubmit) {
      console.log({ gosubmit });
      if (useDebug) console.log("moveSurveyStep", step);
      if (step < 0) return;

      if (this.surveyStep >= 0) {
        this.nextStep = step;
        // this.surveyValues[this.surveyStep] = this.currentSurveyValue;
        if (useDebug)
          console.log(
            "surveyValues",
            JSON.parse(JSON.stringify(this.surveyValues))
          );

        // Submit/validate only when moving forward
        if (step < this.surveyStep) {
          console.log("x1");
          window.scrollTo({ top: 0, behavior: "smooth" });
          this.goSurveyStep(step);
        } else {
          console.log("x2");
          window.VueFormulate.submit("fff");
        }
      }
    },
    buildSurvey() {
      try {
        window.form2 = window.applyForm.form.customFormMetaJson
          ? JSON.parse(window.applyForm.form.customFormMetaJson)
          : false;
      } catch (e) {
        if (useDebug) console.log({ e });
      }

      const form2 = window.form2;
      const survey = [];
      const cl = this.$i18n.locale;
      const lmap = { "zh-hans": "CN", "zh-hant": "HK", pt: "PT" };
      const toConvert = [
        "label",
        "placeholder",
        "options",
        "remark",
        "optionsV2",
        "content",
      ];
      const subRules = {
        url: "isValidUrl",
        email: "isValidEmail",
        min: "minNoReq",
        max: "maxNoReq",
        before: "beforeNoReq",
        after: "afterNoReq",
      };
      let currentLang = (o) => {
        return o[lmap[cl]];
      };
      if (useDebug) console.log("Second Form: ", { form2 });

      // <FormulateInput
      //               v-if="showCaptcha"
      //               type="captchainput"
      //               name="captchaCode"
      //               :setCaptchaKey="setCaptchaKey"
      //               :addStar="true"
      //               :label="`${$t('驗證碼')}`"
      //               :placeholder="$t('驗證碼')"
      //               :validation="`required|minNoReq:4,false|verifyCaptchaCode:${captchaKey}`"
      //               :validation-messages="{
      //                 verifyCaptchaCode: verifyCodeMesssage,
      //               }"
      //               error-behavior="submit"
      //               v-model="captchaCode"
      //             />

      for (const f in form2) {
        let raw = form2[f];

        /* Widget Rate: Transform to Radio btn */
        if (raw.type == "rate") {
          raw.type = "radio";
          let preset = { "style": {
                "label": "所有答案佔用一條行",
                "value": "one-row"
            },
            "options": {
                
            }};

          let [min,max] = raw.range;
          console.log('!min,max',min,max );
          // build an array from min to max
          let options = [];
          for(let i=min;i<=max;i++){
            options.push({value:i,label:i});
          }
          
          

          let useText = false;
          if(raw.rateStyle){
            if(raw.rateStyle=='TEXT'){
              if(options.length==5){
                console.log('!options',options)
                let hklabels =  [ "極不滿意",  "不滿意", "一般", "滿意", "極滿意"];
                let cnlabels =  [ "极不满意",  "不满意", "一般", "满意", "极满意"];
                let ptlabels =  ["extremamente insatisfeito", "insatisfeito", "médio", "satisfeito", "extremamente satisfeito"];
                let ocn = [...options].map((o,i)=>{
                  return { value: o.value, label:cnlabels[i]};
                })
                let ohk = [...options].map((o,i)=>{
                  return { value: o.value, label:hklabels[i]};
                })
                let opt = [...options].map((o,i)=>{
                  return { value: o.value, label:ptlabels[i]};
                })
                preset.options = {"CN":ocn,"HK":ohk,"PT":opt};
                useText = true;
              }
            }else if(raw.rateStyle=='DOWN'){
              options = options.reverse();
            }
          }
          if(!useText) preset.options = {"CN":options,"HK":options,"PT":options};
          console.log('preset.options', preset.options );
          preset.rules=false;// {max: 10, min: 1};
          preset.validation='required';

          raw = Object.assign(raw, preset);
        }
        toConvert.forEach((item) => {
          if (raw[item]) raw[item] = currentLang(raw[item]);
        });
        if (raw.slug == "captcha") {
          raw.type = "captchainput";
          raw.setCaptchaKey = this.setCaptchaKey;
          raw.addStar = true;
          raw.value = this.captchaCode;
          raw.id = "captcha";
          raw.label = this.$t("驗證碼");
          raw.placeholder = this.$t("驗證碼");
          raw.validation = `required|minNoReq:4,false|verifyCaptchaCode:*`;
          console.log("captcha validation", raw.validation);
          raw.validationMessages = {
            verifyCaptchaCode: this.verifyCodeMesssage,
          };
        }
        if (raw.rules) {
          if (raw.rules.suffix) {
            // const mimeTypes = {
            //   'png':'image'
            // }
            //raw.rules.mime = raw.rules.suffix.split(',').map( m=>  mimeTypes[m] ? `${mimeTypes[m]}/${m}` : m )

            delete raw.rules.suffix;
          }
          delete raw.rules.maxCount;
          delete raw.rules.maxSize;
          const rules = Object.keys(raw.rules)
            .filter((key) => raw.rules[key])
            .map((key) =>
              raw.rules[key] === true
                ? subRules[key]
                  ? subRules[key]
                  : key
                : `${subRules[key] ? subRules[key] : key}:${raw.rules[key]}${
                    key == "min" || key == "max"
                      ? `,${raw.rules.number ? raw.rules.number : "false"}`
                      : ""
                  }`
            );
          if (rules.length) raw.validation = rules.join("|");
        }
        if (raw.default)
          raw.value =
            raw.type == "datetime-local"
              ? raw.default.replace(" ", "T")
              : raw.default;
        if (raw.optionsV2) {
          raw.optionsV2.forEach((item) => {
            item.value = item.id;
          });
          raw.options = raw.optionsV2;
          this.useOptionsV2 = true;
        }
        raw["help-position"] = "after";
        raw.isQuestionTop = true;
        raw.tooltip = this.generateHelpText(raw.rules, raw.type);
        raw.addStar = raw.rules && raw.rules.required;
        raw.deselectFunc = this.deselectRadio;
        raw.validationName = raw.label;
        raw.defaultHidden = raw.hidden ? raw.hidden : false;
        raw.defaultType = raw.type;
        if (raw.type == "editor") {
          raw.label = "-";
          raw.help = false;
        } else {
          raw.help = true;
        }
        raw.hidden = false;
        raw.getOpinion = this.getOpinion;

        if (raw.type == "date" || raw.type == "datetime-local") {
          raw.type = "customdate";
          raw.dateType = raw.defaultType;
          raw.datePlaceholder = raw.placeholder;
          // raw.onfocus=`this.type='${raw.defaultType}'`
        }

        if (raw.type == "text") {
          raw.maxlength = raw.rules.max ? raw.rules.max : 255;
        }

        if (raw.type == "file") {
          console.log("file!", raw);
          raw.multiple = true;
          // raw['@file-upload-progress'] = async ()=>{
          //   console.log('@file-upload-progress',arguments)
          // }
          // raw['@file-upload-complete'] = async ()=>{
          //   console.log('@file-upload-complete',arguments)
          // }
          // raw['@file-upload-error'] = async (options)=>{
          //   console.log('@file-upload-error',options)
          // }
          raw["@file-removed"] = async () => {
            console.log("@file-removed", arguments);
          };
          const that = this;
          const acceptTypes = "image/png,image/jpeg,application/pdf".split(",");
          raw.accept = acceptTypes;
          raw.uploader = async (file, progress, error, option) => {
            progress(0);
            const currentForumateValue =
              that.currentSurveyValue[`file_${raw.id}`];
            const fileCount = currentForumateValue.files.length;
            try {
              if (fileCount > 3) {
                console.log(" check again ");
                throw new Error(this.trans("label.file-count-limit"));
              }
              if (acceptTypes.indexOf(file.type) == -1) {
                throw new Error(this.trans("label.file-type-limit"));
              }
              if (file.size > 5 * 1024 * 1024) {
                //hardcode
                throw new Error(this.trans("label.file-size-limit"));
              }
              console.log("uploader", { file, progress, error, option, that });
              var result = await uploadFileAttachment(
                formKey,
                raw.id,
                file,
                progress
              );
              progress(100);
              console.log("uploader result", result);
              return result;
            } catch (e) {
              console.log(`error file_${raw.id}`);

              const currentForumateValue =
                that.currentSurveyValue[`file_${raw.id}`];
              console.log({
                that,
                s: that.currentSurveyValue,
                files: currentForumateValue.files,
              });
              if (currentForumateValue?.files) {
                for (let fi in currentForumateValue.files) {
                  const fileobj = currentForumateValue.files[fi];

                  //console.log('fileobj', (JSON.stringify(fileobj) ))
                  console.log({ fileobj });
                  if (fileobj.file == file) {
                    console.log("removeFile");
                    setTimeout(() => {
                      fileobj.removeFile();
                    }, 1000);
                  }
                }
              }
              that.$toast.error(e.message, {
                position: "top-center",
                timeout: 2698,
                closeOnClick: true,
                pauseOnFocusLoss: true,
                pauseOnHover: true,
                draggable: true,
                draggablePercent: 0.6,
                showCloseButtonOnHover: false,
                hideProgressBar: false,
                closeButton: "button",
                icon: true,
                rtl: false,
              });
              return Promise.resolve({});
            }
          };
        }
        if (this.$i18n.locale == "pt") {
          //TODO: force pt to use default
        } else {
          if (
            raw.style &&
            raw.style != "default" &&
            Object.keys(formItemStyles).includes(raw.type)
          ) {
            Object.keys(
              formItemStyles[raw.type][raw.style.value].styles
            ).forEach((style) => {
              console.log(`!Custom Styling: [${raw.type}] - ${raw.style.value} ... ${formItemStyles[raw.type][raw.style.value].styles[style]}`);
              raw[style] =
                formItemStyles[raw.type][raw.style.value].styles[style];
            });
          }
        }

        if (raw.group)
          raw["outer-class"]
            ? (raw["outer-class"] += " pl-3 mt-4 ")
            : (raw["outer-class"] = "pl-3 mt-4 ");

        if (raw.type !== null) survey.push(raw);
      }

      let chunks = [];
      let chunk = [];
      survey.forEach((s) => {
        if (s.type != "page-seperator") {
          chunk.push(s);
        } else if (chunk.length) {
          chunks.push(chunk);
          chunk = [];
        }
      });
      if (chunk.length) chunks.push(chunk);
      console.log("!", { survey, chunk });
      return chunks;
    },

    getOpinion(name) {
      return this.currentSurveyValue[`${name}_opinion`];
    },

    generateHelpText(rules, type) {
      if (!rules) return;
      let result = "";
      let tempText = this.tempTextI18N[this.$i18n.locale];

      let ruleKeys = Object.keys(rules).filter((x) => {
        return rules[x];
      });
      if (ruleKeys.includes("required")) {
        result += this.$t(`必需輸入`);
        if (ruleKeys.length > 1) result += "; ";
        let index = ruleKeys.indexOf("required");
        ruleKeys.splice(index, 1);
      }

      ruleKeys.forEach((key, idx) => {
        switch (key) {
          case "min":
            result +=
              (type == "checkbox" ? tempText.minSelect : tempText.minChar) +
              ` ${rules[key]}`;
            break;

          case "max":
            result +=
              (type == "checkbox" ? tempText.maxSelect : tempText.maxChar) +
              ` ${rules[key]}`;
            break;

          case "number":
            result += rules[key] ? tempText.numbersOnly : "";
            break;

          case "email":
            result += rules[key] ? tempText.emailOnly : "";
            break;

          case "url":
            result += rules[key] ? tempText.urlOnly : "";
            break;

          case "before":
            result += rules[key] ? tempText.before(rules[key]) : "";
            break;

          case "after":
            result += rules[key] ? tempText.after(rules[key]) : "";
            break;

          case "macauPhone":
            result += rules[key] ? this.$t("請輸入8位澳門流動電話號碼") : "";
            break;

          default:
        }
        result += idx != ruleKeys.length - 1 ? "; " : "";
      });

      return result;
    },

    hasSurveyForm() {
      // 如果 this.applyForm.form.formConfig 無值，強制使用customForm 
      return !this.applyForm.form.formConfig || this.configJson.showCustomForm;
    },

    hasIntroPage() {
      return !this.isConfigTrue("skipFirstIntroPage");
    },

    hasProfilePage() {
      return !this.isConfigTrue("skipSecondProfilePage");
    },

    isConfigTrue(key) {
      return typeof this.configJson[key] != "undefined" && this.configJson[key];
    },

    formConfig(key, defaultVal) {
      return typeof this.configJson[key] != "undefined"
        ? this.configJson[key]
        : defaultVal;
    },

    isSurveyForm() {
      return window.applyForm.form.customFormMetaJson ? true : false;
    },
    moveAwayFromStartPage(noAnimation) {
      if (!this.hasProfilePage() && this.hasSurveyForm()) {
        this.startSurvey();
      }
      this.isAtStartForm = false;
      if (this.surveyStartTime == null) {
        this.surveyStartTime = new Date();
      }
      if (!this.showAnimation || noAnimation) {
        setTimeout(() => {
          this.showBaseForm = true;
          this.showShortDesc = true;
        }, PAGE_LOAD_TIME);
      } else {
        setTimeout(() => {
          this.showBaseForm = true;
          this.showShortDesc = true;
        }, 500);
      }
    },
    moveToStartPage() {
      this.showBaseForm = false;
      this.showShortDesc = false;
      if (!this.showAnimation) {
        setTimeout(() => {
          this.isAtStartForm = true;
        }, PAGE_LOAD_TIME);
      } else {
        setTimeout(() => {
          this.isAtStartForm = true;
        }, 500);
      }
    },
    getDeviceDetails() {
      let parser = new UAParser(window.navigator.userAgent);
      let result = parser.getResult();

      let toReturn = {
        browser: result.browser.name,
        browserVersion: result.browser.version,
        deviceType: result.device.type ? result.device.type : "desktop",
        deviceModel: result.device.model ? result.device.model : "desktop",
        os: result.os.name,
        osVersion: result.os.version,
      };

      return toReturn;
    },
    extractRelationRules() {
      let result = {};
      let recordResult = {};

      const extraction = (schema, dict) => {
        schema.forEach((page, pageIndex) => {
          page.forEach((question) => {
            if (question.relationRules) {
              question.relationRules.forEach((rule) => {
                if (!dict[rule.relateField])
                  dict[rule.relateField] = { show: [], hide: [] };
                rule.valueID = `${question.type}_${question.id}`;
                rule.surveyPage = pageIndex;
                rule.options = question.options;
                rule.optionsV2 = question.optionsV2;
                rule.todo =
                  "TODO -> Purpose of this is when condition is not from list but rather a string value(or any other value";
                rule.conditionType = question.type;
                dict[rule.relateField][rule.action].push(rule);
              });
            }
          });
        });
      };

      extraction(this.surveySchema, result);
      extraction([this.recordSchema ? this.recordSchema : []], recordResult);

      this.allRelationRules = result;
      this.allRecordRelationRules = recordResult;
    },
    deselectRadio(id) {
      if (this.currentSurveyValue[id]) this.currentSurveyValue[id] = null;
      if (this.recordValues[id]) this.recordValues[id] = null;
    },
    setCaptchaKey(id) {
      console.log(`setCaptchaKey:${id}`);
      if (!id) return;
      window.CaptchaKey = id;
      this.captchaKey = id;
      return;
    },
    setCodeKey(id) {
      if (!id) return;
      this.values.verifyKey = id;
      return;
    },
    setCodeValue(value) {
      if (!value) return;
      this.values.verifyCode = value;
      return;
    },
    recordFormBlur(context) {
      let slug = context.slotProps.addMore.slug;
      if (slug && slug != "verifyCode" && slug != "verifyKey")
        this.values[slug] = context.model;
    },
    buildRecordForm() {
      this.useRecord = this.applyForm.form.useRecord;
      if (!this.useRecord) return null;


      let prasedRecordInfo;
      let validateContact = false;
      try {
        prasedRecordInfo = JSON.parse(this.applyForm.form.recordInfo);
      } catch (e) {
        if (useDebug) console.log("Prasing Record Info Error: ", e);
      }

      const lmap = { "zh-hans": "CN", "zh-hant": "HK", pt: "PT" };
      const toConvert = [
        "label",
        "placeholder",
        "options",
        "remark",
        "optionsV2",
      ];
      const subRules = {
        url: "isValidUrl",
        email: "isValidEmail",
        min: "minNoReq",
        max: "maxNoReq",
        before: "beforeNoReq",
        after: "afterNoReq",
      };
      const currentLang = (o) => {
        return o[lmap[this.$i18n.locale]];
      };

      // let dimensionIdx = [];
      for (const idx in prasedRecordInfo) {
        const field = prasedRecordInfo[idx];

        if (field.slug == "phoneNumber") {
          field.rules.macauPhone = true;
          if (field.advanceRules.validationCode && !validateContact) {
            field.type = "codeverifyinput";
            validateContact = true;
            this.contactVerifyType = "number";
            if (useDebug) {
              field.value = "66502155";
              field.codeValue = "123456";
            }
          }
        }

        if (
          field.slug == "email" &&
          field.advanceRules.validationCode &&
          !validateContact
        ) {
          field.type = "codeverifyinput";
          validateContact = true;
          this.contactVerifyType = "text";
        }

        if (field.slug == "idNumber") field.rules.macauID = true;
        if (field.slug == "tickets") {
          field.type = "customticket";
          field.ticketList = this.applyForm.form.tickets;
          this.useRecordTickets = true;
          if (this.applyForm.form.tickets?.length == 1) {
            //TODO:hardcode
            field.value = this.applyForm.form.tickets[0].id;
          }
        }

        if (field.slug == "attendeeSpeak") {
          //TODO:hardcode
          field.id = "attendeeSpeak";
        }
        if (field.slug == "customticket_ticketcount") {
          //TODO:hardcode
          field.id = "customticket_ticketcount";
        }
        if (field.slug == "sessions") {
          field.type = "customsession";
          field.sessionList = this.applyForm.form.sessions;
          this.useRecordSession = true;
          if (this.applyForm.form.sessions?.length == 1) {
            //TODO:hardcode
            field.value = this.applyForm.form.sessions[0].id;
          }
        }

        toConvert.forEach((item) => {
          if (field[item]) field[item] = currentLang(field[item]);
        });
        if (field.rules) {
          const rules = Object.keys(field.rules)
            .filter((key) => field.rules[key])
            .map((key) =>
              field.rules[key] === true
                ? subRules[key]
                  ? subRules[key]
                  : key
                : `${subRules[key] ? subRules[key] : key}:${field.rules[key]}${
                    key == "min" || key == "max"
                      ? `,${field.rules.number ? field.rules.number : "false"}`
                      : ""
                  }`
            );
          if (rules.length) field.validation = rules.join("|");
        }
        if (field.optionsV2) {
          field.optionsV2.forEach((item) => {
            item.value = item.id;
          });
          field.options = field.optionsV2;
          this.useOptionsV2 = true;
        }

        field["help-position"] = "after";
        field.isQuestionTop = true;
        field.tooltip = this.generateHelpText(field.rules, field.type);
        field.addStar = field.rules && field.rules.required;
        field.validationName = field.label;
        field.defaultHidden = field.hidden ? field.hidden : false;
        field.deselectFunc = this.deselectRadio;
        field.help = true;
        field.hidden = false;
        field.setCodeKey = this.setCodeKey;
        field.setCodeValue = this.setCodeValue;
        field["@blur-context"] = this.recordFormBlur;
      }

      let liveParsedRecordInfo = prasedRecordInfo.filter(
        (x) => !x.defaultHidden
      );

      if (useDebug) console.log("Final parsed Record Schema", prasedRecordInfo);
      return {
        prasedRecordInfo,
        liveParsedRecordInfo,
      };
    },

    // End of methods
  },

  async mounted() {
    // Register language
    // const that = this;
    this.setFormLang(this.$i18n.locale);
    window.comp = this;

    // Register captcha listeners
    if (useDebug) console.log("window.$vue", window.$vue);
    if (window.$vue) {
      if (useDebug) console.log("refreshCaptcha hook");
      window.$vue.$on("verifyCodeRule", async function () {
        if (useDebug) console.log("verifyCodeRule fired");
      });
      window.$vue.$on("refreshCaptcha", async function () {
        if (useDebug) console.log("refreshCaptcha fired");
        // await that.getCaptcha();
      });
    }

    // Start loading event form
    this.isLoading = false;
    let data = await this.getEvent(formKey);


    if(data.form?.event?.frontendConfig?.i18n){
        console.log('x i18n',data.form?.event?.frontendConfig?.i18n)
        updateMessages(data.form?.event?.frontendConfig?.i18n);
    }

    if (data.form)
      this.hideAcceptTnc = data.form.configJson
        ? data.form.configJson.hideAcceptTnc || false
        : false;

    if (useDebug) console.log("mounted", { data });
    if (!data) return;

    // Register Messages
    let userMessage = false;
    let msgTemplateRaw = await getMessageTemplate("term");
    if (msgTemplateRaw) {
      userMessage = messageTemplateToI18n(msgTemplateRaw);
    }
    window.userMessage = userMessage;
    if (userMessage) {
      if (useDebug) console.log("updateMessages", { userMessage });
      updateMessages(userMessage);
    }

    let trustTemplateRaw = await getMessageTemplate("information_policy");
    if (trustTemplateRaw) {
      let convertedTrust = {};
      trustTemplateRaw.messageTemplateTranslations?.forEach((item) => {
        convertedTrust[
          item.locale == "zh_HK"
            ? "zh-hant"
            : item.locale == "zh_CN"
            ? "zh-hans"
            : "pt_PT"
        ] = item.content;
      });
      this.trustPolicy = convertedTrust;
    }

    // Build Survey
    this.schema = this.getApplyFormSchema();
    let recordBuild = this.buildRecordForm();
    this.recordSchema = recordBuild?.liveParsedRecordInfo;
    this.originalRecordSchema = recordBuild?.prasedRecordInfo;
    this.surveySchema = this.buildSurvey();
    this.isSurvey = data?.form?.frontendConfig?.customForm?.length == 1;

    this.initSurvey();
    this.extractRelationRules();
    this.evaluateShowQuestions();

    // Finish loading, register sms listeners and captcha verifiers
    this.isLoading = true;
    this.ready = true;
    this.countingHandler = setInterval(() => {
      if (this.isCounting) {
        this.counting = this.counting - 1;
        const smsbtn = document.querySelector("button[name=check-code-btn]");
        if (smsbtn) {
          smsbtn.textContent =
            this.$t(`label.receive-verification-code`) + `(${this.counting})`;
          smsbtn.disabled = true;
        }

        if (this.counting <= 0) {
          this.isCounting = false;
          this.counting = 0;
          smsbtn.textContent = this.$t(`label.receive-verification-code`);
          smsbtn.disabled = false;
        }
        //: this.counting });
      }
    }, 1000);
    if (this.applyForm.form.isValidateCaptcha) {
      // await this.getCaptcha();
    }

    //if (useDebug) console.log('refs',this.$refs);
    //if (useDebug) console.log('baseForm refs',this.$refs.baseForm.$el.querySelector('.name_ticketId'));

    if (!this.hasProfilePage() && !this.hasIntroPage()) {
      this.startSurvey();
    } else if (!this.hasIntroPage()) {
      this.moveAwayFromStartPage(true);
    }

    this.refreshI18n();
    setTimeout(() => {
      this.refreshTickets();
    }, 100);
    setTimeout(() => {
      this.refreshTickets();
    }, 1000);
  },
  created() {},
  computed: {
    formSchema() {
      return this.schema;
    },

    withTransition() {
      return this.showAnimation ? "fade" : "";
    },

    isMobileSharable() {
      if (!navigator) return false;
      if (!navigator.userAgent) return false;
      if (
        !/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        // Gets in here if not mobile
        return false;
      }
      if (!navigator.share) return false;
      return true;
    },

    verifyCodeMesssage() {
      return window.captchaMessage
        ? this.$t(window.captchaMessage)
        : this.$t("驗證碼不正確");
    },

    getVerifyRepeatSessionMessage() {
      return window.verifyRepeatSessionMessage
        ? this.$t(window.verifyRepeatSessionMessage)
        : "";
    },

    getVerifyRepeatFormMessage() {
      return window.verifyRepeatFormMessage
        ? this.$t(window.verifyRepeatFormMessage)
        : "";
    },

    ...mapModalComputed(),
  },
  errorCaptured(err, vm, info) {
    console.log(`cat EC: ${err.toString()}\ninfo: ${info}`, err);
    return false;
  },
  data() {
    return {
      isAtStartForm: true,
      showShortDesc: false,
      useDebug,
      inviteCode: false,
      ready: false,
      banner: "",
      forceUpdate: 0,
      isValid: false,
      isLoading: false,
      isSubmitLoading: false,
      isCounting: false,
      counting: 0,

      term: false,
      eventTerm: false,
      publicUrl: false,
      showQrModal: false,
      acceptTnc: false,
      attendeeSpeak: true,
      channel: false,
      shareText: "",
      shareText1: "",
      shareText2: "",
      abc: {
        name: "Test from Form",
        email: "tim+test@wizmacau.com",
        phoneNumber: "61234567",
        sms_code: "1234",
        idNumber: "12345678",
        session_id: "1",
        agency: "agency",
        group: "group",
        address: "address" + new Date().toLocaleString(),
      },
      // Record form(custom base form)
      useRecord: false,
      disableBaseBasic: false,
      recordSchema: [],
      originalRecordSchema: [],
      recordValues: {},
      useRecordTickets: false,
      useRecordSession: false,
      contactVerifyType: null,
      contactSchema: [],
      contactValues: {},
      useOptionsV2: false,
      // Base form(form)
      schema: [],
      values: {},
      formErrors: [],
      inputErrors: {},
      // Survey Form(form2)
      isSurvey: false,
      showBaseForm: false,
      showSurveyForm: false,
      showProgressBar: false,
      showDebug: false,
      surveyStep: -1,
      nextStep: -1,
      lastStep: -1,
      surveySchema: [],
      surveyValues: [],
      currentSurveyValue: {},
      currentSurveySchema: [],
      surveyFormErrors: [],
      surveyInputErrors: {},
      allRelationRules: {},
      allRecordRelationRules: {},
      surveyStartTime: null,
      surveyEndTime: null,
      //
      showCaptcha: false,
      captchaKey: null,
      captchaCode: null,

      trustPolicy: { pt: "", "zh-hans": "", "zh-hant": "" },
      //   captchaInformation: {show: false},
      //   showAnimation: useDebug,
      showAnimation: false,
      configJson: {},
      tempTextI18N: {
        en: {
          minSelect: "Minimum selection",
          maxSelect: "Maximum selection",
          numbersOnly: "Numbers only",
          emailOnly: "Must be email",
          urlOnly: "Must be url",
          minChar: "Minimum characters",
          maxChar: "Maximum characters",
          before: (beforeDate) => {
            return `date must be before ${beforeDate}`;
          },
          after: (afterDate) => {
            return `date must be after ${afterDate}`;
          },
          start: "Start",
          startSurvey: "Start survey",
          nextPage: "Next Page",
          prevPage: "Previous Page",
          systemError: "System Error",
        },
        pt: {
          minSelect: "Seleção mínima",
          maxSelect: "Seleção máxima",
          numbersOnly: "Apenas números",
          emailOnly: "Deve ser e-mail",
          urlOnly: "Deve ser URL",
          minChar: "Caracteres mínimos",
          maxChar: "Máximo de caracteres",
          before: (beforeDate) => {
            return `a data deve ser anterior ${beforeDate}`;
          },
          after: (afterDate) => {
            return `a data deve ser posterior ${afterDate}`;
          },
          start: "Começar",
          startSurvey: "iniciar o questionário",
          nextPage: "próxima página",
          prevPage: "página anterior",
          systemError: "Problema no sistema, tente novamente",
        },
        "zh-hans": {
          minSelect: "至少选择数",
          maxSelect: "最多可选择数",
          numbersOnly: "必须为数字",
          emailOnly: "必须为email",
          urlOnly: "必须为url",
          minChar: "至少輸入字符數",
          maxChar: "最多輸入字符數",
          before: (beforeDate) => {
            return `必須在 ${beforeDate} 之前`;
          },
          after: (afterDate) => {
            return `必須在 ${afterDate} 之后`;
          },
          start: "开始",
          startSurvey: "开始问卷",
          nextPage: "下一页",
          prevPage: "上一页",
          systemError: "系统问题，请再试过",
        },
        "zh-hant": {
          minSelect: "至少選擇數",
          maxSelect: "最多可選擇數",
          numbersOnly: "必須為數字",
          emailOnly: "必須為email",
          urlOnly: "必須為url",
          minChar: "至少輸入字符數",
          maxChar: "最多輸入字符數",
          before: (beforeDate) => {
            return `必須在 ${beforeDate} 之前`;
          },
          after: (afterDate) => {
            return `必須在 ${afterDate} 之後`;
          },
          start: "開始",
          startSurvey: "開始問卷",
          nextPage: "下一頁",
          prevPage: "上一頁",
          systemError: "系統問題，請再試過",
        },
      },

      hideAcceptTnc: false,
    };
  },
};
</script>

<style scoped>
.cheatbtn {
  opacity: 3%;
}

.formBtn {
  @apply py-3 px-16 bg-yellow-500 text-white m-1 cursor-pointer;
}

.formBtn:focus-visible {
  @apply outline-none;
}

.formBtn-green {
  @apply py-3 px-16 bg-green-500 text-white m-1 cursor-pointer;
}

.formBtn-green:focus-visible {
  @apply outline-none;
}

.pill {
  @apply bg-blue-500  text-white font-bold py-2 px-4 rounded-full;
}

.pill:hover {
  @apply bg-blue-700;
}
[name="captchaButton"] {
  min-width: 100px;
}

.modal {
  transition: opacity 0.25s ease;
}
body.modal-active {
  overflow-x: hidden;
  overflow-y: visible !important;
}

.hidethis {
  opacity: 0;
  position: absolute;
  z-index: -9999;
  pointer-events: none;
}

.debug_button {
  background-color: darkgoldenrod;
  border: black;
  border-width: 2px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>