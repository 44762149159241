import 'core-js/stable';
import 'regenerator-runtime/runtime';
if (!String.prototype.includes) {
  String.prototype.includes = function(search, start) {
    if (typeof start !== "number") {
      start = 0;
    }

    if (start + search.length > this.length) {
      return false;
    } else {
      return this.indexOf(search, start) !== -1;
    }
  };
}

const useDebug =
  window.location.href.indexOf("127.0.0.1") >= 0 ||
  window.location.href.indexOf("localhost") >= 0;
// const useDebug = false;


  
import Vue from 'vue'
import VueRouter from 'vue-router'
import VueRaven from 'vue-raven'

//toast
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

Vue.use(Toast, {
  transition: "Vue-Toastification__bounce",
  maxToasts: 20,
  newestOnTop: true
});



if(process.env.VUE_APP_RAVEN_URI){
  console.log('raven!'+process.env.VUE_APP_RAVEN_URI)
  Vue.use(VueRaven, {
    dsn: process.env.VUE_APP_RAVEN_URI
  });
}

import store from './store'

import App from './App.vue'
import Main from './view/Main.vue'
import End from './view/End.vue'
import LanguageSelection from './view/LanguageSelection.vue'
import FormSelection from './view/FormSelection.vue'
import Thankyou from './view/Thankyou.vue'
import Attendee from './view/Attendee.vue'
import ErrorDialog from "./view/ErrorDialog.vue";
import StopDialog from "./view/StopDialog.vue";
import EnterInviteCode from './view/EnterInviteCode.vue';

import './assets/tailwind.css';
import './assets/form-item-styling.css';
import './assets/form.css';

import VueFormulate from '@braid/vue-formulate';
import pt from './utils/vue-formulate-i18n-pt';
import zh_hant from "./utils/vue-formulate-i18n-zh-hant";
import zh_hans from "./utils/vue-formulate-i18n-zh-hans";

import i18n from "./libs/i18n"


import { verifyCode , verifyCaptchaCode, checkCanCommit} from "./api/fetchEvent";

import HTMLRenderer from './components/HTMLRenderer';
import CaptchaInput from './components/formcomponents/CaptchaInput';
import CodeVerifyInput from './components/formcomponents/CodeVerifyInput';
import CustomDate from './components/formcomponents/CustomDate';
import CustomTickets from './components/formcomponents/CustomTickets';
import CustomSessions from './components/formcomponents/CustomSessions';
import {
  getFormQueryString,
} from "./utils/helper";

Vue.component('HTMLRenderer', HTMLRenderer);
Vue.component('CaptchaInput', CaptchaInput);
Vue.component('CodeVerifyInput', CodeVerifyInput);
Vue.component('CustomDate', CustomDate);
Vue.component('CustomTickets', CustomTickets);
Vue.component('CustomSessions', CustomSessions);

// /* use font awsome*/
// import { library } from "@fortawesome/fontawesome-svg-core";
// import { faShare } from "@fortawesome/free-solid-svg-icons";
// library.add(faShare);
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
Vue.component("font-awesome-icon", FontAwesomeIcon);


import CustomLabel from "./components/formcomponents/CustomLabel.vue";
import CustomHelp from "./components/formcomponents/CustomHelp.vue";
Vue.component("CustomLabel", CustomLabel);
Vue.component("CustomHelp", CustomHelp);

import VTooltip from 'v-tooltip';
Vue.use(VTooltip, {
  defaultTrigger: window.innerWidth > 768 ? 'hover' : 'click'
})


// ----- Helper Function ----- //

// let isMobile = () => {
//     if (!navigator) return false;
//     if (!navigator.userAgent) return false;
//     if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
//         return true;
//     }
//     return false;
// }

function validURL(str) {
    var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
      '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
    return !!pattern.test(str);
}

function validateEmail(str) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(str);
}
  

// ----- End of Helper Function ----- //

window.VueFormulate = VueFormulate;
let $vue = null;
window.$vue=null
let tagi=1
Vue.use(VueFormulate, {
    library:{
        captchainput: {
            classification: 'text',
            component: 'CaptchaInput',
            slotProps: ['setCaptchaKey', 'addStar']
        },
        codeverifyinput: {
            classification: 'text',
            component: 'CodeVerifyInput',
            slotProps: ['setCodeKey', 'slug', 'setCodeValue', 'addStar']
        },
        customdate: {
          classification: 'text',
          component: 'CustomDate',
          slotProps: ['dateType', 'datePlaceholder']
        },
        customticket: {
          classification: 'radio',
          component: 'CustomTickets',
          slotProps: ['ticketList']
        },
        customsession: {
          classification: 'radio',
          component: 'CustomSessions',
          slotProps: ['sessionList']
        },
        html:{
            classification: 'html',
            component: 'FormulateHTML',
            slotProps: {
                component: ['html']
        },
    }

  },
  plugins: [zh_hant, zh_hans, pt],
  validationNameStrategy: ["validationName", "label", "name", "type"],
  rules: {
    verifyUploading(context){
      const values = context.getFormValues()
      let isFileUploading = false;
      let hasFile = false;
      if(values){
        for(let key in values){
          const vv = values[key];
          if(!vv) break;
          if(vv.context?.type=='file'){
              hasFile = true;
              for(let fii in vv.files ){
                const fileupload = vv.files[fii];
                console.log(`file input ${fii}`, {fileupload})
                if(fileupload.progress != 100){
                  isFileUploading = true;
                  break;
                }
              }
          }
        }
      }
      return hasFile&&isFileUploading ? false : true;
    },
    async verifyReapeatSession(context, type, sessionId, formId) {
      try {
        if (type == 'mobile' && context.value.length != 8) return true;
        if (type == 'idCard' && !context.value.match(/\b\d{8}\b/)) return true;

        let query = { sessionId, formId };
        query[type] = context.value;
        let verifyCanCommitSession = await checkCanCommit(query);
        window.verifyRepeatSessionMessage = verifyCanCommitSession.message == 'OK' ? false : verifyCanCommitSession.message
        if (useDebug) console.log({ verifyCanCommitSession });
        return verifyCanCommitSession.data;
      } catch (e) {
          window.eee = e;
          return false;
      }
    },
    async verifyReapeatForm(context, type, formId) {
      try {
        let query = { formId };
        query[type] = context.value;
        let verifyCanCommit = await checkCanCommit(query);
        window.verifyRepeatFormMessage = verifyCanCommit.message == 'OK' ? false : verifyCanCommit.message
        if (useDebug) console.log({ verifyCanCommit });
        return verifyCanCommit.data;
      } catch (e) {
          window.eee = e;
          return false;
      }
    },
    async verifyCodeRule(context, verifiyKeyField, verifiyCodeField, isCaptcha) {
        if(window.$vue) window.$vue.$emit("verifyCodeRule");
        let c = context.getFormValues();
        if (useDebug) console.log({c, context, x: context.getGroupValues()})
        if (isCaptcha) c = c.captchaCode;
        let id = c[verifiyKeyField];
        let inputVerifyCode = c[verifiyCodeField];
        if (!inputVerifyCode) return false;
        if (useDebug) console.log("verifyCodeRule", {
            context,
            c,
            id,
            verifiyKeyField,
            verifiyCodeField,
            inputVerifyCode,
            isCaptcha
        });
        if (id && inputVerifyCode) {
            try {
                window.captchaMessage = ''
                let verifyCodeResp = isCaptcha
                    ? await verifyCaptchaCode(id, inputVerifyCode)
                    : await verifyCode(id, context.value);
                if (useDebug) console.log({ verifyCodeResp });
            
                if (verifyCodeResp.expired && window.$vue) window.$vue.$emit("refreshCaptcha");
                window.captchaMessage = verifyCodeResp.message == 'OK' ? false : verifyCodeResp.message
                return verifyCodeResp.data;
            } catch (e) {
                window.eee = e
                return false
            }
        } else {
            return false;
        }
    },
    async verifyContactCode(context, verifyKey) {
        if (context.value.length == 0) return true;
        if (context.value.length != 6) return false;

        try {
            window.verifyCodeMessage = '';
            let verifyCodeResp = await verifyCode(verifyKey, context.value);
            if (useDebug) console.log({ verifyCodeResp });

            window.verifyCodeMessage = (verifyCodeResp.message == 'OK') ? false : verifyCodeResp.message;
            return verifyCodeResp.data;
        } catch (e) {
            window.eee = e;
            return false;
        }
    },
    async verifyCaptchaCode(context, verifyKey) {
        if (context.value.length != 4) return false;
        if (useDebug) console.log("Verifying captcha: ", verifyKey, context.value);
        try {
            window.captchaMessage = '';
            let verifyCodeResp = await verifyCaptchaCode(verifyKey, context.value);
            if (useDebug) console.log({ verifyCodeResp });

            if (verifyCodeResp.expired && window.$vue) window.$vue.$emit("refreshCaptcha");
            window.captchaMessage = verifyCodeResp.message == 'OK' ? false : verifyCodeResp.message
            return verifyCodeResp.data;
        } catch (e) {
            window.eee = e;
            return false;
        }
    },
    isValidUrl(context) {
        if (context.value == '') return true;
        return validURL(context.value);
    },
    isValidEmail(context) {
        if (context.value == '') return true;
        return validateEmail(context.value);
    },
    minNoReq(context, minNum, isNumber) {
        if ((context.value == '') || (context.value.length == 0)) return true;
        if (isNumber == 'true') {
            let parsedNumber = Number(context.value);
            if (Number.isNaN(parsedNumber)) return false;
            return (parsedNumber >= minNum);
        } else {
            return context.value.length >= minNum;
        }
    },
    maxNoReq(context, maxNum, isNumber) {
        if ((context.value == '') || (context.value.length == 0)) return true;
        if (isNumber == 'true') {
            let parsedNumber = Number(context.value);
            if (Number.isNaN(parsedNumber)) return false;
            return (parsedNumber <= maxNum);
        } else {
            return context.value.length <= maxNum;
        }
    },
    afterNoReq(context, afterDate) {
        if (context.value == '') return true;
        let parsedAfterDate = new Date(afterDate);
        let parsedInputDate = new Date(context.value);
        if ((parsedInputDate instanceof Date) && (!isNaN(parsedInputDate))) {
            return parsedInputDate >= parsedAfterDate;
        } else {
            return false;
        }
    },
    beforeNoReq(context, beforeDate) {
        if (context.value == '') return true;
        let parsedBeforeDate = new Date(beforeDate);
        let parsedInputDate = new Date(context.value);
        if ((parsedInputDate instanceof Date) && (!isNaN(parsedInputDate))) {
            return parsedInputDate <= parsedBeforeDate;
        } else {
            return false;
        }
    },
    macauID(context) {
        if (context.value == '') return true;
        return context.value.match(/\b\d{8}\b/);
    },
    macauPhone(context) {
        if (context.value == '') return true;
        return context.value.match(/^6\d{7}\b/);
    }

  },
  classes: {
    labelHasValue: 'has-value',
    labelHasErrors: 'has-errors',
    elementHasValue : 'element-has-value',
    inputHasValue : 'input-has-value',
    wrapper(context){
        let cls = ['formulate-input-wrapper'];
        if(context.attrs?.otherClasses){
          cls.push(` ${context.attrs?.otherClasses}`)
        }
        if ((context.type == 'checkbox' || context.type == 'radio') && !context.options) {
            cls.push(`flex flex-row cursor-pointer`);
        }else{
          //cls.push(`mt-4`);
        }

        return cls
    },
    outer(context) {
    //   if (useDebug) console.log(context);
      // top level form field
      let cls = [ `context_cls_{context.classification}`] ;
      if(context.classification == 'box'){
        cls = ["mb-2"];
      }else if( context.classification == 'file' ){
        cls = ["formulate-input"];
      }else if(context.type == 'simple-group'){
        cls = ["mt-4"];
      }else{
        cls = ["mb-4"];
      }
      
      if(context.attrs?.otherClasses){
        //console.log('otherClasses',context.attrs?.otherClasses)
        cls.push(`${context.attrs?.otherClasses}`)
      }else{
        cls.push("col-span-2")
      }
      if (useDebug && !window.startSurveyAnimation) (cls.push('fadeIn'));
      if (useDebug && window.startSurveyAnimation) (cls.push('fadeOut'));
      if (context.id) {
        cls.push(`id_${context.id}`);
      }
      if (context.name) {
        cls.push(`name_${context.name}`);
        if(context.classification =='group'){
          cls.push(`group_${context.name}`);
        }
      }

      
      if (context.attrs && context.attrs.wrapper_hidden) {
        cls.push(`hidden`);
      }
      return cls.join(' ');
    },
    element(context) {      
      if (context.type == "radio" || context.type == "checkbox" ) {
        return 'ttag ttagi'+tagi+" flex flex-wrap max-w-min" +  ( context.attrs.groupCount ? ` gc-${context.attrs.groupCount}` : '' );
      }
    },
    input(context) {
        if (context.type == "radio" ) {
            if (context.classification == 'group') {
                return "border rounded px-3 py-3 border-box flex-grow w-full sm:w-full md:w-1/4 md:mr-2 inline-flex";
            } else {
                return "nocolor-radio focus:shadow-none cursor-pointer";
            }
        }
        if (context.type == "checkbox") {
            if (context.classification == 'group') {
                return "border  rounded px-3 py-3 border-box flex-grow w-full sm:w-full md:w-1/4 md:mr-2 inline-flex";
            } else if (context.isGrouped) {
                return "form-checkbox nocolor-checkbox focus:shadow-none";
            } else {
                return "form-checkbox color-checkbox";
            }
        }

        switch (context.classification) {
            case "button":
                if (context.type == "submit") {
                    return "rounded-full py-3 px-16 bg-yellow-500 text-white";
                }
                return "px-4 py-2 rounded bg-blue-500 text-white hover:bg-blue-600 disabled:opacity-50";
            case "textarea":
                return "border border-gray-500 rounded px-3 py-2 leading-none focus:border-green-500 outline-none border-box w-full mb-1 h-16";
            default:
                return "border border-gray-500 rounded px-3 py-2 leading-none focus:border-green-500 outline-none border-box w-full mb-1";
        }
    },
    label(context) {
      //if (useDebug) console.log('label-context-'+context.name,{context})
     // if (useDebug) console.log('tagi'+ ++tagi,context)
        let classesToAdd = (context.labelPosition == 'before') ? "block _x_break-all " : "_x_break-all cursor-pointer pl-2 mr-auto ";

        if(context.type=='radio' ){
            if (context.attrs.hasValue) {
                classesToAdd += 'tagi'+tagi+' font-medium text-base' + (context.attrs.disabled ? " text-gray-300 md:mr-2" : "");
            } else {
                classesToAdd += 'tagi'+tagi+" font-medium text-sm md:text-base" + (context.attrs.disabled ? " text-gray-300 md:mr-2" : "");
            }
        } else {
            classesToAdd += 'tagi'+tagi+" font-medium text-base" + (context.attrs.disabled ? " text-gray-300 md:mr-2" : "")
        }
        return classesToAdd;
    },
    help() {
        let cls = ["text-sm mb-1 text-gray-600 whitespace-pre-line md:mr-2"];
        // if (context.hasValue && !context.isValid) cls.push('hidden');
        return cls;
    },
    error: "text-red-700 text-sm mb-1",
  },
  slotComponents: {
    label: "CustomLabel",
    help: "CustomHelp",
  },
  slotProps: {
      label: ['tooltip','isQuestionTop','remark','addStar', 'isAddOpinion', 'getOpinion'],
      help: ['deselectFunc'],
      addMore: ['setCaptchaKey', 'slug', 'setCodeKey', 'setCodeValue', 'dateType', 'datePlaceholder', 'ticketList', 'sessionList']
  }

});


if(LanguageSelection){ ()=>{}; }
if(Main){ ()=>{}; }

Vue.use(VueRouter)
const routes = [
  { path: "/", redirect: () => {
    let { formKey } = getFormQueryString();
    return { path: formKey ? '/main' : '/language' }
  } },
  { path: "/main", component: Main },
  { path: "/language", component: LanguageSelection },
  { path: "/list", component: FormSelection },
  { path: "/language/:eventId", component: LanguageSelection },
  { path: "/list/:eventId", component: FormSelection },
  { path: "/end", component: End },
  { path: "/done/:uuid", component: Thankyou },
  { path: "/attendee/:uuid", component: Attendee },
  { path: "/error/:type", component: ErrorDialog },
  { path: "/error", component: ErrorDialog },
  { path: "/display/:key", component: StopDialog },
  { path: "/enter-invite-code", component: EnterInviteCode },
];
// 3. Create the router instance and pass the `routes` option
// You can pass in additional options here, but let's
// keep it simple for now.
const router = new VueRouter({
  routes // short for `routes: routes`
})


if(process.env.VUE_APP_GTAG_ID){
  const VueGtag =  require(`vue-gtag`)
  Vue.use(VueGtag, {
    config: { id: process.env.VUE_APP_GTAG_ID },
    appName : 'rsvp',
    pageTrackerTemplate(to) {
      const r= {
        page_title: document.title,
        page_path: to.path,
        page_params: to.params ? new URLSearchParams(to.params).toString() : undefined
      }
      console.log({r,to})
      return r;
    }
  },router);

  Vue.config.errorHandler = function (err, vm, info) {
    console.log({err, vm, info})
    // handle error
    // `info` is a Vue-specific error info, e.g. which lifecycle hook
    // the error was found in. Only available in 2.2.0+
    if(window.gtag) window.gtag('event', 'exception', {
      'description':err
    });
  }
}

import JsonViewer from 'vue-json-viewer';
// import VueSocialSharing from 'vue-social-sharing';

// Import JsonViewer as a Vue.js plugin
Vue.use(JsonViewer);
// Vue.use(VueSocialSharing);


Vue.config.productionTip = false
window.$store = store;
window.$vue = $vue = new Vue({
  store,
  router,
  i18n,
  render: (h) => h(App),
})
$vue.$mount("#app");