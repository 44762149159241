<template>
    <div :class="`flex flex-col justify-start items-start pb-4 ${context.attributes.otherClasses}`">
        <div class="flex flex-row justify-between items-start w-full">
            <div
                v-if="context.slotProps.label.isAddOpinion"
                class="flex flex-row justify-start items-center mt-2">
                <span>{{$t('其他意見/建議')}}：</span>
                <input
                    type="checkbox"
                    v-model="showAddOpinionSlot"
                    class="form-checkbox color-checkbox"
                    @input="opinionCheckboxInput"
                    >
            </div>
            <div
                v-if="showDeselect"
                class="flex justify-end cursor-pointer text-red-700 text-sm md:mr-2 ml-auto"
                v-on:click="context.slotProps.help.deselectFunc(`${context.type}_${context.id}`)" >
                [{{this.tempTextI18N[this.$i18n.locale].deselect}}]
            </div>
        </div>
        <div v-if="showAddOpinionSlot" class="w-full mt-2">
            <FormulateInput
                v-model="opinionText"
                :name="`${context.name}_opinion`"
                type="textarea"
                maxlength="1000"
                wrapper-class="w-full"
                group-class="w-full"
                outer-class="w-full"
            /><span>{{opinionText.length}} / 1000</span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'customhelp',
    props: ['context'],
    mounted() {
        let opinion = this.context?.slotProps?.label?.getOpinion && this.context.slotProps.label.getOpinion(this.context.name);
        if (opinion) {
            this.opinionText = opinion;
            this.showAddOpinionSlot = true;
        }
        let reqFound = false;
        this.context.rules.forEach((rule) => { if (rule.ruleName == 'required') reqFound = true });
        if (!reqFound && ((this.context.type == 'radio') || (this.context.type == 'customdate'))) this.showDeselect = true;
    },
    methods: {
        opinionCheckboxInput() {
            if (!this.showAddOpinionSlot) this.opinionText = "";
        }
    },
    data() {
        return {
            showDeselect: false,
            showAddOpinionSlot: false,
            opinionText: "",
            tempTextI18N: {
                'en': {
                    deselect: 'Deselect'
                },
                'pt': {
                    deselect: 'Desmarcar'
                },
                'zh-hans': {
                    deselect: '取消选择'
                },
                'zh-hant': {
                    deselect: '取消選擇'
                }
            }
        }
    }
}
</script>

<style scoped>

</style>