<template>
    <section :data-update="forceUpate">
      <div class=" px-4  mx-auto  max-w-6xl sm:px-6   ">
       <div class="shadow-xl bg-white text-left p-6  pb-12 md:pb-20 ">
         <div class="container mx-auto px-4">
        <section class="py-8 px-4 text-center">
            <div class="max-w-auto mx-auto">
                <div class="md:max-w-lg mx-auto">
                      <SaWarning class="mx-auto"/>
                </div>
                <!-- <h2 class="mt-8 uppercase text-xl lg:text-5xl font-black">We are sorry, Page not found!</h2> -->
                <p class="mt-6 uppercase text-sm lg:text-base text-gray-900">{{ $t(msg) }}{{ route_error_extra  }}</p>
                
                <router-link to="/list" class="mt-6 bg-blue-300 hover:bg-blue-700 text-white font-light py-4 px-6 rounded-full inline-block uppercase shadow-md">返回</router-link>
            </div>
        </section>
    </div>
       </div>
      </div>
    </section>
</template>
 
<script>

import SaWarning from '../components/icon/SaWarning'

const useDebug =
  window.location.href.indexOf("127.0.0.1") >= 0 ||
  window.location.href.indexOf("localhost") >= 0;

 export default {
  components: {
    SaWarning
  },
  methods:{
  },
  async mounted(){
    if (useDebug) console.log(this.$route)
  },
  data(){
    return {
      forceUpate:0,
    }
  },
  computed:{
    msg(){
      return window.route_error ? window.route_error : this.$route.params.type
    },
    route_error_extra(){
      return window.route_error_extra ? window.route_error_extra : ''
    }
  }
};
</script>
