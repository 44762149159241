/* eslint-disable no-unused-vars */
/**
 * Here we can import additional helper functions to assist in formatting our
 * language. Feel free to add additional helper methods to libs/formats if it
 * assists in creating good validation messages for your locale.
 */

 /**
  * This is the ISO 639-1 and (optionally) ISO 639-2 language "tag".
  * Some valid examples:
  * zh
  * zh-CN
  * zh-HK
  * en
  * en-GB
  */
 function s(item) {
    if (typeof item === 'string' && item.length) {
        return item[0].toUpperCase() + item.substr(1)
    }
    return item
  }
 const locale = 'pt'
 
 /**
  * This is an object of functions that each produce valid responses. There's no
  * need for these to be 1-1 with english, feel free to change the wording or
  * use/not use any of the variables available in the object or the
  * arguments for the message to make the most sense in your language and culture.
  *
  * The validation context object includes the following properties:
  * {
  *   args        // Array of rule arguments: between:5,10 (args are ['5', '10'])
  *   name:       // The validation name to be used
  *   value:      // The value of the field (do not mutate!),
  *   vm: the     // FormulateInput instance this belongs to,
  *   formValues: // If wrapped in a FormulateForm, the value of other form fields.
  * }
  */
 const localizedValidationMessages = {
 
   /**
    * Valid accepted value.
    */
   accepted: function ({ name }) {
     return `Por favor aceite o ${name}.`
   },
 
   /**
    * The date is not after.
    */
   after: function ({ name, args }) {
     if (Array.isArray(args) && args.length) {
       return `${s(name)} deve ser posterior a ${args[0]}.`
     }
     return `${s(name)} deve ser uma data posterior.`
   },
 
   /**
    * The value is not a letter.
    */
   alpha: function ({ name }) {
     return `${s(name)} pode conter apenas letras.`
   },
 
   /**
    * Rule: checks if the value is alpha numeric
    */
   alphanumeric: function ({ name }) {
     return `${s(name)} pode conter apenas letras e números.`
   },
 
   /**
    * The date is not before.
    */
   before: function ({ name, args }) {
     if (Array.isArray(args) && args.length) {
       return `${s(name)} deve ser antes de ${args[0]}.`
     }
     return `${s(name)} deve ser uma data anterior.`
   },
 
   /**
    * The value is not between two numbers or lengths
    */
   between: function ({ name, value, args }) {
     const force = Array.isArray(args) && args[2] ? args[2] : false
     if ((!isNaN(value) && force !== 'length') || force === 'value') {
       return `${s(name)} deve ser entre ${args[0]} e ${args[1]}.`
     }
     return `${s(name)} deve ter entre ${args[0]} e ${args[1]} caracteres.`
   },
 
   /**
    * The confirmation field does not match
    */
   confirm: function ({ name, args }) {
     return `${s(name)} não corresponde.`
   },
 
   /**
    * Is not a valid date.
    */
   date: function ({ name, args }) {
     if (Array.isArray(args) && args.length) {
       return `${s(name)} não é válido, por favor use o formato ${args[0]}`
     }
     return `${s(name)} não é uma data válida.`
   },
 
   /**
    * The default render method for error messages.
    */
   default: function ({ name }) {
     return `Este campo não é válido.`
   },
 
   /**
    * Is not a valid email address.
    */
   email: function ({ name, value }) {
     if (!value) {
       return 'Por favor informe um e-mail válido.'
     }
     return `“${value}” não é um e-mail válido.`
   },
 
   /**
    * Ends with specified value
    */
   endsWith: function ({ name, value }) {
     if (!value) {
       return `Este campo não termina com um valor válido.`
     }
     return `“${value}” não termina com um valor válido.`
   },
 
   /**
    * Value is an allowed value.
    */
   in: function ({ name, value }) {
     if (typeof value === 'string' && value) {
       return `“${s(value)}” não é um ${name} permitido.`
     }
     return `Isso não é um ${name} permitido.`
   },
 
   /**
    * Value is not a match.
    */
   matches: function ({ name }) {
     return `${s(name)} não é um valor válido.`
   },
 
   /**
    * The maximum value allowed.
    */
   max: function ({ name, value, args }) {
     if (Array.isArray(value)) {
       return `Você deve selecionar apenas ${args[0]} ${name}.`
     }
     const force = Array.isArray(args) && args[1] ? args[1] : false
     if ((!isNaN(value) && force !== 'length') || force === 'value') {
       return `${s(name)} deve ser menor ou igual a ${args[0]}.`
     }
     return `${s(name)} deve ter no máximo ${args[0]} caracteres.`
   },
 
   /**
    * The (field-level) error message for mime errors.
    */
   mime: function ({ name, args }) {
     return `${s(name)} deve ser no formato: ${args[0] || 'Formato de arquivo não permitido.'}`
   },
 
   /**
    * The maximum value allowed.
    */
   min: function ({ name, value, args }) {
     if (Array.isArray(value)) {
       return `Você deve selecionar pelo menos ${args[0]} ${name}.`
     }
     const force = Array.isArray(args) && args[1] ? args[1] : false
     if ((!isNaN(value) && force !== 'length') || force === 'value') {
       return `${s(name)} deve ser maior que ${args[0]}.`
     }
     return `${s(name)} deve ter mais de ${args[0]} caracteres.`
   },
 
   /**
    * The field is not an allowed value
    */
   not: function ({ name, value }) {
     return `“${value}” não é um ${name} válido.`
   },
 
   /**
    * The field is not a number
    */
   number: function ({ name }) {
     return `${s(name)} deve ser um número.`
   },
 
   /**
    * Required field.
    */
   required: function ({ name }) {
     return `${s(name)} é obrigatório.`
   },
 
   /**
    * Starts with specified value
    */
   startsWith: function ({ name, value }) {
     if (!value) {
       return `Este campo não começa com um valor válido.`
     }
     return `“${value}” não começa com um valor válido.`
   },
 
   /**
    * Value is not a url.
    */
   url: function ({ name }) {
     return `Por favor informe uma URL válida.`
   }

 }

import { customValidToStandardValid, addCustomMessages } from "./standardizeValidationMessages";
customValidToStandardValid(localizedValidationMessages);
addCustomMessages(localizedValidationMessages, locale);
 
 /**
  * This creates a vue-formulate plugin that can be imported and used on each
  * project.
  */
 export default function (instance) {
   instance.extend({
     locales: {
       [locale]: localizedValidationMessages
     }
   })
 }