<template>
    <div
        :class="`formulate-input-element formulate-input-element w-full flex flex-col justify-start align-middle ${context.otherClasses}`"
        :data-type="context.type" >
        <FormulateInput
            v-model="context.model"
            :name="context.name"
            :options="getRadioList()"
            type="radio"
            wrapper-class="w-full"
            group-class="w-full"
            outer-class="w-full"
        />
    </div>
</template>

<script>
import { LOCALE_TO_LANG } from '../../libs/i18n';

export default {
    props: {
        context: {
            type: Object,
            required: true
        },
    },
    async mounted() {
        console.log("Loading Session:", this.context);
        if (this.context?.slotProps?.addMore?.sessionList) this.sessionList = this.context.slotProps.addMore.sessionList;
    },
    methods: {
        getRadioList() {
            let sessionList = this.sessionList;
            if (sessionList.length == 0) return [];

            return sessionList.reduce((a, b) => {
                let session = b.sessionTranslations.find(x => this.$i18n.locale == LOCALE_TO_LANG[x.locale]); 
                if (session) {
                    const stitle = session.title;
                    let lines = []
                    if (stitle) lines.push(stitle);
                    const stime = session.eventTimePeriod
                    if (stime)
                        lines.push(`${this.$t("日期及時間")}: ${stime}`);
                    const add = session.address
                    if (add) 
                        lines.push(`${this.$t("地點")}: ${add}`);
                    const description = session.description
                    if (description)
                         lines.push("" + description);
                    let label = lines
                    .map(
                    (l, i) =>
                        i==0 ? l : `<div class="${
                        i == 0 ? "text-current" : "text-gray-600"
                        }">${l}</div>`
                    )
                    .join("");  
                    a[b.id]  = label
                }else{
                     a[b.id] = `[${b.id}]`;
                }
                return a;
            }, {});
        },

        async customBlurHandler() {
        },

    },
    data () {
        return {
            sessionList: []
        }
    }
}

</script>

<style scoped>
</style>
