<template>
    <div
        :class="`formulate-input-element formulate-input-element w-full flex flex-col justify-start`"
        :data-type="context.type" >
        <div class="w-full border border-gray-500 rounded leading-none border-box mb-1 flex justify-between items-center">
            <flat-pickr
                class="px-3 py-2 cursor-pointer w-full"
                :placeholder="context.slotProps.addMore.datePlaceholder ? context.slotProps.addMore.datePlaceholder : 
                                ((context.slotProps.addMore.dateType == 'datetime-local') ? 'dd/mm/yyyy, --:-- --' : 'dd/mm/yyyy')"
                v-model="context.model"
                :config="{...config, enableTime: (this.context.slotProps.addMore.dateType == 'datetime-local'),
                        enableSeconds: (this.context.slotProps.addMore.dateType == 'datetime-local')}" />
                <span class="far fa-calendar px-3 relative right-0"></span>
        </div>
    </div>
</template>

<script>
import BaseMixin from "../BaseMixin";
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import { MandarinTraditional } from "flatpickr/dist/l10n/zh-tw.js"
import { Mandarin } from "flatpickr/dist/l10n/zh.js"
import { Portuguese } from "flatpickr/dist/l10n/pt.js"

// const useDebug =
//   window.location.href.indexOf("127.0.0.1") >= 0 ||
//   window.location.href.indexOf("localhost") >= 0;

export default {
    mixins: [BaseMixin],
    components: { flatPickr },
    props: {
        context: {
            type: Object,
            required: true
        }
    },

    async mounted() {
        this.config = {
            altInput: true,
            locale: (this.$i18n.locale == 'zh-hant' ? MandarinTraditional :
                    this.$i18n.locale == 'zh-hans' ? Mandarin :
                    this.$i18n.locale == 'pt' ? Portuguese : null)
        };
        this.customAttributes = this.context.attributes;
        this.customAttributes.class = null;
    },

    computed: {
    },
    
    methods: {
        removeDate() {
            this.context.model = null;
        }
    },
    data () {
        return {
            customAttributes: {},
            config: {}
        }
    }
}

</script>

<style scoped>

</style>
