
import Vuex from 'vuex';
import Vue from 'vue';
Vue.use(Vuex);

const useDebug =
  window.location.href.indexOf("127.0.0.1") >= 0 ||
  window.location.href.indexOf("localhost") >= 0;
import createLogger from 'vuex/dist/logger';
import { updateModel,mapModel } from 'vuex-bound';

import VuexPersistence from 'vuex-persist'

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  modules:['pref'],
  filter: () => {
    // console.log('modulePref', {mutation})
    return true
  }
})

const pluginList = [ vuexLocal.plugin ];
if (useDebug) pluginList.push(createLogger({ collapsed: false }));

const modulePref = {
  state: {
    i18n: {}
  },
  getters: {},
  mutations: { ...updateModel() },
  namespaced: true,
  actions: {}
}

const moduleState = {
  state: {
    formLangs:[],
  },
  getters: {},
  mutations: { ...updateModel() },
  namespaced: true,
  actions: {}
}

const store = new Vuex.Store({
  modules: {
    pref: modulePref,
    trans: moduleState
  },
  plugins: pluginList,
});

export function mapModalComputed(){
  return {
        ...mapModel('pref',['i18n']),
        ...mapModel('trans',['formLangs']),
    }
}

export default store;
