<template>
    <div class='mb-20'>
        <div class="text-xl pb-1 border-b-2 border-black w-1/2">{{ tempTextI18N[$i18n.locale].displayAllTitle }}</div>
        <div
            class="py-2 text-lg"
            v-if="isAllEmpty(surveyValues)">{{ tempTextI18N[$i18n.locale].emptyAnswers }}</div>
        <div
            v-else
            v-for="(schemaPage, pageIdx) in surveySchema" :key="pageIdx">
            <div v-for="(question, questionIdx) in surveySchema[pageIdx]" :key="`${pageIdx}_${questionIdx}`"
                class="flex flex-col">
                <div class='p-1' v-if="shouldRender(question, surveyValues, pageIdx)">
                    <p class="font-medium text-base">{{question.validationName}}</p>
                    <div v-if="((question.type == 'checkbox') && surveyValues[pageIdx][`${question.type}_${question.id}`])"
                        class="border border-gray-500 rounded px-3 py-1" >
                            <div v-if="surveyValues[pageIdx][`${question.type}_${question.id}`].length == 0">{{ this.tempTextI18N[this.$i18n.locale].empty }}</div>
                            <div v-else v-for="(surveyValue) in surveyValues[pageIdx][`${question.type}_${question.id}`]" :key="`${pageIdx}_${questionIdx}_${surveyValue}`">
                                <span class="mr-2">&#8226;</span> {{ getCorrespondingLabel(surveyValue, question, useOptionsV2) }}
                            </div>
                    </div>
                    <div v-else-if="(question.type == 'radio') && surveyValues[pageIdx][`${question.type}_${question.id}`]"
                        class="border border-gray-500 rounded px-3 py-1" >
                            <span class="mr-2">&#8226;</span> {{ getCorrespondingLabel(surveyValues[pageIdx][`${question.type}_${question.id}`], question, useOptionsV2) }}
                    </div>
                    <div v-else-if="(question.type == 'select') && surveyValues[pageIdx][`${question.type}_${question.id}`]"
                        class="border border-gray-500 rounded px-3 py-1" >
                            {{ getCorrespondingLabel(surveyValues[pageIdx][`${question.type}_${question.id}`], question, useOptionsV2) }}
                    </div>
                    <div v-else-if="surveyValues[pageIdx][`${question.type}_${question.id}`]" class="border border-gray-500 rounded px-3 py-1 mt-1 _x_break-all">
                        {{getSurveyValue(question, surveyValues, pageIdx)}}
                    </div>
                    <div v-if="surveyValues[pageIdx][`${question.type}_${question.id}_opinion`]" class="pl-2 mt-2">
                        <div>{{$t("意見")}}</div>
                        <div class="border border-gray-500 rounded px-3 py-1 mt-1">
                            {{ surveyValues[pageIdx][`${question.type}_${question.id}_opinion`] }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import BaseMixin from "../BaseMixin";

export default {
    name: 'DisplayAllValues',
    props: ['surveySchema', 'surveyValues', 'useOptionsV2', 'contactVerifyType', 'contactConfig'],
    mixins: [BaseMixin],
    methods: {
        isAllEmpty(surveyValues) {
            if (surveyValues == null) return true;
            let hasValues = false;
            surveyValues.forEach(page => {
                Object.keys(page).forEach(id => {
                    if (page[id] != "") hasValues = true;
                });
            });

            return !hasValues;
        },
        shouldRender(question, surveyValues, pageIdx) {
            let currSurveyValuePage = surveyValues[pageIdx];
            if (Object.keys(currSurveyValuePage).includes(`${question.type}_${question.id}`) ||
                Object.keys(currSurveyValuePage).includes(`${question.type}_${question.id}_opinion`)) {
                if (!currSurveyValuePage[`${question.type}_${question.id}`]
                    && !currSurveyValuePage[`${question.type}_${question.id}_opinion`]) return false;
                return true;
            } else {
                return false;
                // return !question.defaultHidden;
            }
        },
        getCorrespondingLabel(surveyValue, question, useOptionsV2) {
            if (!useOptionsV2) return surveyValue;
            let label = question.options.filter(x=>x.id==surveyValue).pop();
            return label?.label;
        },
        getSurveyValue(question, surveyValues, pageIdx) {
            let currSurveyValuePage = surveyValues[pageIdx]
            if (Object.keys(currSurveyValuePage).includes(`${question.type}_${question.id}`)) {
                if (!currSurveyValuePage[`${question.type}_${question.id}`]) return "";
                return currSurveyValuePage[`${question.type}_${question.id}`].toString();
            }
            return this.tempTextI18N[this.$i18n.locale].empty;
        }
    },
    data() {
        return {
            tempTextI18N: {
                'en': {
                    displayAllTitle: 'Your answers',
                    empty: 'Empty',
                    emptyAnswers: 'No questions answered'
                },
                'pt': {
                    displayAllTitle: 'Suas respostas',
                    empty: 'em branco',
                    emptyAnswers: 'Nenhuma pergunta respondida'
                },
                'zh-hans': {
                    displayAllTitle: '你的答案',
                    empty: '空白',
                    emptyAnswers: '没有回答的问题'
                },
                'zh-hant': {
                    displayAllTitle: '你的答案',
                    empty: '空白',
                    emptyAnswers: '沒有回答的問題'
                }
            },
        }
    }
}
</script>

<style scoped>

</style>