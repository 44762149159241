<template>
  <div class="shadow-xl bg-white">
      <div v-if="eventBanner || banner">
          <img class='w-full h-full' :src=" eventBanner ? eventBanner : banner" />
      </div>
    <div
      v-if="false"
      class="relative bg-main-blue rounded py-5 px-8 md:py-5 md:px-5 shadow-2xl overflow-hidden"
    
    >
      <div
        class="absolute right-0 bottom-0 pointer-events-none hidden lg:block"
        aria-hidden="true"
      >
        
      </div>
      <div
        class="relative flex flex-col  justify-between items-center"
      >
        <div class="text-center">
            <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import {
  getEventDetail,
  getBannerUrl
} from "../api/fetchEvent";

import {
  tranlationsToi18nDict
} from "../utils/helper";

export default {
  props:{
    banner: {
      type:[String,Boolean],
      default: ''
    },
    eventId:{
      type:[Number,Boolean],
      default: false
    }
  }, 
  data(){
    return {
      'eventBanner'  : false
    }
  },
  async mounted() {
    if(this.eventId){  
      const event = await getEventDetail(this.eventId)
      const trans = tranlationsToi18nDict(event.eventTranslations);
      if(trans[this.$i18n.locale]?.banner){
        this.eventBanner = getBannerUrl(trans[this.$i18n.locale].banner);
      }
    }
  }
}
</script>
