<template>
    <section>
       
    </section>
</template>
<style>

</style>
<script>

export default {
    components: {
    },
    data() {
        return {

        }
    },
    methods: {
        async getEvent() {
        }
    },
    async mounted() {
            document.getElementById('app').style.display = 'none';


    },
    created() { },
};
</script>
