<template>
    <div
        :class="`formulate-input-element formulate-input-element w-full flex flex-col justify-start`"
        :data-type="context.type" >
        <!-- <div v-html="tempTextI18N[$i18n.locale].instructions($t((context.slotProps.addMore.slug == 'phoneNumber')?'電話':'電郵'))" /> -->
        <div class="w-full border border-gray-500 rounded leading-none border-box mb-1 flex">
            <div
                v-if="context.slotProps.addMore.slug == 'phoneNumber'"
                class="bg-gray-400 h-full px-3 py-2 border border-gray-600 rounded-l">+853</div>
            <input 
                :type="(context.slotProps.addMore.slug == 'phoneNumber') ? 'number' : 'text'"
                class="rounded px-3 py-2 leading-none focus:border-green-500 outline-none w-full"
                v-model="context.model"
                v-bind="customAttributes"
                :maxlength="(context.slotProps.addMore.slug == 'phoneNumber') ? 8 : 10000"
                @keydown.enter.prevent="context.model = selection.label"
                @blur="context.blurHandler">
        </div>
        <div class="w-full flex flex-row justify-start items-start mt-2 -mb-4">
            <FormulateInput
                :name="$t('驗證碼')"
                v-model="verifyCode"
                @input="setCodeValue"
                class="w-1/2 mb-0"
                :placeholder="$t(`label.${context.slotProps.addMore.slug == 'phoneNumber' ? 'mobile' : 'email'}-verification-code`)"
                maxlength="6"
                :validation="`${(context.rules.filter( x => x.ruleName =='required' ).length > 0) ? 'required|' : ''}verifyContactCode:${verifyKey}`"
                :validation-messages="{ verifyContactCode: verifyCodeMesssage }"
                error-behavior="submit" />
            <button
                type="button"
                @click="getCode" 
                :disabled="isReadyToRecieveCode() == false"
                :class="`ml-2 px-4 input_padding_height rounded text-white
                ${isReadyToRecieveCode() ? 'bg-blue-500 hover:bg-blue-600' : 'opacity-50 bg-gray-600 cursor-not-allowed'}`">
                {{ (this.codeCountdown > 0) ? this.codeCountdown : $t("label.receive-verification-code") }}</button>
        </div>
    </div>
</template>

<script>
import {
    sendMobileVerification,
    sendEmailVerification,
} from '../../api/fetchEvent';
import BaseMixin from "../BaseMixin";

const useDebug =
  window.location.href.indexOf("127.0.0.1") >= 0 ||
  window.location.href.indexOf("localhost") >= 0;

export default {
    mixins: [BaseMixin],
    props: {
        context: {
            type: Object,
            required: true
        }
    },

    async mounted() {
        this.customAttributes = this.context.attributes;
        this.customAttributes.class = null;
        if(this.context.attributes?.codeValue){
            this.verifyCode = this.context.attributes.codeValue
        }
        //console.log('verifyinput',this.context)
        //console.log('hello: ', this.context,this.context.validation)
    },

    computed: {

        verifyCodeMesssage() {
            return (window.verifyCodeMessage ? this.$t(window.verifyCodeMessage) : this.$t('驗證碼不正確'));
        },

    },
    
    methods: {
        isReadyToRecieveCode() {
            if (this.context.slotProps.addMore.slug == 'phoneNumber') return ((this.codeCountdown == 0) && (this.context.isValid) && (this.context.model.length == 8));
            if (this.context.slotProps.addMore.slug == 'email') return ((this.codeCountdown == 0) && (this.context.isValid) && (this.context.model.length > 0));
            return false;
        },
        async getCode() {
            this.codeCountdown = useDebug ? 5 : 60;
            let countdownInterval = setInterval(() => {
                this.codeCountdown -= 1;
                if (this.codeCountdown == 0) clearInterval(countdownInterval);
            }, 1000)
            let resp = (this.context.slotProps.addMore.slug == 'phoneNumber' ? await sendMobileVerification(
                this.context.model, this.$i18n.locale
            ) : (this.context.slotProps.addMore.slug == 'email' ? await sendEmailVerification(
                'Email Verification', this.context.model, this.$i18n.locale
            ) : {id: null}));

            this.verifyKey = resp.id;
            console.log('this.verifyKey ', this.verifyKey )
            window.lastVerifyKey = this.verifyKey
        },
        setCodeValue() {
            
            this.context.slotProps.addMore.setCodeValue(this.verifyCode);
            this.context.slotProps.addMore.setCodeKey(this.verifyKey);
        }
    },
    data () {
        return {
            customAttributes: {},
            codeCountdown: 0,
            verifyKey: "",
            verifyCode: "",
            requiredInput: false,
            tempTextI18N: {
                'en': {
                    instructions: (type) => {return `<div>1. 輸入填寫者的 "${type}"</div><div>2. 按 "${this.$t("label.receive-verification-code")}"</div><div>3. 輸入${type}收到的驗證碼</div>`},
                },
                'pt': {
                    instructions: (type) => {return `<div>1. entrar "${type}"</div><div>2. de acordo com "${this.$t("label.receive-verification-code")}"</div><div>3. entrar ${type} código de verificação recebido</div>`},
                },
                'zh-hans': {
                    instructions: (type) => {return `<div>1. 输入填写者的 "${type}"</div><div>2. 按 "${this.$t("label.receive-verification-code")}"</div><div>3. 输入${type}收到的验证码</div>`},
                },
                'zh-hant': {
                    instructions: (type) => {return `<div>1. 輸入填寫者的 "${type}"</div><div>2. 按 "${this.$t("label.receive-verification-code")}"</div><div>3. 輸入${type}收到的驗證碼</div>`},
                }
            },
        }
    }
}

</script>

<style scoped>

.input_padding_height{
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
}

</style>
