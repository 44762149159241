<template>
    <div
        :class="`formulate-input-element formulate-input-element w-full flex flex-col justify-start align-middle`"
        :data-type="context.type" >
        <FormulateInput
            v-model="context.model"
            :name="context.name"
            :options="getRadioList()"
            type="radio"
            @input="handleTicketChange"
            wrapper-class="w-full"
            group-class="w-full"
            outer-class="w-full"
        />
        <FormulateInput 
            v-if="false && maxTicketCount != 1"
            v-model="ticketCount"
            type="select"
            :options="Array.from({length: Math.min(maxTicketCount, remainingTicketCount)}, (x, i) => i+1)"
            name="customticket_ticketcount"
            @input="handleTicketChange"
            :label="$t('門票數量')"
            :validation="`required|number|between:0,${maxTicketCount+1},value`"
            min="0"
            :addStar="true"
            :max="remainingTicketCount+1"
            error-behavior="live"
        />
    </div>
</template>

<script>
import { LOCALE_TO_LANG } from '../../libs/i18n';

export default {
    props: {
        context: {
            type: Object,
            required: true
        },
    },
    async mounted() {
        console.log("Loading Ticket:",this.context);
        if (this.context?.slotProps?.addMore?.ticketList) this.ticketList = this.context.slotProps.addMore.ticketList;
    },
    methods: {
        getRadioList() {
            let ticketList = this.ticketList;
            if (ticketList.length == 0) return [];

            return ticketList.reduce((a, b) => {
                let translation = b.ticketTranslations.find(x => this.$i18n.locale == LOCALE_TO_LANG[x.locale]);
                let item = {
                    label: translation ? `${translation.title} <span class='text-xs text-gray-600'>[${this.$t('剩余数量')}: ${b.quota - b.useQuota} ${this.$t('門票')}]</span>` : `[${b.id}]`,
                    value: b.id,
                    disabled: b.quota - b.useQuota <= 0
                }
                a.push(item);
                return a;
            }, []);
            // return ticketList;
        },

        handleTicketChange() {
            let currTicket = this.ticketList.find(x => x.id == this.context.model);
            if (!currTicket) return;

            this.maxTicketCount = currTicket.maxQuotaUnit;
            this.remainingTicketCount = currTicket.quota - currTicket.useQuota;
        },

        async customBlurHandler() {
        },

    },
    data () {
        return {
            ticketList: [],
            maxTicketCount: 1,
            remainingTicketCount: 999999999,
            ticketCount: 1
        }
    }
}

</script>

<style scoped>
</style>
