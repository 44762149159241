import CONFIG from "../config"
import {LOCALE_TO_LANG} from "../libs/i18n"
export function htmlToText(html){
  return html ? html
    .replace(/<[^>]+>/g, "")
    .replace(/^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g, "") : '';
}
 
export function handleStopError(errSection, err, paramCanBack){
  let errCode = err.code;
  let back = typeof paramCanBack=='undefined' ? true : paramCanBack;
  let errMsg = CONFIG.errors[errSection][errCode] ? CONFIG.errors[errSection][errCode] : false;
  if(!errCode || !errMsg){
    errMsg = err.message ? err.message : (err.name ? err.name : 'unknown error')
    errCode = 'unknown-error'
  }
  let dialog = { 
    icon: 'error',
    back,
    msg: errMsg
  }
  localStorage.setItem(errCode,JSON.stringify(dialog))
  return errCode;
}
 
export function getFormQueryString(){
    const urlParams = new URLSearchParams(window.location.search);
    const formKey = urlParams.get('formKey')
    const previewKey = urlParams.get("previewKey");
  const inviteCode = urlParams.get("inviteCode");
  const formLang = urlParams.get("lang");
    return { formKey, previewKey, inviteCode, formLang: formLang ?formLang : 'zh-hant' };
}
 
 
export function tranlationsToi18nDict(tranlations){
    let i18n = {}
    tranlations.map( t=>{
        const locale = LOCALE_TO_LANG[t.locale] ? LOCALE_TO_LANG[t.locale] : t.locale;
        i18n[locale] = Object.assign({},t);
        delete i18n[locale].locale;
    })
    //console.log("x", { i18n, tranlations });
    return i18n
}
 
import {ALL_LANGS} from '../libs/i18n'
 
function mergeToI18n(i18n,values,prefix){
    //console.log('mergeToI18n',{i18n,values,prefix})
    for(const l in values){
        let keys = Object.keys(values[l])
        for( const k in keys){
            const key = keys[k]
            i18n[l][`${prefix}${key}`] = values[l][key]
        } 
    }
}

export function messageTemplateToI18n(msgTemplateRaw){
  if(!msgTemplateRaw || !msgTemplateRaw.messageTemplateTranslations ) return false;
  
  let entries = msgTemplateRaw.messageTemplateTranslations.map((t) => {
    
    let json = t.content && t.content[0] == "{" ? JSON.parse(t.content) : false
    console.log({t,json})
    return !json ? [ LOCALE_TO_LANG[t.locale], t.content ] : [
      LOCALE_TO_LANG[t.locale],
      json,
    ];
  });
  return Object.fromEntries( entries.filter(one=>one) );

}

export function formI18n(form,event,tickets,sessions) {
  let i18n = {};
  for (const i in ALL_LANGS) {
    const l = ALL_LANGS[i];
    i18n[l] = {};
  }
  if (
    form &&
    form.applyFormTranslations
  ) {
    mergeToI18n(
      i18n,
      tranlationsToi18nDict(form.applyFormTranslations),
      "form."
    );
  }

  if (
    event && event.eventMessage &&
    event.eventMessage.eventMessageTranslations
  ) {
    mergeToI18n(
      i18n,
      tranlationsToi18nDict(
        event.eventMessage.eventMessageTranslations
      ),
      "event."
    );
  }

  if (
    event && event.eventMessage &&
    event.eventMessage.surveyMessageTranslations
  ) {
    mergeToI18n(
      i18n,
      tranlationsToi18nDict(
        event.eventMessage.surveyMessageTranslations
      ),
      "event.survey."
    );
  }

  if ( event && event.eventTranslations) {
    mergeToI18n(
      i18n,
      tranlationsToi18nDict(event.eventTranslations),
      "event.intro."
    );
  }
  if (tickets) {
    for (const ti in tickets) {
      const ticket = tickets[ti];
      // console.log({ticket});
      mergeToI18n(
        i18n,
        tranlationsToi18nDict(tickets[ti].ticketTranslations),
        `ticket.${ticket.id}.`
      );
    }
  }
  if (sessions) {
    for (const si in sessions) {
      const session = sessions[si];
      mergeToI18n(
        i18n,
        tranlationsToi18nDict(sessions[si].sessionTranslations),
        `session.${session.id}.`
      );
    }
  }
  return i18n;
}

export function applyFormi18n(applyForm) {
  return formI18n(applyForm.form, applyForm.form.event, applyForm.form.tickets, applyForm.form.sessions )
}

export function applyFormi18nOld(applyForm) {
  let i18n = {};
  for (const i in ALL_LANGS) {
    const l = ALL_LANGS[i];
    i18n[l] = {};
  }
  if (
    applyForm.form &&
    applyForm.form.applyFormTranslations
  ) {
    mergeToI18n(
      i18n,
      tranlationsToi18nDict(applyForm.form.applyFormTranslations),
      "form."
    );
  }
 
  if (
    applyForm.form.event.eventMessage &&
    applyForm.form.event.eventMessage.eventMessageTranslations
  ) {
    mergeToI18n(
      i18n,
      tranlationsToi18nDict(
        applyForm.form.event.eventMessage.eventMessageTranslations
      ),
      "event."
    );
  }
  if (applyForm.form.event.eventTranslations) {
    mergeToI18n(
      i18n,
      tranlationsToi18nDict(applyForm.form.event.eventTranslations),
      "event.intro."
    );
  }
  if (applyForm.form.tickets) {
    for (const ti in applyForm.form.tickets) {
      const ticket = applyForm.form.tickets[ti];
      mergeToI18n(
        i18n,
        tranlationsToi18nDict(applyForm.form.tickets[ti].ticketTranslations),
        `ticket.${ticket.id}.`
      );
    }
  }
  if (applyForm.form.sessions) {
    for (const si in applyForm.form.sessions) {
      const session = applyForm.form.sessions[si];
      mergeToI18n(
        i18n,
        tranlationsToi18nDict(applyForm.form.sessions[si].sessionTranslations),
        `session.${session.id}.`
      );
    }
  }
  return i18n;
}
 
export function  version() { return process.env.PACKAGE_VERSION }
 
export function submitI18n(data) {
  let i18n = {};
  for (const i in ALL_LANGS) {
    const l = ALL_LANGS[i];
    i18n[l] = {};
  }
  if (data.ticket) {    
    const ticket = data.ticket;
    mergeToI18n(
      i18n,
      tranlationsToi18nDict(ticket.ticketTranslations),
      `ticket.`
    );
  }
  if (data.session) {    
    const session = data.session;
    mergeToI18n(
      i18n,
      tranlationsToi18nDict(session.sessionTranslations),
      `session.`
    );
     
  }
  return i18n;
}

export function json(string_or_json){
  let json = {}
  if( typeof string_or_json == "string" ){
    try{
      json = JSON.parse(string_or_json)
      
    }catch(e){
      console.log('string_or_json parse fail', e)
    }
    return json
  }else if( typeof string_or_json == "object" ){
    return string_or_json
  }
}
 
export function eventDetaili18n(detail){
    let i18n = {}
    for(const i in ALL_LANGS){
        const l = ALL_LANGS[i]
        i18n[l] = {}
    }
    if(detail.eventMessage && detail.eventMessage.eventMessageTranslations){
        mergeToI18n(i18n,tranlationsToi18nDict(detail.eventMessage.eventMessageTranslations), 'event.')
    }
    if(detail.eventTranslations){
        mergeToI18n(i18n,tranlationsToi18nDict(detail.eventTranslations), 'event.intro.')
    }
    if(detail.tickets) {
        for (const ti in detail.tickets) {
            const ticket = detail.tickets[ti]
            mergeToI18n(i18n,tranlationsToi18nDict(detail.tickets[ti].ticketTranslations), `ticket.${ticket.id}.`)
        }
    }
    if(detail.sessions){
        for (const si in detail.sessions) {
            const session = detail.sessions[si]
            mergeToI18n(i18n,tranlationsToi18nDict(detail.sessions[si].sessionTranslations), `session.${session.id}.`)
        }
    }
    return i18n
}

export function attendeeNumberFormater(s){ //TODO:hard code
  const parts = s.split('_');
  //20220914_R3_0018_00027_SPK
  const sessionNumber = parts[1]
  const seq = String(1*parts[3]).padStart(3, '0') 
  return  `${sessionNumber}${seq}` ;
}