<template>
    <div v-if="showDebug && useDebug">
        <div class="text-lg text-red-400">[Warning] When opened will cause the survey to lag!</div>
        <div class="w-full flex flex-row justify-start items-center">
            <div
                class='w-1/4 mb-1 py-2 px-3 bg-red-600 text-center rounded-md cursor-pointer'
                @click="useTestData()">Use test data:
            </div>
            {{ formValues }}
        </div>
        <div
            class='w-1/4 py-2 px-3 bg-red-600 text-center rounded-md cursor-pointer'
            @click="forceFormSubmit()">force submit
        </div>
        <div class="font-bold">surveyStep: {{surveyStep}}</div>
        currentSurveySchema
        <json-viewer
            :value="currentSurveySchema"
            :expand-depth=5
            copyable
            boxed
            sort />

        currentSurveyValue
        <json-viewer
        :value="currentSurveyValue"
        :expand-depth=5
        copyable
        boxed
        sort />

        Values
        <json-viewer
        :value="values"
        :expand-depth=5
        copyable
        boxed
        sort />

        Record Form Schema
        <json-viewer
        :value="recordSchema"
        :expand-depth=5
        copyable
        boxed
        sort />

        Record Values
        <json-viewer
        :value="recordValues"
        :expand-depth=5
        copyable
        boxed
        sort />

        Dimension Form Schema
        <json-viewer
        :value="dimensionSchema"
        :expand-depth=5
        copyable
        boxed
        sort />

        Dimension Values
        <json-viewer
        :value="dimensionValues"
        :expand-depth=5
        copyable
        boxed
        sort />

        surveyFormErrors
        <json-viewer
        :value="surveyFormErrors"
        :expand-depth=5
        copyable
        boxed
        sort />

        surveyInputErrors
        <json-viewer
        :value="surveyInputErrors"
        :expand-depth=5
        copyable
        boxed
        sort />

        surveySchema
        <json-viewer
        :value="surveySchema"
        :expand-depth=5
        copyable
        boxed
        sort />

        surveyValues
        <json-viewer
        :value="JSON.parse(JSON.stringify(surveyValues))"
        :expand-depth=5
        copyable
        boxed
        sort />

        Share Text
        <json-viewer 
            :value="shareText"
            copyable
            boxed />

        i18n Object
        <json-viewer
        :value="this.$i18n.messages"
        :expand-depth=5
        copyable
        boxed
        sort />

        Device Information
        <json-viewer
        :value="deviceDetails"
        :expand-depth=5
        copyable
        boxed />
        All relation rules
        <json-viewer 
        :value="allRelationRules"
        :expand-depth=5
        copyable
        boxed
        sort />
    </div>
</template>

<script>
import BaseMixin from "../BaseMixin";

export default {
    name: 'DebugViewer',
    props: ["showDebug", "useDebug", "useTestData", "forceFormSubmit", "surveyStep",
            "currentSurveySchema", "surveyFormErrors", "surveyInputErrors", "surveySchema",
            "surveyValues", "deviceDetails", "allRelationRules", "formValues",
            "currentSurveyValue", "shareText", "recordSchema", "recordValues", "values",
            "dimensionSchema", "dimensionValues"],
    mixins: [BaseMixin]
}
</script>

<style>

</style>