export default {
    'radio': {
        default: {
            label: '默認',
            styles: {}
        },
        'one-row': {
            label: '所有答案佔用一條行',
            styles: {
                'element-class': 'one-row-container',
                'input-class': 'one-row-items '
            }
        },
        'one-column': {
            label: '每個答案佔用一條行',
            styles: {
                'element-class': 'one-column-container',
                'input-class': 'one-column-items '
            }
        },
        'one-row-responsive': {
            label: '所有答案佔用一條行，但多選時會到下一行',
            styles: {
                'element-class': 'one-row-4th-responsive-container',
                'input-class': 'one-row-4th-responsive-items '
            } 
        }
    },
    'checkbox': {
        default: {
            label: '默認',
            styles: {}
        },
        'one-row': {
            label: '所有答案佔用一條行',
            styles: {
                'element-class': 'one-row-container',
                'input-class': 'one-row-items '
            }
        },
        'one-column': {
            label: '每個答案佔用一條行',
            styles: {
                'element-class': 'one-column-container',
                'input-class': 'one-column-items '
            }
        },
        'one-row-responsive': { 
            label: '所有答案佔用一條行，但多選時會到下一行',
            styles: {
                'element-class': 'one-row-responsive-container',
                'input-class': 'one-row-responsive-items border-box'
            }
        }
    },
}