<template>
    <section>
      <div class=" px-4  mx-auto  max-w-6xl sm:px-6   ">
       <div class="shadow-xl bg-white text-left p-6  pb-12 md:pb-20 ">
         <div class="container mx-auto px-4">
            <section class="py-8 px-4 text-center">
                <div class="max-w-auto mx-auto">
                    <FormulateInput
                        type="text"
                        :placeholder="tempI18N[this.$i18n.locale]['請填寫']"
                        :label="tempI18N[this.$i18n.locale]['請填寫邀請碼']"
                        v-model="inviteCode" />
                
                    <div
                        class="text-red-700 text-sm lg:text-base py-5"
                        v-if="incorrect">{{tempI18N[this.$i18n.locale]['邀請碼不正確']}}</div>

                    <button @click="tryCode" class="bg-green-300 hover:bg-green-700
                        text-white font-light py-2 px-10 rounded-md inline-block uppercase shadow-md">
                        {{tempI18N[this.$i18n.locale]['label.submit']}}</button>
                </div>
            </section>
        </div>
       </div>
      </div>
    </section>
</template>
 
<script>
import { getFormQueryString } from "../utils/helper";
import { getApplyForm } from "../api/fetchEvent";

let { formKey, previewKey } = getFormQueryString();
import BaseMixin from "../components/BaseMixin";
import { mapModalComputed } from '../store';
const useDebug =
  window.location.href.indexOf("127.0.0.1") >= 0 ||
  window.location.href.indexOf("localhost") >= 0;

 export default {
    mixins: [BaseMixin],
    components: {
    },
    methods:{
        async tryCode() {
            try {
                this.applyForm = await getApplyForm(formKey, previewKey, this.inviteCode);
                if (this.applyForm == 'INVITE_CODE') {
                    this.incorrect = true;
                } else {
                    let newUrl = window.location.protocol + '//' + window.location.host+ '/form/';
                    window.location = newUrl + `?formKey=${formKey}&inviteCode=${this.inviteCode}`;
                }
            } catch (e) {
                if (useDebug) console.log("getEvent error", e);
                window.route_error = this.tempTextI18N[this.$i18n.locale].systemError;
                let failUrl = "/error";
                this.$router.push(failUrl);
            }
        }
    },
    async mounted(){
        if (useDebug) console.log(this.$route);
        this.formLangs = ['zh-hans', 'zh-hant', 'pt'];
    },
    data(){
        return {
            inviteCode: null,
            incorrect: false,
            tempI18N: {
                'pt': {
                    'label.submit': "Submeter",
                    '請填寫邀請碼':"Preencha o código de convite",
                    '請填寫': 'Preencha por favor',
                    '輸入錯誤，請填寫邀請碼':"Inserção incorrecta preencha o código de convite",
                    '邀請碼不正確':"Código de convite incorrecto"
                },
                'zh-hans': {
                    'label.submit': "递交",
                    '請填寫邀請碼':"请填写邀请码",
                    '請填寫': '请填写',
                    '輸入錯誤，請填寫邀請碼':"输入错误，请填写邀请码",
                    '邀請碼不正確':"邀请码不正确"
                },
                'zh-hant': {
                    'label.submit': "label.submit",
                    '請填寫邀請碼':"請填寫邀請碼",
                    '請填寫': '請填寫',
                    '輸入錯誤，請填寫邀請碼':"輸入錯誤，請填寫邀請碼",
                    '邀請碼不正確':"邀請碼不正確"
                }
            }
        }
    },
    computed:{
        ...mapModalComputed()
    }
};
</script>
