/* eslint-disable no-unused-vars */

/**
 * Standardize Validation Messagages
 * 
 * Incentive: With fully customizable forms, users have the option to
 * set whether a question is required or not. On top of that, users are
 * also allowed to indicate whether a question should be min/max/url....
 * However, when setting question type it forces that question to be required.
 * 
 * Purpose: Custom validation messages to be standardized with their corresponding
 * validation message.
 * 
 * For example min validation checks if string or number and does the check depending on type.
 * Validation is also true if empty. Running this file's function adds the validation message
 * minNoReq to be equal to the standard validation message of min.
 * 
 */

function s(item) {
    if (typeof item === 'string' && item.length) {
        return item[0].toUpperCase() + item.substr(1);
    }
    return item;
}

let customValidToStandardValidMap = {
    'minNoReq' : 'min',
    'maxNoReq' : 'max',
    'isValidUrl' : 'url',
    'isValidEmail' : 'email',
    'beforeNoReq': 'before',
    'afterNoReq': 'after'
}

let customValidationMessage = {
    'macauID' : {
        'pt' : (name) => {return `O formato do cartão de ${name} deve ter um número de identificação de Macau válido`},
        'hans' : (name) => {return `${name} 格式必须要一个有效的澳门身份证号码`},
        'hant' : (name) => {return `${name} 格式必須要一個有效的澳門身份證號碼`}
    },
    'macauPhone' : {
        'pt' : (name) => {return `O formato do ${name} deve ter um número de telefone válido de Macau`},
        'hans' : (name) => {return `${name} 格式必须要一个有效的澳门电话号码`},
        'hant' : (name) => {return `${name} 格式必須要一個有效的澳門電話號碼`}
    },
    'verifyUploading' : {
        'pt' : (name) => {return `Upload de anexo`},
        'hans' : (name) => {return `附件上传中`},
        'hant' : (name) => {return `附件上傳中`},
    },
    'verifyReapeatForm': {
        'pt' : (name) => window.verifyRepeatFormMessage,
        'hans' : (name) => window.verifyRepeatFormMessage,
        'hant' : (name) => window.verifyRepeatFormMessage
    },
    'verifyReapeatSession': {
        'pt' : (name) => window.verifyRepeatSessionMessage,
        'hans' : (name) => window.verifyRepeatSessionMessage,
        'hant' : (name) => window.verifyRepeatSessionMessage
    },
}

export function customValidToStandardValid(localizedValidationMessages) {
    Object.keys(customValidToStandardValidMap).forEach((key) => {
        localizedValidationMessages[key] = localizedValidationMessages[customValidToStandardValidMap[key]];
    });
}

export function addCustomMessages(localizedValidationMessages, lang) {
    Object.keys(customValidationMessage).forEach((key) => {
        localizedValidationMessages[key] = function ({ name }) {
            return customValidationMessage[key][lang](name);
        }
    })
}