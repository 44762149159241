<template>
  <section v-if="displayObj" :data-update="forceUpate">
    <div class="px-4 mx-auto max-w-6xl sm:px-6">
      <div class="shadow-xl bg-white text-left p-6 pb-12 md:pb-20">
        <div class="container mx-auto px-4">
          <section class="py-8 px-4 text-center">
            <div class="max-w-auto mx-auto">
              <div class="md:max-w-lg mx-auto">
                <SaError v-if="displayObj.icon == 'x'" class="mx-auto" />
                <SaSuccess v-if="displayObj.icon == 'success'" class="mx-auto" />
                <SaInfo v-if="displayObj.icon == 'warning'" class="mx-auto" />
                <SaInfo v-if="displayObj.icon == 'info'" class="mx-auto" />
                <SaInfo v-if="displayObj.icon == 'error'" class="mx-auto" />
                
              </div>
              <!-- <h2 class="mt-8 uppercase text-xl lg:text-5xl font-black">We are sorry, Page not found!</h2> -->
              <p
                class="mt-6 uppercase text-sm lg:text-base text-gray-900"
                v-html="displayText"
              ></p>
              

              <template v-if="displayObj && !displayObj.noBack">
              <a
                v-if="displayObj.backUrl"
                :href="displayObj.backUrl"
                class="
                  mt-6
                  bg-blue-300
                  hover:bg-blue-700
                  text-white
                  font-light
                  py-4
                  px-6
                  rounded-full
                  inline-block
                  uppercase
                  shadow-md
                "
                >{{ $t("返回") }}</a
              >

              <router-link
                v-if="!displayObj.backUrl"
                to="/list"
                class="
                  mt-6
                  bg-blue-300
                  hover:bg-blue-700
                  text-white
                  font-light
                  py-4
                  px-6
                  rounded-full
                  inline-block
                  uppercase
                  shadow-md
                "
                >{{ $t("返回") }}</router-link
              >
              </template>
            </div>
          </section>
        </div>
      </div>
    </div>
  </section>
</template>
 
<script>
import SaError from "../components/icon/SaError";
import SaInfo from "../components/icon/SaInfo";
import SaSuccess from "../components/icon/SaSuccess";
import BaseMixin from '../components/BaseMixin'

const useDebug =
  window.location.href.indexOf("127.0.0.1") >= 0 ||
  window.location.href.indexOf("localhost") >= 0;

export default {
  mixins:[BaseMixin],
  components: {
    SaSuccess,
    SaError,
    SaInfo
  },
  methods: {},
  mounted() {
    if (useDebug) console.log("mount");
    let displayObj = localStorage.getItem(this.$route.params.key);
    if (displayObj) displayObj = JSON.parse(displayObj);
    if (displayObj) {
      if (displayObj.useEventI18n && !window.eventI18n) {
        this.$router.push("/");
      }
      this.displayObj = displayObj ? displayObj : false;
    }
    if (useDebug) console.log("mount done");
  },
  computed: {
    displayText() {
      if (useDebug) console.log("computed displayText", this.displayObj);
      if (!this.displayObj) return "";
      if (this.displayObj.msg) return this.$t(this.displayObj.msg);
      else if (this.displayObj.msgKeys) {
        for (let k in this.displayObj.msgKeys) {
          const key = this.displayObj.msgKeys[k];
          if (this.$t(key)) {
            return this.$t(key);
          }
        }
      }
      return "-";
    },
  },
  data() {
    return {
      forceUpate:0,
      displayObj: false,
      backUrl:'/'
    };
  },
};
</script>
