import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)
function loadLocaleMessages() {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}
  locales.keys().forEach(key => {
    

    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      let payload = locales(key)
      let payload2 = Object.keys(payload).reduce(function (result, key) {
        if (payload[key] && typeof payload[key] != "object")
          result[key] = payload[key];
        return result;
      }, {});
      // console.log({ key, payload, payload2 });
      messages[locale] = payload2;
    }
  })
  return messages
}

export const ALL_LANGS = ["en", "pt", "zh-hant", "zh-hans"];
export const SUPPORT_LANG_LABELS = {
         pt: "Português",
         "zh-hant": "繁體中文",
         "zh-hans": "简体中文",
       };

export const SUPPORT_LANGS = [ "zh-hant", "zh-hans", "pt"];

export const LOCALE_TO_LANG = {
  'zh_TW' : 'zh-hant',
  'zh_HK' : 'zh-hant',
  'pt_PT' : 'pt',
  'zh_CN' : 'zh-hans'
}
let locale = 'zh-hant'
const urlParams = new URLSearchParams(window.location.search);
if(urlParams.has('lang')  ){
  let queryLang = urlParams.get('lang')
  if( LOCALE_TO_LANG[queryLang] ) queryLang = LOCALE_TO_LANG[queryLang] ;
  if( SUPPORT_LANGS.indexOf(queryLang) !==-1 ){
    locale=queryLang;
  }
}

let messages=loadLocaleMessages()


export function compactToI18n(data){
  let db = {}
  for (const l in SUPPORT_LANGS) {
    const lang = SUPPORT_LANGS[l]
    db[lang] = {}
  }
  for (const ll in data) {
    const row = data[ll];
    for (const l in SUPPORT_LANGS) {
      const lang = SUPPORT_LANGS[l]
      if( row[l] )
        db[lang][ll] = row[l]
    }
  }
  return db;
}

export function updateMessages(data){
  for (const ll in data) {
    if (SUPPORT_LANGS.indexOf(ll)>=0) { 
      //console.log( 'updateMessages', [ll, messages[ll], data[ll] ] );
      messages[ll] = Object.assign(messages[ll], data[ll]);
    }
  }
  // console.log("updateMessages", { data , messages});
}

export function updateI18n(data){
  for (const key in data) {
    const row = data[key]
    for (const ll in row) {
      if (SUPPORT_LANGS.indexOf(ll)>=0) { 
        const value = row[ll]
        //console.log( 'updateI18n', { key, ll ,value });
        messages[ll][key] = value
      }
    }
  }
  // console.log("updateMessages", { data , messages});
}

window.missing= {"en":{}, "pt":{}, "zh-hant":{}, "zh-hans":{}}
const vueI18n = new VueI18n({
  locale,
  fallbackLocale: 'zh-hant',
  silentTranslationWarn:true,
  missing:function(a,b){
    if(b.indexOf("form.")==0 || b.indexOf("event.")==0){
      return false;
    }
    if(!window.missing[a][b]){
      window.missing[a][b]=true
      console.warn('missing',[a,b])
    }
  },
  messages
})
window.vueI18n = vueI18n
export default vueI18n;

window.messages = messages;
// console.log({messages})